$filters__heading-font-weight: $font-weight-bold !default;
$filters__heading-font-weight\@medium: $font-weight-normal !default;
$filters__heading-margin: 0 !default;
$filters__heading-margin\@medium: 0 0 $spacer--medium 0 !default;
$filters__skip-button-height: $spacer--extra-large !default;
$filters__skip-button-padding: 0 $spacer--medium !default;
$filters__skip-button-margin-bottom: $spacer !default;
$filters__switcher-margin: 0 auto $spacer--large !default;
$filters__switcher-padding: 0 !default;
$filters__switcher-border: 1px solid $gray-lighter !default;
$filters__switcher-border-radius: 0 !default;
$filters__switcher-background-color: $bg-color-base !default;
$filters__switcher-color: $white !default;
$filters__switcher-font-size: $font-size-large !default;
$filters__switcher-font-family: $font-family-base !default;
$filters__switcher-transition: $transition-base !default;
$filters__switcher-fill: $white !default;
$filters__switcher-size: $spacer--extra-large !default;
$filters__switcher-icon-margin: $spacer !default;
$filters__switcher-icon-padding: 0 5px !default;
$filters__list-margin: 0 !default;
$filters__divider-margin: 0 0 $spacer--medium 0 !default;
$filters__active-margin: 0 0 $spacer--medium 0 !default;

.filters {
  &__heading {
    font-weight: $filters__heading-font-weight;
    margin: $filters__heading-margin;
    @include mq($screen-l) {
      font-weight: $filters__heading-font-weight\@medium;
      margin: $filters__heading-margin\@medium;
    }
    &--mobile-hidden {
      display: none;
      @include mq($screen-l) {
        display: block;
      }
    }
  }

  &__skip-button {
    @include visually-hidden($usePseudo: true);
    transition: none;

    &:focus {
      height: $filters__skip-button-height;
      padding: $filters__skip-button-padding;
      margin-bottom: $filters__skip-button-margin-bottom;
    }
  }

  &__switcher {
    display: flex;
    align-items: center;
    height: $filters__switcher-size;
    margin: $filters__switcher-margin;
    padding: $filters__switcher-padding;
    width: calc(100% - #{$spacer *2});

    &:hover,
    &:focus {
      color: $filters__switcher-color;
      text-decoration: none;
      cursor: pointer;
      background: darken($color-primary, 10%);
    }

    &[data-state-current="display"] {
      background: darken($color-primary, 15%);
    }

    @include mq($screen-s) {
      width: calc(100% - #{$spacer--medium *2});
    }

    @include mq($screen-l) {
      display: none;
    }
  }

  &__switcher-icon {
    margin-left: $filters__switcher-icon-margin;
    padding: $filters__switcher-icon-padding;
    transition: $filters__switcher-transition;
    fill: $filters__switcher-fill;
    width: 20px;
    height: 20px;
    &--active {
      fill: $filters__switcher-fill;
    }
  }

  &__list {
    margin: $filters__list-margin;
    &--mobile-hidden {
      display: none;
      @include mq($screen-l) {
        display: block;
      }
    }
  }

  &__list-label {
    margin-right: $spacer--medium;
    display: inline;
    color: $gray;
  }

  &__list-list {
    padding: 0;
    margin: $spacer--medium 0 0;
    flex-flow: wrap;
    @include mq($screen-l) {
      margin-top: 0;
      display: inline-flex;
      max-width: calc(100% - 210px);
      flex-flow: wrap;
    }

    .filter {
      margin-bottom: 10px;
    }


  }

  &__active {
    margin: $filters__active-margin;
  }

  &__divider {
    display: none;
    margin: $filters__divider-margin;
    @include mq($screen-l) {
      display: flex;
    }
  }
}

// Mobile only

.catalog-list {

  .button-filter-list {
    display: none;
    @include mq(0, $screen-m) {
      position: relative;
      width: 256px;
      margin-left: auto;
      margin-right: auto;
      display: block;
      .button__icon {
        position: absolute;
        right: 20px;
        top: 50%;
        margin-top: -5px;
      }
    }
  }

  .filters__list {

    @include mq(0, $screen-m) {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1000;
      padding: 25px;
      display: none;
      padding-top: 70px;
      &.active {
        display: block;
      }
    }

    .icon-close {
      display: none;
      @include mq(0, $screen-m) {
        display: block;
        cursor: pointer;
      }
      svg {
        position: fixed;
        right: 23px;
        top: 10px;
        fill: $color-primary;
      }
    }


  }
}



