$catalog-grid__gap    : $spacer--medium !default;
$catalog-grid__gap\@large    : 42px !default;
$catalog-gird__margin : 0 !default;
$catalog-grid__padding: $spacer--medium 0 $spacer--large 0 !default;
$catalog-grid__border : 0 !default;

.catalog-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: $catalog-grid__gap;
    margin: $catalog-gird__margin;
    padding: $catalog-grid__padding;
    border-bottom: $catalog-grid__border;
    list-style: none;
    padding-top: 40px;
    @include mq($screen-m) {
        grid-template-columns: 1fr 1fr 1fr;
        padding-top: 70px;
    }

    @include mq($screen-l) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @include mq(1900px) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    @include mq($screen-xxl) {
        grid-gap: $catalog-grid__gap\@large;
    }

    @include isIE() {
        display: flex;
        flex-wrap: wrap;
    }

    &--related {
        li:nth-of-type(5) {
            display: none;
        }
    }

    &__benefits {
        padding-top: $spacer--large;
        border-top: 1px solid $gray-lighter;
    }
}


body.cms-home  {
    .catalog-grid {
        padding-top: 0 !important;
    }
}
