.benefits {
    padding: $spacer--medium 0;
    align-items: center;
}
.benefits__item {
    position: relative;
    @include clearfix;
    margin-bottom: $spacer--medium;
    display: flex;
    align-items: center;
}
.benefits__icon {
    width: 80px;
    height: 80px;
    flex: 0 0 auto;
    margin-right: 15px;
}

.benefits__label {
    flex: 1 1 auto;
}

.benefits__title {
    font-weight: 900;
    font-size: $font-size-large;
    font-family: $font-family-sans-serif-alt;
    padding: 0;
    margin: 0 0 4px;
    color: $darkblue;
    line-height: 1;
}
.benefits__description {
    padding: 0;
    font-size: 13px;
    font-family: $font-family-serif;
    margin-top: 5px;
    line-height: $font-line-height;
    max-width: 200px;
    display: inline-block;
}
