$swatch__transition                  : $transition-base !default;

$swatch__title-margin-bottom         : $spacer !default;
$swatch__title-font-size             : 17px !default;

$swatch__option-size                 : 30px !default;
$swatch__option-size--image          : 48px !default;
$swatch__option-margin               : $spacer--small !default;
$swatch__option-background           : $white !default;
$swatch__option-color                : $gray-dark !default;
$swatch__option-border               : 1px solid $color-primary !default;
$swatch__option-border--white        : 1px solid $gray-lighter !default;
$swatch__option-border-color--active : $color-primary !default;

.swatch {
    display: flex;
    flex-wrap: wrap;

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    &__option-container {
        box-sizing: border-box;
        border: $swatch__option-border;
        margin-right: $swatch__option-margin;
        transition: $swatch__transition;
        cursor: pointer;
        border-radius: 15px;
        padding: 0 24px;
        color: $color-primary;
        font-family: $font-family-sans-serif-alt;
        font-size: $font-size-small;
        font-weight: 700;
        line-height: 100%;
        &:focus {
            outline: 1px dotted $gray-light;
        }

        &.selected {
            border-color: $swatch__option-border-color--active;
            background: $color-primary;
            color: #fff;
            .swatch__option--white {
                border: 0;
            }
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &__selected-option {
        display: none;
    }

    &__title {
        flex: 0 0 100%;
        margin-bottom: $swatch__title-margin-bottom;
        font-size: $swatch__title-font-size;
        color: $darkblue;
        font-family: $font-family-sans-serif-alt;
        font-weight: 700;
    }

    &__option {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        -ms-flex-align:center;
        min-width: $swatch__option-size;
        min-height: $swatch__option-size;
        &--image {
            min-height: $swatch__option-size--image;
            background-size: cover;
            background-position: top center;
        }

        &--white {
            border: $swatch__option-border--white;
        }
    }

    &__input {
        @include visually-hidden;
    }
}

.swatch  {
    &.offer_duration, &.support {
        .swatch__option {
            min-height: auto;
            padding: 8px 0;
        }
    }
}
.swatch__option {
    .checkbox.offer__checkbox {

        margin: 0;
        max-width: none;
        overflow: hidden;
        padding: 0.5em 0;
        label {
            margin: 0;
            line-height: 28px;
            svg {
                float: left;
                margin-right: 5px;
                fill: $darkblue;
            }
            &.offer__checkbox-label {
                margin-right: 14px;
            }
        }
    }
}