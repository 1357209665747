$search-form__height: 42px !default;
$search-form__height\@xl: 50px !default;
$search-form__width: 100% !default;
$search-form__width--extra-large: 90% !default;
$search-form__alignment: center !default;
$search-form__margin: 0 !default;
$search-form__border: 1px solid $gray-lighter !default;
$search-form__font-size: $font-size-base !default;

$search-form__input-height: 40px !default;
$search-form__input-height\@xl: 48px !default;

$search-form__button-size: 24px !default;
$search-form__button-margin: 0 !default;
$search-form__button-border: none !default;
$search-form__button-background: transparent !default;
$search-form__button-icon-color: $color-primary !default;
$search-form__button-fill-hover: $color-secondary !default;

$search-form__placeholder-color: #666 !default;

.search-form {
  position: relative;
  width: $search-form__width;
  height: $search-form__height;
  align-self: $search-form__alignment;
  margin: $search-form__margin;
  border: $search-form__border;
  border-radius: $search-form__height;
  z-index: 1;

  @include mq($screen-xl) {
    width: $search-form__width--extra-large;
    height: $search-form__height\@xl;

  }
  @include mq($screen-xxl) {
    width: 50%;
  }

  &__input-wrapper {
    position: relative;
  }

  &__input {
    width: 100%;
    height: $search-form__input-height;
    border: none;
    border-radius: $search-form__height;
    font-size: $search-form__font-size;
    background: $gray-lightest;
    padding: 0 $spacer--extra-large 0 24px;
    font-weight: 300;
    font-family: $font-family-sans-serif-alt;
    box-shadow: none;

    @include mq($screen-xl) {
      height: $search-form__input-height\@xl;
    }

    &::placeholder {
      color: $search-form__placeholder-color;
      font-size: $search-form__font-size;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__button {
    position: absolute;
    top: 0;
    right: 10px;
    height: $search-form__input-height;
    margin: $search-form__button-margin;
    border: $search-form__button-border;
    border-radius: $search-form__height;
    background-color: $search-form__button-background;

    @include mq($screen-xl) {
      height: $search-form__input-height\@xl;
    }

    &:focus,
    &:hover {
      background-color: $search-form__button-background;

      .button__icon {
        fill: $search-form__button-fill-hover;
      }
    }
  }

  .button__icon {
    width: $search-form__button-size;
    height: $search-form__button-size;
    fill: $search-form__button-icon-color;
  }


    .search-autocomplete.amsearch-clone-position {
      position: fixed;
      left: 0;
      right: 0;
      width: 100% !important;
      bottom: 0;
      top: 117px;
      min-width: 100% !important;
      max-height: inherit;
      margin: 0;
        overflow: auto;
      @include mq($screen-l) {
        top: 50px;

      }
      @include mq($screen-xl) {
        top: 137px;
      }
      &:before {
        display: block;
        content: "";
        position: absolute;
        top: -7px;
        left: 0;
        right: 0;
        box-shadow: 0 3px 10px 0 rgba(24, 52, 70, 0.3);
        width: 100%;
        height: 7px;
      }
      .amsearch-results {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        background: $gray-lightest url(../images/pattern.png) repeat;
        @include mq($screen-l) {
          display: block;
          height: 100%;
          overflow: hidden;
        }
        .amsearch-leftside {
          height: auto;
          width: 100% !important;
          background: none;
          @include mq($screen-l) {
            float: right;
            width: 430px !important;
            height: 100%;
            overflow: auto;
            background: white;
          }
          @include mq($screen-xxl) {
            width: 30% !important;
          }

          .amsearch-item {
            color: $darkblue;
            font-family: Urbani, sans-serif;
            padding-top: 0;
            padding-bottom: 10px;

            a, span {
              font-size: 18px;
              color: $darkblue;
              font-family: Urbani, sans-serif;
              font-weight: normal;
            }

            &:hover {
              color: $teal;
              a, span {
                color: $teal;

              }
            }

          }

        }
        .amsearch-products {
          width: 100% !important;
          padding: 30px;
          background: none;
          overflow: auto;
          display: block;
          @include mq($screen-l) {
            height: 100%;
            width: calc(100% - 430px) !important;
            max-width: 990px;
            float: left;
            padding-right: 60px;
            padding-left: 60px;
            padding-bottom: 45px;
            padding-top: 47px;
          }
          @include mq($screen-xxl) {
            width: 60% !important;
            max-width: 100%;
          }
          .button {
            font-size: 13px;
          }

          .button__icon {
            width: 10px;
            height: 10px;
            fill: #fff;
          }
          .amsearch-item {
            img {
              border: solid 3px transparent;
            }
            &:hover {
              background: none;
              box-shadow: none;
              img {
                border-color: $teal;
              }
            }
          }

        }
        .amsearch-wrapper-content {
          padding: 0;
          display: block;
          margin-bottom: 30px;
          .product-item-link {
            font-size: 14px !important;
            color: $darkblue;
            font-family: Urbani, sans-serif;
            text-align: center;
            display: block;
            font-weight: 700 !important;
            margin-top: 4px;
            line-height: 1.4;
            min-height: 40px;
            padding-bottom: 0;
            margin-bottom: 0;
          }
        }
        .amsearch-wrapper-content {
          margin-left: -10px;
          margin-right: -10px;
        }
        .amsearch-wrapper-content > .amsearch-item {
          width: 50%;
          display: block;
          float: left;
          max-width: 220px;
          padding: 10px;
          &:before {
            display: none;
          }
          @include mq($screen-s) {
            width: 33.33%;
          }
          @include mq($screen-l) {
            width: 25%;
          }
          @include mq($screen-xl) {
            width: 20%;
          }
        }
        .amsearch-autocomplete-information {
          padding-left: 0;
        }
        .amasty-xsearch-block-header {
          font-size: 24px;
          color: $darkblue;
          font-family: Urbani, sans-serif;
          margin-bottom: 25px;
          text-transform: none;
          font-weight: 900;
          padding-left: 0;
          padding-top: 0;
          &:before {
            display: none;
          }
        }
        .amsearch-products .amsearch-highlight {
          background: none;
          color: $darkblue;
          padding: 0;
          font-size: 14px !important;
          font-weight: 700 !important;
        }
        .search-menu__icon {
          float: left;
          fill: $color-primary;
          height: 10px;
          width: 10px;
          padding: 0;
          margin-top: 9px;
          margin-right: 14px;
        }
        .amsearch-item-container {
          padding: 30px;
          background: white;
          @include mq($screen-l) {
            padding: 50px 35px;
          }

        }
      }
    }

  .search-form__close {
    position: absolute;
    width: 32px;
    height: 32px;
      min-width: 32px !important;
      max-width: 32px !important;
    border-radius: 16px;
    background: #393243;
    display: none;
    &:hover {
      background: $teal;
    }
  }


  .search-form__input-wrapper {
    .search-form__close {
      top: 4px;
      right: 4px;
      @include mq($screen-xl) {
        top: 8px;
        right: 10px;
      }
    }
  }

  .amsearch-products {
    .search-form__close {
      top: 15px;
      right: 15px;
    }
  }

  .search-form__close-icon {
    fill: #fff;
    margin: 0 auto;
    width: 20px;
    height: 32px;
  }

  &.-opened {
    .search-form__button {
      display: none;
    }
    .search-form__close {
      display: block;

    }

    #search_autocomplete {
      .search-form__close {
        display: none;
      }
    }
  }


  .amasty-xsearch-loader {
    top: 15px;
    right: 50px;

    @include mq($screen-xl) {
      top: 19px;
      right: 50px;
    }
  }

  .amsearch-wrapper-content .product-item-details {
    display: block;
  }

  .amsearch-more-results {
    clear: both;
    text-align: center;
  }

}

