body.checkout-index-index._has-modal-custom {
    overflow-y: initial !important;
    overflow-x: hidden !important;
}

.page-layout-checkout {
    background: $gray-lightest;

    .heading--page {
        font-size: 40px;
    }

    .header__container {
        height: 50px;
        align-items: center;
        justify-content: center;

        @include mq($screen-xl) {
            height: 137px;
            justify-content: space-between;
        }
    }

    .header__logo {
        order: 0;
        margin-top: 0;
        margin-left: 0;
    }

    .back-to-cart {
        font-size: $font-size-small;
        align-items: center;
        font-family: $font-family-sans-serif;
        display: none;
        flex-basis: 320px;
        margin-bottom: $spacer--medium;

        @include mq($screen-xl) {
            display: flex;
        }

        > a {
            color: $color-primary;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .back-to-cart__icon {
        margin-right: $spacer;
        width: 14px;
        height: 14px;
        fill: $color-primary;
    }
    .secured-payment {
        font-size: $font-size-medium;
        color: $darkblue;
        font-weight: 700;
        align-items: center;
        justify-content: flex-end;
        flex-basis: 320px;
        margin-bottom: $spacer--medium;
        display: none;
        @include mq($screen-xl) {
            display: flex;
        }
    }
    .secured-payment__logo {
        margin-right: $spacer--medium;
        width: auto;
    }
    .page-main {
        padding: 18px 0 $spacer--extra-large;
    }
    .page-main .container {
        max-width: 1170px;
    }
    .page-content {
        margin: 0;
    }
    .step-title {
        font-size: 30px;
        color: $darkblue;
        margin: 0 0 $spacer--large $spacer;
        margin-left: 0;
    }
    .opc-payment .step-title {
        margin: 0 0 $spacer--large;
    }
    .form-login .note {
        margin: $spacer;
        font-size: $font-size-small;
        color: $darkblue;
        display: block;
    }
    .admin__field {
        margin-bottom: $spacer--medium;
        margin-top: -$spacer;
    }
    .checkout-agreement {
        margin: 0 auto $spacer--large;
        text-align: center;
        display: flex;
        justify-content: center;
        label {
            display: inline-block;
            font-size: $font-size-small;
            position: relative;
            button {
                color: $darkblue !important;
            }
        }
    }

    .checkbox__label {
        font-weight: normal;
    }

    .checkout-billing-address {
        .billing-address-same-as-shipping-block {
            .checkbox__label {
                color: $darkblue;
                font-size: 24px;
                font-weight: 700;
                &:before {
                    top: 24px;
                }
            }
        }
        svg.checkbox__icon {
            top: 24px;
        }
    }
    .checkbox__field:checked ~ .checkbox__label {
        font-weight: bold !important;
    }

    .action.remind {
        color: $color-primary;
        font-size: 14px;
    }

    .gift-info {
        font-size: 14px;
        color: $darkblue;
        padding-left: 10px;
        margin-top: 30px;
        > span {
            font-weight: bold;
            display: block;
            margin-bottom: 10px;
        }
        ul {
            list-style: none;
            padding-left: 0;
            li {
                margin-bottom: 4px;
            }
        }
        &:before {
            background: url(../images/gift.svg) center no-repeat;
            width: 15px;
            height: 20px;
            background-size: contain;
            background-position-x: 0;
            content: '';
            display: block;
            float: left;
            margin-top: -5px;
            margin-right: 10px;
        }
    }

    .checkbox__label:before {
        top: 17px;
    }
    svg.checkbox__icon {
        top: 17px;
    }
    .registration__newsletter-checkbox-icon {
        left: $spacer;
    }
    .checkout-shipping-method {
        .step-title.heading, .divider, .shipping-policy-block.field-tooltip, #checkout-shipping-method-load, #onepage-checkout-shipping-method-additional-load {
            display: none;
        }
    }

    .field {
        textarea.admin__control-textarea {
            width: 100%;
        }
    }
    .opc-block-shipping-information {
        .shipping-information {
            overflow: hidden;
            div:not(.ship-to) {
                .shipping-information__title, .shipping-information__content {
                    display: none;
                }
            }
        }
    }
    .cart-totals {
        .totals.shipping {
            display: none;
        }
    }
    #shipping_method_form {
        display: none;
    }
    .fieldset {
        & > .field {
            &._required,
            &.required {
                & > {
                    label,
                    .label {
                        &:after {
                            font-size: 1rem;
                        }
                    }
                }
            }
        }
    }
    .page-main fieldset.field {
        padding-right: calc(16px * 0.5) !important;
        padding-left: calc(16px * 0.5) !important;
    }

    .hidden.visible {
        display: block !important;
    }
    .payment-method-title.field.choice {
        padding: 15px 0;

        .radio__icon {
            margin-right: 20px;
        }
        label {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            height: auto;
            margin-bottom: 0;
            line-height: normal;

            span:last-child {
                margin: 10px 0;
            }
        }
        img {
            height: 40px;
            width: auto;
            margin: 10px 24px 10px 0;
            vertical-align: middle;
        }
    }

    .opc-sidebar {
        @include mq($screen-m ) {
            width: 100% !important;
        }

        @include mq($screen-l ) {
            width: 33.33333% !important;
        }
    }

    .opc-wrapper {

        @include mq($screen-m ) {
            width: 100% !important;
            padding-right: 0 !important;
        }

        @include mq($screen-l ) {
            padding-right: 30px !important;
            width: 66.66667% !important;
        }

        .opc#checkoutSteps {
            padding: 48px 20px !important;
            @include mq($screen-l ) {
                padding: 48px 98px !important;
            }
            .message {
                background: none;
                border: none;
                color: #e85b55;
                margin: 0;
                padding: 0;
                font-size: 14px;
                &:after {
                    display: none;
                }
            }
        }


        .checkout-shipping-address {
            .checkout__link {
                /* margin-left: 10px; */
            }
        }

        .shipping-address-items {
            margin-top: 0 !important;
            padding-left: 10px;
        }
        .shipping-address-item {
            padding: 0 !important;
            font-style: normal;
            color: $darkblue;
            line-height: 1.4 !important;
            font-family: Urbani, sans-serif;
            font-size: 16px !important;
            padding-top: 7px !important;
            margin-top: 0 !important;
            margin-bottom: 20px !important;
            border: none !important;
            a {
                color: $darkblue;
            }
            &.selected-item {
                    border: none;
                padding-right: 30px !important;
                .action__link {
                    display: none;
                }
            }
            &:before {
                width: 0 !important;
            }
        }

        .action-show-popup {
            text-decoration: none;
            &:before {
                content: "+";
                height: 22px;
                width: 22px;
                display: inline-block;
                color: #fff;
                background: $color-primary;
                margin-right: 8px;
                border-radius: 50% !important;
                text-align: center;
                line-height: 22px;
                font-size: 22px;
                font-weight: 700;
                font-family: Urbani, sans-serif;
                overflow: hidden;
                border: none;
                box-shadow: none;
                vertical-align: middle;
            }
            span {
                text-decoration: underline;
                &:before {
                    display: none;
                }
            }
        }
    }

    #opc-shipping_method {
        padding-top: 55px;
        &:before {
            display: block;
            content: "";
            height: 1px;
            position: absolute;
            left: -98px;
            right: -98px;
            background: #bcc4c5;
            top: 0;
            @include mq(0, $screen-l) {
                left: -20px;
                right: -20px;
            }
        }
    }


    .opc-sidebar {
        padding: 20px;


        .minicart-product {
            padding: 30px 0;
        }
    }

    .opc-block-summary {

        .product-item .product-item-inner {
            display: block !important;
        }

        .checkout-summary__title {
            border-bottom: solid 1px #bcc4c5;
        }

        .product-item-name-block {
            display: block !important;
            overflow: hidden;
        }

        .product-item-name {
            font-size: 18px;
            font-family: Urbani, sans-serif;
            font-weight: 900;
            color: $darkblue;
            display: block;
            margin-bottom: 10px;
        }

        .product-item-offer-magazine-number, .product-item-offer-duration {
            font-weight: bold;
            float: left;
            font-family: Urbani, sans-serif;
            font-size: 14px;
            color: $darkblue;
        }

        .details-qty {
            display: inline-block;
            margin-right: 10px;
            font-family: Roboto, sans-serif;
            font-size: 14px;
            font-weight: normal;
            color: $darkblue;

            span {
                font-weight: bold;
                span {
                    font-weight: normal;
                }
            }
        }
        .product-item {
            div.details-qty {
                margin-top: 12px;
            }
        }

        .details-price {
            display: inline-flex;
            vertical-align: top;
            flex-flow: row wrap;
            max-width: calc(100% - 110px);

            .cart-price {
                margin-right: 4px;
            }
            .old-subtotal {
                order: 3;
                width: 100%;
            }
        }
        .minicart-product__actions {
            display: inline-block;
            float: right;
        }

        .product-item-is-gift {
            margin-top: 15px;
            margin-bottom: 15px;
            line-height: 36px;

            &:before {
                background: url(../images/gift.svg) center no-repeat;
                width: 15px;
                height: 20px;
                background-size: contain;
                background-position-x: 0;
                content: '';
                display: block;
                float: left;
                margin-top: 5px;
                margin-right: 10px;
            }
            span {
                font-size: 14px;
                font-weight: normal;
                font-family: Roboto, sans-serif;
                color: $darkblue;
            }
        }

        .cart-price {
            color: $pink;
            padding: 0 8px;
            border: 1px solid;
            display: inline-block;
            font-weight: $price__font-weight;
            font-size: 18px;
            font-family: $font-family-sans-serif-alt;
            vertical-align: top;
            margin: 2px 0;
            line-height: 33px;
            height: 33px;
        }

        .old-subtotal {
            font-weight: $price__font-weight--old;
            margin-right: $price__spacing;
            height: auto;
            display: block;
            color: #455966;
            padding: 0;
            border: 0;
            margin-top: $spacer--small;
            font-size: 13px;
            font-family: $font-family-condensed;
            del {
                text-decoration: line-through;
                margin-right: $spacer--small;
                font-size: 14px;
            }

            .price-currency {
                top: 0;
                font-size: 100%;
            }
        }

        .badge--discount {
            padding: 0 8px;
            border: 1px solid;
            display: inline-block;
            font-weight: $price__font-weight;
            font-size: 22px;
            font-family: $font-family-sans-serif-alt;
            vertical-align: top;
            font-size: $font-size-medium;
            top: -2px;
            position: relative;
            top: auto;
            vertical-align: top;
            margin: 2px 0;
            background: $pink;
            color: white;
            border-color: $pink;
            height: 33px;
            line-height: 33px;
        }



        .cart-totals {
            border-color: #bcc4c5;
        }
        .cart-totals__row--summary {
            border-color: #bcc4c5;
        }

        .cart-totals__row {
            font-size: 14px;
            color: $darkblue;

            .amount {
                .price {
                    font-weight: bold;
                }
            }
            &.grand.totals {
                border-bottom: solid 1px #bcc4c5;
                padding-bottom: 10px;
                .cart-totals__row-value {
                    font-size: 22px;
                    font-weight: 900;
                    .price {
                        font-weight: 900;
                    }
                }
                .cart-totals__row-label {
                    padding-top: 5px;
                }
            }
        }

        .cart-totals__row-value {
            font-family: Urbani, sans-serif;
            font-size: 16px;
        }
    }


    .checkout__link {
        color: $color-primary;
        text-decoration: underline;
        margin-left: 68px;
        padding: 0;
        transition: none;
        margin: 0;
        background: none;
        border: none;
        font-family: Roboto, sans-serif;
        font-size: 14px;
    }

    .title-wrapper {
        margin-bottom: 40px;
    }


    &.payment-step {
        .opc-wrapper {
            width: 100% !important;
        }
    }


    .checkout-review__payment {
        img {
            width: 70px;
            height: auto;
        }
    }

    .modal-popup .modal-footer {
        .button {
            background-color: $color-primary;
            border: none;
            color: #fff;

            &:hover,
            &:focus {
                background-color: darken($color-primary, 20%);
                color: #fff;
            }
        }
    }
}
.pac-container {
    z-index: 1000000 !important;
}
.modal-popup .modal-inner-wrap .field.street {
    padding-right: calc(16px * 0.5) !important;
    padding-left: calc(16px * 0.5) !important;
    margin: 0;
}


body.checkout-index-index {
    .minicart-items,
    .totals.shipping,
    .step-title.heading,
    #checkout-shipping-method-load {
        display: block !important;
    }

    .authentication-wrapper,
    .checkout-products__title {
        display: none !important;
    }

    .old-subtotal {
        text-decoration: line-through;
    }

    .payment-method {
        .checkout-agreements-block,
        .actions-toolbar,
        .payment-method-content {
            display: none !important;
        }
    }

    .separate-form {
        margin-left: -98px;
        margin-right: -98px;
        margin-top: 30px;
        margin-bottom: 30px;
        border: none;
        height: 1px;
        background: #bcc4c5;
        @include mq(0, $screen-l) {
            margin-left: -20px;
            margin-right: -20px;
        }
    }


    .i-have-account-link {
        color: $color-primary;
        background: none;
        border: none;
        padding: 0;
        font-size: 14px;
        float: right;
        margin-top: 4px;
    }



    .billing-address-form > form > fieldset {
        .checkbox__label {
            padding: 0;
            padding-left: 40px;
            font-weight: normal;
            font-size: 14px;
            font-family: Roboto, sans-serif;
            &:before {
                top: 2px !important;
                left: 8px;
            }
        }
        svg.checkbox__icon {
            top: 2px;
            left: 8px;
        }
        fieldset {
            border: none;
            padding: 0;
            legend {
                display: none;
            }
            .admin__field-option {
                display: inline-block;
                position: relative;
                margin-right: 10px;
                margin-bottom: 0;
                label {
                    font-size: 14px;
                    font-weight: normal;
                    font-family: Roboto, sans-serif;
                    margin-left: 0;
                    &:before {
                        position: relative;
                        display: inline-block;
                        content: "";
                        border: 1px solid #000;
                        border-radius: 20px;
                        width: 20px;
                        height: 20px;
                        box-shadow: 0 2px 8px rgba(24, 52, 70, 0.2);
                        color: #666;
                        vertical-align: top;
                        margin-right: 10px;
                        margin-top: 1px;
                    }
                    &:after {
                        position: absolute;
                        content: '';
                        width: 10px;
                        height: 10px;
                        border-radius: 10px;
                        margin: auto;
                        opacity: 0;
                        transform: scale(0);
                        background-color: $color-primary;
                        transition: all 0.3s ease-in-out;
                        top: 6px;
                        left: 5px;
                    }
                }
                input {
                    position: absolute;
                    left: 0;
                    height: 20px;
                    width: 20px;
                    opacity: 0;
                    z-index: -1;
                }
                input:checked + label {
                    color: $color-primary;
                    &:after {
                        opacity: 1;
                        transform: scale(1);
                    }
                }
            }

            //civilité

            &[data-index="prefix"] {
                input, label {
                    display: inline-block;
                }
            }
        }

        //civilité

        .field {
            &[name="billingAddressshared.prefix"] {
                label, .control {
                    display: inline-block;
                }
            }
        }

        .field {
            &[name="billingAddressshared.telephone"] {
                margin-bottom: 25px;
            }
        }



        // Complement adresse
        .field {
            &[name="billingAddressshared.custom_attributes.additional_details"] {
                margin-bottom: 25px;
            }
            &[name="shippingAddress.has_complement"], &[name="billingAddressshared.has_complement"] {
                margin-top: 25px;
                .admin__field-option {
                    &:before {
                        position: absolute;
                        content: "+";
                        height: 22px;
                        width: 22px;
                        display: inline-block;
                        color: #fff;
                        background: $color-primary;
                        margin-right: 8px;
                        border-radius: 50% !important;
                        text-align: center;
                        line-height: 22px;
                        font-size: 22px;
                        font-weight: 700;
                        font-family: Urbani, sans-serif;
                        overflow: hidden;
                        border: none;
                        box-shadow: none;
                    }
                }
                .checkbox__label {
                    font-size: $font-size-small;
                    color: $color-primary;
                    text-decoration: underline;
                    font-family: $font-family-sans-serif;
                    position: relative;
                    padding-left: 35px;
                    &:before {
                        display: none;
                    }
                }
                .checkbox__icon {
                    display: none !important;
                }
            }
        }

        .manual-filling {
            margin-bottom: 0;
        }

        .manual-filling-btn {
            font-size: 14px;
            color: $darkblue;
            margin-bottom: 25px;
            span span {
                color: $color-primary;
                text-decoration: underline;
            }
        }


        &.row {
            @include fix-gutter(40px);
        }

        .input-birthday {
            .ui-datepicker-trigger {
                display: none;
            }
            .dob-message {
                font-size: 14px;
                color: $darkblue;
                margin-left: 0;
                margin-top: 15px;
                display: block;

                @include mq($screen-m ) {
                    margin-left: 20px;
                    margin-top: 0;
                    display: inline-block;
                    max-width: 70%;
                    white-space: nowrap;
                }
            }
        }
        .actions-toolbar {
            padding: 1em 0;
            button.button {
                margin: 0 1em;
            }
        }
    }

    #shipping-new-address-form {
        margin-top: 45px;
        .checkbox__label {
            padding: 0;
            padding-left: 40px;
            font-weight: normal;
            font-size: 14px;
            font-family: Roboto, sans-serif;
            &:before {
                top: 2px !important;
                left: 8px;
            }
        }
        svg.checkbox__icon {
            top: 2px;
            left: 8px;
        }
        fieldset {
            border: none;
            padding: 0;
            legend {
                display: none;
            }

            .admin__field-option {
                display: inline-block;
                position: relative;
                margin-right: 10px;
                margin-bottom: 0;
                label {
                    font-size: 14px;
                    font-weight: normal;
                    font-family: Roboto, sans-serif;
                    margin-left: 0;
                    &:before {
                        position: relative;
                        display: inline-block;
                        content: "";
                        border: 1px solid #000;
                        border-radius: 20px;
                        width: 20px;
                        height: 20px;
                        box-shadow: 0 2px 8px rgba(24, 52, 70, 0.2);
                        color: #666;
                        vertical-align: top;
                        margin-right: 10px;
                        margin-top: 1px;
                    }
                    &:after {
                        position: absolute;
                        content: '';
                        width: 10px;
                        height: 10px;
                        border-radius: 10px;
                        margin: auto;
                        opacity: 0;
                        transform: scale(0);
                        background-color: $color-primary;
                        transition: all 0.3s ease-in-out;
                        top: 6px;
                        left: 5px;
                    }
                }
                input {
                    position: absolute;
                    left: 0;
                    height: 20px;
                    width: 20px;
                    opacity: 0;
                    z-index: -1;
                }
                input:checked + label {
                    color: $color-primary;
                    &:after {
                        opacity: 1;
                        transform: scale(1);
                    }
                }
            }

            &.street {
                padding-right: calc(40px * 0.5) !important;
                padding-left: 0;
                .label {
                    display: block;
                }
                label {
                    display: none;
                }
            }




            //civilité

            &[data-index="prefix"] {
                input, label {
                    display: inline-block;
                }
            }
        }

        //civilité

        .field {
            &[name="shippingAddress.prefix"] {
                label, .control {
                    display: inline-block;
                }
            }
        }

        .field {
            &[name="shippingAddress.telephone"] {
                margin-bottom: 25px;
            }
        }

        // birthdate


        .field {
            &[name="shippingAddress.dob"] {
                padding-right: calc(40px * 0.5);
                padding-left: calc(40px * 0.5);
            }
        }

        // Complement adresse
        .field {
            &[name="shippingAddress.custom_attributes.additional_details"] {
                margin-bottom: 25px;
            }
            &[name="shippingAddress.has_complement"] {
                margin-top: 25px;
                .admin__field-option {
                    &:before {
                             position: absolute;
                            content: "+";
                            height: 22px;
                            width: 22px;
                            display: inline-block;
                            color: #fff;
                            background: $color-primary;
                            margin-right: 8px;
                            border-radius: 50% !important;
                            text-align: center;
                            line-height: 22px;
                            font-size: 22px;
                            font-weight: 700;
                            font-family: Urbani, sans-serif;
                            overflow: hidden;
                            border: none;
                            box-shadow: none;
                    }
                }
                .checkbox__label {
                    font-size: $font-size-small;
                    color: $color-primary;
                    text-decoration: underline;
                    font-family: $font-family-sans-serif;
                    position: relative;
                    padding-left: 35px;
                    &:before {
                        display: none;
                    }
                }
                .checkbox__icon {
                    display: none !important;
                }
            }
        }

        .manual-filling {
            margin-bottom: 0;
        }

        .manual-filling-btn {
            font-size: 14px;
            color: $darkblue;
            margin-bottom: 25px;
            span span {
                color: $color-primary;
                text-decoration: underline;
            }
        }


        &.row {
            @include fix-gutter(40px);
        }

        .input-birthday {
            .ui-datepicker-trigger {
                display: none;
            }
            .dob-message {
                font-size: 14px;
                color: $darkblue;
                margin-left: 0;
                margin-top: 15px;
                display: block;

                @include mq($screen-m ) {
                    margin-left: 20px;
                    margin-top: 0;
                    display: inline-block;
                    max-width: 70%;
                    white-space: nowrap;
                }
            }
        }
    }

    .form-login {
        &.row {
            padding-left: calc(calc(40px * 0.5) * -1);
            padding-right: calc(calc(40px * 0.5) * -1);
            margin-left: 0 !important;
            margin-right: 0 !important;
            .field.input, .login-buttons {
                padding: 0;
            }
            .login-buttons {
                margin-bottom: 30px;
            }
        }
        .field-newsletter-subscription {
            .checkbox__label {
                padding: 0;
                padding-left: 40px;
                font-weight: normal;
                font-size: 14px;
                font-family: Roboto, sans-serif;
                &:before {
                    top: 2px !important;
                    left: 8px;
                }
            }
            svg.checkbox__icon {
                top: 2px;
                left: 8px;
            }
        }
    }




    // formulaire de confirmation avant paiement (panier rapide)

    #co-payment-form {


        .billing-address-details {
            line-height: 1.5;

            a {
                color: $darkblue;
            }
        }

        .checkout-summary__title {
            text-align: left;
            margin-bottom: 20px;
        }

        .checkout-products {
            @include mq($screen-l ) {
                width: 40%;
                float: left;
            }
        }
        .opc-block-shipping-information {
            @include mq($screen-l ) {
                width: 60%;
                float: left;
            }
            @include mq($screen-s ) {
                .ship-to {
                    float: left;
                    width: calc(50% - 1em);
                    padding-left: 1em;
                    overflow: hidden;
                }
            }
            .ship-to {
                font-size: 14px;
                margin-bottom: 2em;
            }
            .shipping-information__title {
                margin-bottom: 0;
            }
            .shipping-information {
                margin-top: 8px;
                padding-right: 0;
                padding-left: 0;
            }
            .shipping-information__content {
                a {
                    color: $darkblue;
                }
            }
        }

        .cart-totals {
            width: 100%;
            clear: both;
            > div {
                float: right;
                width: 100%;
                @include mq($screen-l ) {
                    max-width: 320px;
                }
            }
        }

        .actions-toolbar-trigger {
            text-align: center;
        }

        .payment-group {
            margin-top: 30px;
        }

        .checkout-agreement {
            button {
                border: none;
                padding-top: 4px;
                background: none;
                text-decoration: underline;
            }
        }
    }


    // formulaire checkout - adresse de livraison

    .form-shipping-address {

        .control._with-tooltip {
            .field-tooltip {
                @include mq($screen-m ) {
                    position: relative;
                    width: 50%;
                    float: left;
                }
                .action-help {
                    display: none;
                }
                .field-tooltip-content {
                    display: block;
                    display: block;
                    position: relative;
                    padding: 0;
                    background: none;
                    border: none;
                    font-size: 12px;
                    color: #666;
                    width: 100%;
                    left: 0;
                    margin-top: 15px;
                    @include mq($screen-m ) {
                        left: 12px;
                        margin-top: 0;
                    }
                    &:after, &:before {
                        display: none;
                    }
                }
            }

            .intl-tel-input {
                @include mq($screen-m ) {
                    position: relative;
                    width: 50%;
                    float: left;
                }
                input {
                    width: 100% !important;
                }
            }
        }



        .input__field[name="telephone"] {

        }
    }
}

body.multishipping-checkout-addresses {
    .multicheckout__item .offer__infos {
        display: none;
    }

    .offer__image-wrapper {
        width: 100%;
    }
}

.page-layout-checkout .page-main .action.primary {
    background: $color-primary !important;
}

.page-layout-checkout .page-main .button-checkout__icon {
    top: 50%;
    margin-top: -8px;
}

.checkout-success {
    text-align: center;

    .actions-toolbar {
        margin-top: 1.5em;

        a.continue {
            @extend .button;
            @extend .button--link;

            width: auto;
        }
    }
}
.step-title-address {
    .warning {
        color: $pink;
        display: flex;
        align-items: center;
        margin-top: $spacer;
        @include mq($screen-m) {
            margin-top: 0;
        }

        font-weight: 400;
    }
    .warning__icon {
        fill: $pink;
        margin-right: $spacer;
        width: 34px;
        height: 34px;
    }
    .delivery-country {
        color: $teal;
        font-weight: normal;
        display: flex;
        align-items: center;
        margin-top: $spacer;
        @include mq($screen-m) {
            margin-top: 0;
        }
    }
    .delivery-country__icon {
        fill: $teal;
        margin-right: $spacer;
        width: 40px;
        height: 40px;
        min-width: 40px;
        min-height: 40px;
    }
}

body.checkout-index-index {
    .modal-inner-wrap {
        .modal-header {
            h1.heading--page {
                display: none !important;
            }
        }
        /*
        .prefix-container {
            > .label {
                margin-top: -10px;
                float: left;
                margin-right: 1em;
            }

        } */
    }
    .field.prefix-container {
        margin-top: 1em;
        &:after {
            clear: both;
        }

        > label {
            margin-top: -10px;
            float: left;
            margin-right: 1em;
        }
        > .control {
            float: left;
        }
    }
}

.payment-method__tip {
    display: none;
    .warning__icon {
        fill: $pink;
        margin-right: $spacer;
        height: 28px;
    }
    .delivery-country {
        color: $teal;
        font-weight: normal;
        display: flex;
        align-items: center;
        margin-top: $spacer;
        @include mq($screen-m) {
            margin-top: 0;
        }
    }
    .delivery-country__icon {
        fill: $teal;
        margin-right: $spacer;
        width: 40px;
        height: 40px;
        min-width: 40px;
        min-height: 40px;
    }
}

.switch {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 75px;
    height: 30px;
    padding: 3px;
    margin: 0 10px 10px 0;
    background: linear-gradient(to bottom, #eeeeee, #ffffff 25px);
    background-image: -webkit-linear-gradient(top, #eeeeee, #ffffff 25px);
    border-radius: 18px;
    box-shadow: inset 0 -1px white, inset 0 1px 1px rgba(0, 0, 0, 0.05);
    cursor: pointer;
}
.switch-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}
.switch-label {
    position: relative;
    display: block;
    height: inherit;
    font-size: 14px;
    /* text-transform: uppercase; */
    background: #eceeef;
    border-radius: inherit;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
}
.switch-label:before, .switch-label:after {
    position: absolute;
    top: 50%;
    margin-top: -0.5em;
    line-height: 1;
    -webkit-transition: inherit;
    -moz-transition: inherit;
    -o-transition: inherit;
    transition: inherit;
}
.switch-label:before {
    content: attr(data-off);
    right: 11px;
    color: $color-primary;
    text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}
.switch-label:after {
    content: attr(data-on);
    left: 11px;
    color: #ffffff;
    text-shadow: 0 1px rgba(0, 0, 0, 0.2);
    opacity: 0;
}
.switch-input:checked ~ .switch-label, .shipping-address-item.selected-item .switch-label {
    background: #e1b42b;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
}
.switch-input:checked ~ .switch-label:before, .shipping-address-item.selected-item .switch-label:before {
    opacity: 0;
}
.switch-input:checked ~ .switch-label:after, .shipping-address-item.selected-item .switch-label:after {
    opacity: 1;
}
.switch-handle {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 28px;
    height: 28px;
    background: linear-gradient(to bottom, #ffffff 40%, #f0f0f0);
    background-image: -webkit-linear-gradient(top, #ffffff 40%, #f0f0f0);
    border-radius: 100%;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}
.switch-handle:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -6px 0 0 -6px;
    width: 12px;
    height: 12px;
    background: linear-gradient(to bottom, #eeeeee, #ffffff);
    background-image: -webkit-linear-gradient(top, #eeeeee, #ffffff);
    border-radius: 6px;
    box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
}
.switch-input:checked ~ .switch-handle, .shipping-address-item.selected-item .switch-handle {
    left: 74px;
    box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}
/* Transition
    ========================== */
.switch-label, .switch-handle {
    transition: All 0.3s ease;
    -webkit-transition: All 0.3s ease;
    -moz-transition: All 0.3s ease;
    -o-transition: All 0.3s ease;
}

.switch-flat {
    padding: 0;
    background: #fff;
    background-image: none;
    margin-top: 12px;
}
.switch-flat .switch-label {
    background: #fff;
    border: solid 2px $color-primary;
    box-shadow: none;
}
.switch-flat .switch-label:after {
    color: white;
}
.switch-flat .switch-handle {
    top: 4px;
    left: 6px;
    background: #dadada;
    width: 22px;
    height: 22px;
    box-shadow: none;
}
.switch-flat .switch-handle:before {
    background: #eceeef;
}
.switch-flat .switch-input:checked ~ .switch-label, .shipping-address-item.selected-item .switch-flat .switch-label {
    background: $color-primary;
    border-color: $color-primary;
}
.switch-flat .switch-input:checked ~ .switch-handle, .shipping-address-item.selected-item .switch-flat .switch-handle {
    left: 47px;
    background: white;
    box-shadow: none;
}
.switch-flat .switch-input:checked ~ .switch-handle:before, .shipping-address-item.selected-item .switch-flat .switch-handle:before {
    background: $color-primary;
}
.switch-handle-label {
    padding-top: 6px;
    display: inline-block;
    margin-right: 1em;
    margin-left: 0;
    font-size: 24px;
    font-weight: 700;
}

.switch-handle-label-shipping {
    display: inline-block;
    line-height: 50px;
    margin: 0 10px 10px 0;
}
body.checkout-index-index {
    .field.field-telephone-tooltip {
        label {
            display: none;
        }
        .field-tooltip {
            width: 100% !important;
            float: none !important;
            text-align: center;
            display: block;
            .field-tooltip-content {
                font-size: 16px !important;
            }
        }
    }
}
.checkout-billing-address-choose-address-intro {
    margin: 1em 0;
    text-align: center;
}

body.page-layout-checkout {
    &[aria-busy="false"] {
        div#checkout-loader {
            display: none;
        }
    }
}