.multicheckout-summary {
    background: $white;
    box-shadow: $box-shadow__form;
    padding: $spacer--medium;

    display: block;
    @include mq($screen-l) {
        display: block;
    }

    &__title {
        color: $darkblue;
        font-size: 24px;
        font-weight: 700;
        border-bottom: 1px solid $gray-lighter;
        text-align: center;
        padding-bottom: $spacer--medium;
        margin: 0;
    }

    &__list {
        padding: $spacer--large 0;
        border-bottom: 1px solid $gray-lighter;
    }

    &__item {
        margin-bottom: $spacer--large;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    &__item-title {
        color: $darkblue;
        font-family: $font-family-sans-serif-alt;
        font-weight: 900;
        font-size: $font-size-medium;
        margin-bottom: $spacer;
    }
    &__item-description {
        color: $darkblue;
        font-family: $font-family-sans-serif-alt;
        font-weight: 700;
        font-size: $font-size-small;
        margin-bottom: $spacer;
    }
    &__item-price-area {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        color: $darkblue;
        font-size: $font-size-small;
        .price {
            margin: 0;
        }
        .price__value {
            font-size: $font-size-medium;
            &--old {
                font-size: inherit;
            }
        }
    }
    &__item-gift {
        display: flex;
        align-items: flex-end;
        font-size: $font-size-small;
        .icon {
            fill: $darkblue;
            margin-right: $spacer;
        }
    }

    &__incentive {
        color: $darkblue;
        font-weight: 700;
        font-family: $font-family-sans-serif-alt;
        margin: $spacer--large auto;
        display: block;
        text-align: center;
        .price__value {
            padding: 1px $spacer--small;
        }
    }

    &__totals {
        border-bottom: 1px solid $gray-lighter;
    }

    .totals {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        padding: $spacer 0;
        color: $darkblue;

        &__left {
            font-size: $font-size-small;
        }
        &__right {
            font-family: $font-family-sans-serif-alt;
            font-weight: 700;
            &--price {
                font-weight: 900;
            }
        }

        &--first {
            padding-top: $spacer--medium;
        }
        &--last {
            padding-bottom: $spacer--medium;
        }

        &--discount {
            color: $pink;
        }

        &--final {
            border-top: 1px solid $gray-lighter;
            padding: 12px 0;

            .totals__left {
                font-weight: 700;
            }
            .totals__right {
                font-size: $font-size-extra-large;
            }
        }


    }
}
