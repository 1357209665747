$link__color              : $blue !default;
$link__decoration         : underline !default;
$link__font-family        : $font-family-base !default;
$link__font-weight        : $font-weight-normal !default;
$link__color-hover        : $color-secondary !default;
$link__line-height        : $font-line-height !default;
$link__transition         : $transition-base !default;
$link__text-decoration    : none !default;
$link__color--invert      : $gray-darker !default;
$link__color-hover--invert: $blue !default;

.link {
    color: $link__color;
    text-decoration: $link__decoration;
    transition: $link__transition;
    font-family: $link__font-family;
    font-weight: $link__font-weight;
    line-height: $link__line-height;
    text-decoration: $link__text-decoration;

    &:hover,
    &:focus {
        color: $link__color-hover;
    }

    &--invert {
        color: $link__color--invert;

        &:hover,
        &:focus {
            color: $link__color-hover--invert;
        }

        .link__icon {
            fill: $link__color--invert;
        }
    }

    &--secondary {
        color: $gray-lighter;

        &:hover,
        &:focus {
            color: $color-primary;
        }
    }

    // visible only for screen readers and when focused
    &--visually-hidden {
        @include visually-hidden($usePseudo: true);
    }

    &__icon {
        width: 10px;
        height: 10px;
        margin-left: 10px;
        fill: $link__color;
    }
}

a {
    @extend .link;
}
