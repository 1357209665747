.issues-grid-item {

    &__button {
        margin-top: $spacer--medium;
    }

    &__button-icon {
        order: -1;
        margin-right: $spacer;
    }
}
