.overlay {
    background: $gray-lightest;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: $spacer--medium $spacer;
    text-align: left;

    @include mq($screen-s) {
        padding: $spacer--large $spacer--medium;
    }

    &__wishlist {
        position: absolute;
        z-index: 10;
        right: 10px;
        top: 5px;
        display: none;
        @include mq($screen-m) {
            display: block;
        }
        @include mq(1340px) {
            top: initial;
        }
        .button {
            min-width: auto;
            height: 26px;
            width: 26px;
            border: 1px solid $color-primary;
            background: transparent;
            .button__icon {
                fill: $color-primary;
                width: 16px;
                height: 16px;
            }

            &:hover,
            &.active {
                background: $color-primary;
                .button__icon {
                    fill: $white;
                }
            }
        }
    }

    &__header {
        display: flex;
    }

    &__thumb,
    .image {
        min-width: 70px;
        max-width: 70px;
        margin: 0;

        display: none;
        @include mq($screen-m) {
            display: block;
        }
    }

    &__title-wrapper{
        cursor: default;
        text-align: center;
        @include mq($screen-m) {
            text-align: left;
            max-width: calc(100% - (70px + #{$spacer--medium} + #{$spacer--large}));
            margin-left: $spacer--medium;
            margin-right: $spacer--large;
        }
    }

    &__title {
        font-size: $font-size-medium;
    }
    &__subtitle {
        color: $gray;
        font-size: $font-size-small;
        font-family: $font-family-condensed;
    }


    &__description {
        color: $darkblue;
        font-size: $font-size-small;
        font-family: $font-family-sans-serif-alt;
        font-weight: bold;
        text-align: center;
    }

    &__price {
        line-height: 1.5;
        display: none;
        @include mq($screen-m) {
            display: block;
        }
    }

    .button {
        @include mq(0, $screen-s) {
            font-size: $font-size-extra-small;
        }
    }

    .button + .button,
    form + .button {
        margin-top: $spacer;
    }

    &__btn-buy-icon,
    &__btn-see-icon {
        fill: $white;
    }
    &__btn-see-icon {
        margin-left: 10px;
    }
    &__btn-buy-icon {
        order: -1;
        margin-right: 10px;
        margin-top: -2px;

        @include mq($screen-s) {
            width: 16px;
            height: 16px;
            margin-top: -2px;
        }
    }

    &__actions {
        width: 100%;
        max-width: 200px;
        margin: 0 auto;
    }
}
