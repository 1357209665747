.input__field,
.select__field-list,
.select__field--native,
.checkbox__label:before,
.radio__icon {
    box-shadow: $box-shadow__input;
    color: #666;
}

.radio__icon {
  background: $white;
}

.input {
  &__field {
    &:-moz-placeholder { /* Firefox 18- */
      opacity:1;
    }
    &::-moz-placeholder {  /* Firefox 19+ */
      opacity:1;
    }
  }

}

.form-address-edit, body.customer-account-edit form.dashboard-form {
  margin: 0 auto 48px;
  padding: 48px 20px;
  box-shadow: $box-shadow__form;
  display: flex;
  flex-direction: column;
  background: $white;
  @include mq($screen-l) {
    padding: 48px calc(48px * 2);
  }
  .field-postalcode {
    @include mq($screen-l) {
      padding-right: 0 !important;
    }
  }
  .action {
    flex-direction: column;
    .button {
      padding: 0 $spacer--medium;
    }
  }
  .fieldset legend {
    margin: 16px 0;
    font-size: 32px;
    text-transform: none;
    font-family: Urbani, sans-serif;
    font-weight: 900;
    line-height: 1;
    color: #183446;
  }
  .country {
    select {
      width: 100%;
      height: 40px;
      line-height: normal;
      padding: 0 16px;
      border: 1px solid #183446;
      border-radius: 32px;
      color: #666;
    }
  }

  .radio__handler {
    display: inline-block;
  }

  .row {
    @include fix-gutter(40px);
  }

  .checkbox__label {
    font-weight: normal;
    &.no-padding {
      padding-bottom: 0;
    }
  }

   .separate-form {
    border-top: solid 1px #bcc4c5;
    margin-left: -96px;
    margin-right: -96px;
    margin-top: 30px;
     margin-bottom: 30px;
  }

  .manual-filling-btn {
    font-size: 14px;
    color: $darkblue;
    span {
      color: $color-primary;
      text-decoration: underline;
      margin-left: 0.25em;
    }
  }
  .has_complement_checkbox {
    .has_complement_help {
      padding-left: 35px;
      font-size: $font-size-small;
      font-family: $font-family-sans-serif;
    }
    .checkbox__label {
      padding-left: 35px;
      span {
        font-size: $font-size-small;
        color: $color-primary;
        text-decoration: underline;
        font-family: $font-family-sans-serif;
      }
      &:before {
        content: "+";
        height: 22px;
        width: 22px;
        display: inline-block;
        color: $white;
        background: $color-primary;
        margin-right: $spacer;
        border-radius: 50% !important;
        text-align: center;
        line-height: 22px;
        font-size: 22px;
        font-weight: 700;
        font-family: $font-family-sans-serif-alt;
        overflow: hidden;
        border: none;
        -webkit-box-shadow: none hoff voff blur #000;
        -moz-box-shadow: none hoff voff blur #000;
        box-shadow: none hoff voff blur #000;
      }
    }
    .checkbox__field:checked + .checkbox__icon {
      opacity: 0;
    }
  }


  p.small {
    font-size: 12px;
    color: #666;
    @include mq($screen-l ) {
      margin-left: -30px;
      margin-right: -55px;
      margin-top: 34px;
    }
    &.no-margin {
      margin: 0;
    }
    &.center {
      text-align:center;
    }
  }
  
  @include mq($screen-m) {
    .field-country,
    .field-street,
    .field-number,
    .field-postalcode + .field-case_postale {
      padding-right: 0 !important;
    }
  }
  
  .field-zip {
    .input__field {
      @include mq($screen-m) {
        padding: 0 20px;
      }
    }
  }
  
  .field-box_number {
    .input__field {
      padding: 0 15px;
    }
  }
  
  .message {
    margin-left: 20px;
    margin-right: 20px;
  }

  .actions-toolbar {
    margin-top: 1em;
    
    .secondary {
      margin-top: 1em;
      
      @include mq($screen-m) {
        margin-top: 0;
      }
    }
  }
}
.registration {
  .input-dob {
    #dob {
      max-width: 100%;
    }
    .ui-datepicker-trigger {
      float: right;
      display: block;
    }
  }
  .dob-message {
    padding: 0 !important;
    p {
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 0;
      font-size: 14px;
      @include mq($screen-m) {
        margin-top: 41px;
        margin-right: -35px;
      }
      @include mq($screen-l) {
        margin-top: 30px;
      }
    }
    .bold {
      font-weight: bold;
    }
  }
}
.hidden {
  display: none;
}

.input--finder {
  @include mq(0, $screen-m) {
    display: block;
  }
  .input__field {
    margin-bottom: 20px;
  }
}

.field-postcode,
.field-city,
.field.street,
.field-number
{
  display:block!important;
}


form input:disabled{
  opacity:0.3;
}

form input.read-only-suggest{
  opacity:0.3;
  background-color:#eee;
  cursor: not-allowed;
  &:not(:placeholder-shown) {
    opacity:1;
    background-color:#eee;
  }
}

select[name=city],
select[name="street[]"],
select[name="street[0]"]
{
  width: 100%;
  height: 40px;
  line-height: normal;
  padding: 0 16px;
  border: 1px solid #183446;
  border-radius: 32px;
}

.field.street .control .field label{
  display:none;
}

.field.loading .control{
  position:relative;
}
.field.loading .control::before {
  content: "";
  width: 25px;
  border: 14px solid #f3f3f3;
  border-radius: 50%;
  border-top: 14px solid #0295aa;
  border-bottom: 14px solid #0295aa;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  display: block;
  position: absolute;
  z-index:10;
  top: 6px;
  left: 7px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.input__field {
  padding: 0 15px;
  &.selectize-control {
    padding: 0 10px;
    .selectize-input {
      background: none;
    }
    .selectize-dropdown {
      padding: 0;
    }
  }
}
.checkbox__label:before {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
  border: solid 1px $darkblue;
}

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear
{
  display: none;
}

input::-ms-clear {
  width : 0;
  height: 0;
}

.login__input {
  position: relative;
}

label[for=number],
.field-postcode label,
.field-postalcode label,
.field-box_number label {
  white-space: nowrap;
}

@include mq($screen-m) {
  input[name=number] {
    padding: 0 15px;
  }
}

input.selectized-form-element-custom {
  opacity: 0;
  height: 0;
  display: block !important;
}


.selectized-global-container {
  .control {
    position: relative;
    input.selectized {
      display: block !important;
      opacity: 0;
    }
    .selectize-control {
      position: absolute;
      top: 0;
      background-color: #fff;
    }
  }
}


.email_shipping_help {
  font-size: $font-size-small;
  font-family: $font-family-sans-serif;
}

.field-has-complement {
  .admin__field.admin__field-option {
    margin-bottom: 0 !important;
  }
  .field-note {
    padding-left: 35px;
    font-size: $font-size-small;
    font-family: $font-family-sans-serif;
    margin-bottom: 16px;
  }
}
@media (min-width: $screen-m) {
  .field-element-with-note {
    .input {
      position: relative;
      width: 50%;
      float: left;
    }
  }
}
.field-element-with-note {
  .field-tooltip {
    position: relative !important;
    display: block !important;
  }
}

.manual-filling {
  text-align:center;


  .question-mark__icon {
    display: inline-block;
    margin-right: $spacer;
    width: 28px;
    height: 28px;
    min-width: 28px;
    min-height: 28px;
  }
  .manual-filling-btn {
    display: inline-block;
    vertical-align: middle;
    font-weight:bold;
    font-size:16px !important;
    div {

      display: -webkit-flex; /* Safari */
      display: flex;

      -webkit-justify-content: center;
      justify-content: center;
      p {
        margin:0;
        font-weight:bold;
        font-size:17px !important;
      }
    }
  }
}

