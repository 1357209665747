$select__margin-bottom                   : $spacer--medium !default;
$select__padding                         : 0 $spacer--medium 0 $spacer--medium !default;
$select__border                          : 1px solid $form-elements-border-color !default;
$select__spacing                         : 40px !default;
$select__border-radius                   : 20px !default;
$select__field-background                : $white !default;
$select__input-border-color              : $form-elements-border-color !default;
$select__field-list-border-radius--single: 20px !default;

.select {
    margin-bottom: $select__margin-bottom;

    &__field {
        position: relative;
        background: $select__field-background;

        &--native {
            width: 100%;
            height: $select__spacing;
            padding: 0 20px;
            padding-right: 40px;
            border: $select__border;
            border-radius: $select__border-radius;
            appearance: none;
            background-image: url(../images/arrow-select.svg);
            background-repeat: no-repeat;
            -webkit-background-size: 10px 5px;
            background-size: 10px 5px;
            background-position: calc(100% - 20px) center;
            &::-ms-expand {
                display: none;
            }
            
            &.one-value {
                background-image: none;
            }
        }
    }

    &__field-item {
        position: relative;
        height: $select__spacing;
        line-height: $select__spacing;
        padding: $select__padding;
        cursor: pointer;
        font-size: $font-size-base;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &__field-list {
        &--single {
            height: $select__spacing;
            line-height: $select__spacing;
            padding: 0;
            border: $select__border;
            border-radius: $select__border-radius;
        }
    }

    &__field-input {
        &:focus {
            outline: none;
        }
    }
}

// Choices.js lib styles overwrites
.choices {
    &.is-open {
        .select__field-list {
            &--single {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                border-top-left-radius: $select__field-list-border-radius--single;
                border-top-right-radius: $select__field-list-border-radius--single;
                border-bottom: 0;
            }
        }
    }

    &__list--dropdown {
        max-height: 300px;
        overflow: auto;
        z-index: 2;
        .is-open & {
            border-radius: 0 0 $select__border-radius $select__border-radius;
            border-color: $select__input-border-color;
        }

        & .choices__item--selectable {
            padding-right: $spacer--medium;
        }
    }
}
