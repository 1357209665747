$tab__border-width                    : 1px !default;
$tab__border-width--large             : 1px !default;

// title
$tab__title-padding                   : $spacer--medium $spacer--medium $spacer--medium $spacer--large !default;
$tab__title-border                    : $tab__border-width solid $gray-light !default;
$tab__title-border--large             : $tab__border-width--large solid $gray-lighter !default;
$tab__title-border--large-active      : $tab__border-width--large solid $gray-lighter !default;
$tab__title-color                     : $gray-dark !default;
$tab__title-color--active             : $darkblue !default;
$tab__title-font-weight--large-active : $font-weight-bold !default;
$tab__title-font-weight               : $font-weight-bold !default;
$tab__title-background                : $gray-lightest !default;
$tab__title-background--active        : $white !default;
$tab__title-background--large-active  : $white !default;

// content
$tab__content-padding                 : $spacer--large !default;
$tab__content-padding--large          : $spacer--large $spacer--large !default;

// icon
$tab__icon-color                      : $gray !default;
$tab__icon-color--active              : $gray-darker !default;
$tab__icon-padding                    : 16px !default;
$tab__icon-size                       : 48px !default;

.tab {
    border: 1px solid $gray-lighter;
    @include mq($screen-l) {
        display: flex;
        flex-flow: row wrap;
    }

    &__title {
        position: relative;
        display: block;
        width: 100%;
        padding: $tab__title-padding;
        border: $tab__title-border;
        border-width: $tab__border-width 0 0 0;
        background: $tab__title-background;
        text-align: left;
        font-weight: $tab__title-font-weight;
        color: $tab__title-color;
        cursor: pointer;
        font-family: $font-family-sans-serif-alt;

        &:first-of-type {
            border-left: 0;
            @include mq(0, $screen-l) {
                border-top: 0;
            }
        }
        &:last-of-type {
            @include mq(0, $screen-l) {
                border-bottom: 0;
            }
        }

        &:nth-last-child(2) {
            border-bottom: 0;

            @include mq($screen-l) {
                border-bottom: $tab__title-border--large;
                width: auto;
            }
        }

        @include mq($screen-l) {
            flex: 1;
            order: -1;
            border-top: none;
            border-width: $tab__border-width 0 0 $tab__border-width;
            border-bottom : $tab__title-border--large;
        }

        .tab__icon {
            fill: $tab__icon-color;
            pointer-events: none;
        }

        &--active {
            border-top: none;
            border-bottom: none;
            background: $tab__title-background--active;
            color: $tab__title-color--active;

            &:nth-last-child(2) {
                @include mq($screen-l) {
                    border-bottom: 0;
                }
            }

            @include mq($screen-l) {
                border-bottom: 0;
                background-color: $tab__title-background--large-active;
                font-weight: $tab__title-font-weight--large-active;
                z-index: 2;
            }

            .tab__icon {
                transform: rotateX(180deg);
                fill: $tab__icon-color--active;
            }
        }

        .counter {
            &:before {
                content: "(";
            }

            &:after {
                content: ")";
            }
        }
    }

    &__icon {
        position: absolute;
        right: 15px;
        top: 0;
        bottom: 0;
        margin: auto;
        width: $tab__icon-size;
        height: $tab__icon-size;
        padding: $tab__icon-padding;

        @include mq($screen-l) {
            display: none;
        }
    }

    &__content {
        position: relative;
        display: none;
        width: 100%;
        padding: $tab__content-padding;
        z-index: -1;
        opacity: 0;
        overflow: hidden;

        @include mq($screen-l) {
            padding: $tab__content-padding--large;
            flex-basis: 100%;
            z-index: 1;
        }

        &--active {
            display: block;
            opacity: 1;
            z-index: 1;

            &:nth-last-child(1) {
                border-bottom: none;

                @include mq($screen-l) {
                    border-bottom: none;
                }
            }
        }
    }
}
