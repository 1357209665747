.selection__list {
    @include mq($screen-s) {
        @include fix-gutter(42px);
    }
}

.selection__title {
    color: $darkblue;
    text-align: center;
    margin-bottom: 30px;

    @include mq(0, $screen-s) {
        font-size: 30px;
        margin-bottom: 50px;
    }

    &:after {
        content: "";
        width: 75px;
        height: 3px;
        background: $color-primary;
        display: block;
        margin: 30px auto 0;
    }
}

.selection__item {
    box-shadow: $box-shadow__selection;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    @include clearfix;
    margin-bottom: 24px;
    @include mq($screen-s) {
        margin-bottom: 42px;
    }
}

.selection__inner {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 140px;
    padding-right: 15px;
}

.selection__image {
    width: 125px;
    height: 175px;
}

.selection__description {
    font-family: $font-family-sans-serif-alt;
    color: $darkblue;
    line-height: 1.2;
    margin-bottom: ($spacer--medium * 1.5);
    font-size: $font-size-medium;
    font-weight: 300;
}

.selection__icon {
    margin-left: 10px;
}

.selection__button {

    @include mq(0, $screen-s) {
        font-size: 10px;
    }
    @include mq($screen-s) {
        padding: 0 $spacer--medium;
    }
    @include mq($screen-m) {
        width: 100%;
        text-align: center;
    }
}
