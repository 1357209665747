$slider__margin                       : $spacer--medium !default;
$slider__transition                   : $transition-base !default;
$slider__item-width                   : 50% !default;
$slider__item-width\@medium           : 20% !default;
$slider__navigation-margin            : $spacer !default;
$slider__navigation-icon-size         : 54px !default;
$slider__navigation-icon-margin       : $spacer--medium !default;
$slider__navigation-icon-background   : $color-primary !default;
$slider__icon-size                    : 22px !default;
$slider__icon-fill                    : #fff !default;
$slider__icon-fill-hover              : #fff !default;
$slider__dot-margin                   : $spacer !default;
$slider__dot-padding                  : 0 !default;
$slider__dot-inside-size              : 10px !default;
$slider__dot-inside-background        : $gray-lighter !default;
$slider__dot-inside-border-radius     : $spacer !default;
$slider__dot-inside-background--active: $color-primary !default;

.slider {
    margin-top: $spacer--large;
    margin-bottom: $slider__margin;
    position: relative;
    text-align: center;

    @include mq($screen-l) {
        width: calc(100% - ((#{$slider__navigation-icon-size} + #{$slider__navigation-icon-margin}) * 2));
        margin: $spacer--large auto $slider__margin;
    }

    &__handler {
        overflow: hidden;
    }

    &__slides {
        display: flex;
    }

    &__item {
        position: relative;
        min-width: $slider__item-width;
        width: $slider__item-width;
        border: 0;
        padding: 0 15px;

        @include mq($screen-s) {
            min-width: 33.33%;
            width: 33.33%;
        }

        @include mq($screen-m) {
            min-width: $slider__item-width\@medium;
            width: $slider__item-width\@medium;
        }

    }

    &__navigation {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: $slider__navigation-margin;

    }

    &__navigation-icon {
        display: none;
        justify-content: center;
        align-items: center;
        height: $slider__navigation-icon-size;
        width: $slider__navigation-icon-size;
        background-color: rgba($slider__navigation-icon-background, 0.5);
        border: 0;

        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        @include mq($screen-l) {
            display: flex;
        }

        &:first-child {
            margin-right: $slider__navigation-icon-margin;
            left: -($slider__navigation-icon-size + $slider__navigation-icon-margin);

            .slider__icon {
                margin-left: -4px;
            }
        }

        &:last-child {
            margin-left: $slider__navigation-icon-margin;
            right: -($slider__navigation-icon-size + $slider__navigation-icon-margin);

            .slider__icon {
                margin-right: -4px;
            }
        }

        &:hover,
        &:focus,
        &:active {
            background-color: $slider__navigation-icon-background;

            .slider__icon {
                fill: $slider__icon-fill-hover;
            }
        }
    }

    &__icon {
        width: $slider__icon-size;
        height: $slider__icon-size;
        fill: $slider__icon-fill;
        transition: $slider__transition;
        cursor: pointer;
    }

    &__dot-navigation {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        margin-top: $spacer--medium;
    }

    &__dot {
        display: block;
        padding: $slider__dot-padding;
        margin-right: $slider__dot-margin;
        cursor: pointer;

        &--active {
            .slider__dot-inside {
                background-color: $slider__dot-inside-background--active;
            }
        }

        &:last-child {
            margin-right: 0;
        }
    }


    &__dot-inside {
        display: block;
        width: $slider__dot-inside-size;
        height: $slider__dot-inside-size;
        background-color: $slider__dot-inside-background;
        border-radius: $slider__dot-inside-border-radius;
    }


}
