$cart-list-item__padding                   : $spacer--medium 0 !default;
$cart-list-item__border                    : 1px solid $gray-light !default;

$cart-list-item__image-width               : 160px !default;
$cart-list-item__image-padding             : 0 $spacer--medium 0 0 !default;

$cart-list-item__name-weight               : $font-weight-bold !default;
$cart-list-item__name-width                : 65% !default;
$cart-list-item__name-padding\@small       : 0 $spacer--medium 0 0 !default;

$cart-list-item__details-width             : calc(100% - #{$cart-list-item__name-width}) !default;
$cart-list-item__details-margin            : $spacer--medium 0 !default;
$cart-list-item__details-margin\@small     : 0 !default;

$cart-list-item__attributes-margin         : 0 0 $spacer--medium 0 !default;

$cart-list-item__price-margin              : $spacer 0 0 0 !default;
$cart-list-item__price-margin\@medium      : 0 !default;

$cart-list-item__qty-width                 : 100% !default;
$cart-list-item__qty-width\@small          : auto !default;

$cart-list-item__qty-wrapper-margin        : 0 !default;

$cart-list-item__qty-input-width\@small    : 70px !default;

$cart-list-item__actions-item-margin       : 0 0 0 $spacer !default;

.cart-list-item {
    display: flex;
    width: 100%;
    flex-flow: row nowrap;
    padding: $cart-list-item__padding;
    border-bottom: $cart-list-item__border;

    &__image {
        flex: 0 0 $cart-list-item__image-width;
        padding: $cart-list-item__image-padding;
    }

    &__info {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        flex: 1;
    }

    &__data {
        display: flex;
        flex-direction: column;
        @include mq($screen-m) {
            flex-flow: row wrap;
        }
    }

    &__name {
        line-height: $font-line-height;
        text-decoration: none;
        font-weight: $cart-list-item__name-weight;
        @include mq($screen-m) {
            flex: 0 0 $cart-list-item__name-width;
            padding: $cart-list-item__name-padding\@small;
        }

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    &__details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        margin: $cart-list-item__details-margin;
        @include mq($screen-m) {
            flex: 0 0 $cart-list-item__details-width;
            margin: $cart-list-item__details-margin\@small;
            flex-direction: row;
            align-items: center;
        }
    }

    &__attributes {
        margin: $cart-list-item__attributes-margin;
    }

    &__price {
        margin: $cart-list-item__price-margin;
        @include mq($screen-m) {
            margin: $cart-list-item__price-margin\@medium;
        }
    }

    &__qty {
        width: $cart-list-item__qty-width;
        @include mq($screen-m) {
            width: $cart-list-item__qty-width\@small;
        }
    }

    &__qty-wrapper {
        margin: $cart-list-item__qty-wrapper-margin;
    }

    &__qty-input {
        @include mq($screen-m) {
            width: $cart-list-item__qty-input-width\@small;
        }
    }

    &__actions {
        display: flex;
        justify-content: flex-end;
    }

    &__actions-item {
        margin: $cart-list-item__actions-item-margin;
    }
}
