.widget-product-grid {
    list-style: none;
    padding: 0;
    margin-left: -8px;
    .product-item {
        width: calc(20% - 8px);
        margin-left: 8px;
        display: inline-block;
    }
}
