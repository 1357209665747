$header__border-color                   : $gray-lighter !default;

$header__wrapper-padding                : 0 !default;
$header__wrapper-padding\@medium        : 0 !default;

$header__logo-padding                   : 6px 0 6px $spacer--medium !default;
$header__logo-padding\@medium           : 0 !default;

$header__buttons-width                  : calc(100% - 42px)  !default;
$header__buttons-width\@medium          : calc(2 * 62px) !default;
$header__buttons-width\@extra-large     : calc(4 * 122px) !default;

$header__search-wrapper-padding         : 12px $spacer !default;
$header__search-wrapper-padding\@medium : 0 24px !default;


.header {

    z-index: 1000;
    margin: 0;
    font-family: $font-family-sans-serif-alt;
    background: #fff;

    .logo__image {
        max-height: 68px;
    }
    position: -webkit-sticky;
    /* position: fixed; */
    position: sticky;

    top: 0;
    left: 0;
    width: 100%;
    right: 0;
    padding: 0;


    @include mq($screen-xl) {
        height: 137px;
    }

    &__preheader {
        background: $color-secondary;
        height: 35px;
        text-align: right;
        display: none;
        font-size: 15px;

        @include mq($screen-xl) {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }

    &__nav {
        display: inline-flex;
    }
    &__nav-item {
        margin-right: 41px;
        position: relative;
        &:after {
            content: "";
            width: 1px;
            height: 15px;
            background: #bcc4c5;
            position: absolute;
            top: 5px;
            right: -20px;
        }
        &:last-child:after {
            display: none;
        }

        a {
            color: #fff;
            &:hover {
                color: #fff;
                text-decoration: underline;
            }
        }
    }


    &__i18n {
        //display: inline-flex; to change when multi website is enabled
        display: none;
        margin-right: 10px;
        margin-top: 7px;
    }

    &__i18n-link {
        color: $gray-lighter;
        padding: 0 5px;
        font-size: $font-size-small;
        margin-left: 5px;
        &--active {
            color: #fff;
            border: 1px solid #fff;
        }
        &:hover {
            color: #fff;
        }
    }

    &__wrapper {
        padding: $header__wrapper-padding;
        box-shadow:  $box-shadow__default;

        @include mq($screen-m) {
            padding: $header__wrapper-padding\@medium;
        }
    }

    &__container {
        margin: 0 auto;
        display: flex;
        flex-flow: row wrap;
        @include mq($screen-xl) {
            justify-content: space-between;

        }
    }

    &__logo {
        order: 1;
        padding: $header__logo-padding;
        transition: none;
        @include mq($screen-xl) {
            padding: $header__logo-padding\@medium;
            margin-left: 20px;
            margin-top: -35px;
        }
    }

    &__search-wrapper {
        display: flex;
        flex-flow: column nowrap;
        flex: 1 100%;
        order: 5;
        justify-content: center;
        padding: $header__search-wrapper-padding;
        border-top: 1px solid $header__border-color;


        @include mq($screen-l) {
            order: 3;
            flex-direction: column;
            justify-content: center;
            padding: $header__search-wrapper-padding\@medium;
            border-top: none;
            flex: 0 50%;
            margin-left: auto;
        }

        @include mq($screen-xl) {
            flex: 1 30%;
        }
    }

    &__minicart {
        position: relative;
    }

    &__minicart-content {
        top: calc(100% + 1px);
        &.popup {
            position: absolute;
            right: 0;
        }
    }

    &__buttons {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-end;
        order: 2;
        max-width: $header__buttons-width;
        margin-left: auto;

        @include mq($screen-s) {
            position: relative;
        }

        @include mq($screen-m) {
            order: 4;
            flex: 1 $header__buttons-width\@medium;
            max-width: $header__buttons-width\@medium;
        }

        @include mq($screen-xl) {
            flex: 1 $header__buttons-width\@extra-large;
            max-width: $header__buttons-width\@extra-large;
        }
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    // Your custom CSS goes here
    .header {
        position: fixed;
    }
}

.deal a {
    text-transform: uppercase;
    color: $pink;
    font-weight: 900;
    font-size: $font-size-base;
    transition: none;
}
