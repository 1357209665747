$container__max-width: $max-content-width !default;
$container__margin   : 8px !default;

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.container {
    margin: 0 $container__margin;

    @include mq($screen-xl) {
        margin: 0 auto;
        width: $max-content-width-xl;
    }
    @include mq($screen-xxl) {
        width: $max-content-width;
    }
}
