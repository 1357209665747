$footer__padding                       : $spacer--large;
$footer__outer-padding                 : 120px;
$footer__background-color              : $color-primary !default;
$footer__background-color\@large       : $color-primary !default;

$footer__newsletter-padding            : 0 $spacer--medium !default;

$footer__link-color                    : $white !default;
$footer__link-color-hover              : $white !default;
$footer__link-text-decoration-hover    : underline !default;

$footer__link-inner-padding            : $spacer--medium !default;

$footer__bottom-bar-background         : $color-secondary !default;
$footer__bottom-bar-background\@large  : $color-secondary !default;
$footer__bottom-bar-color              : $white !default;
$footer__bottom-bar-font-size          : $font-size-small !default;

$footer__social-handl-margin           : 0 0 $spacer--extra-large 0 !default;
$footer__social-handl-margin\@medium   : 0 !default;

$footer__social-icon-color             : $white !default;

$footer__copywrite-padding             : $spacer--medium;

$footer__scroll-top-top                : -80px !default;
$footer__icon-size                     : 13px !default;
$footer__icon-fill                     : $white !default;
$footer__icon-fill-hover               : $color-primary !default;

$footer__title-color                    : $darkblue !default;


.footer {
    margin: 0;
    flex-shrink: 0;
    background-color: $footer__background-color;
    font-family: $font-family-sans-serif-alt;

    @include mq($screen-xl) {
        background-color: $footer__background-color\@large;
    }

    &__intro {
        padding: 0;
        &-handler {
            padding: 0 20px;
            @include mq($screen-m) {
                padding: inherit;
            }
        }
    }

    &__handler {
        margin: 0 auto $footer__padding;
        padding: $footer__padding 0;
        position: relative;
        @include mq($screen-m) {
            margin: 0 30px;
        }

        @include mq($screen-xl) {
            display: flex;
            flex-flow: row wrap;
            margin: 0 20px;
            width: auto;
        }

        @include mq($screen-xml) {
            margin: 0 30px;
        }
    }

    &__newsletter {
        margin: 0 auto $footer__padding;
        padding: $footer__newsletter-padding;
        order: 2;
        text-align: center;
        @include mq($screen-l) {
            order: 0;
            padding-top: $spacer--medium;
        }

        @include mq($screen-xl) {
            text-align: left;
            order: 1;
            padding-right: 0;
            padding-left: 40px;
        }
    }

    &__links-subtitle {
        display: block;
        text-align: center;
        font-size: $font-size-medium;
        font-weight: 900;
        margin: 16px 0 24px 0;
        color: $footer__title-color;
        @include mq($screen-xl) {
            margin-right: $footer__padding;
            text-align: left;
            padding-left: 16px;
        }
    }
    &__links {
        margin-bottom: 32px;
        text-align: center;
        padding: 0;
        @include mq($screen-xl) {
            text-align: left;
        }
        .list__item {
            padding: 0;
            margin-bottom: 10px;

           /* @include mq($screen-xl) {
                &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6) {
                    width: 160px;
                }
                &:nth-child(7), &:nth-child(8), &:nth-child(9), &:nth-child(10),  &:nth-child(11), &:nth-child(12) {
                    margin-left: -35px;
                    white-space: nowrap;
                }
            } */

        }
    }

    &__links-list {
        padding-bottom: $footer__padding;
        @include mq($screen-s) {
            columns: 2;
        }
        @include mq($screen-m) {
            columns: 3;
        }
        @include mq($screen-xl) {
            padding-left: 16px;
            columns: 2;
        }
        @include mq($screen-xxl) {
            width: 90%;
        }
    }


    &__link {
        color: $footer__link-color;
        text-decoration: none;

        &:hover,
        &:focus {
            color: $footer__link-color-hover;
            text-decoration: $footer__link-text-decoration-hover;
        }
    }

    &__bottom-bar {
        color: $footer__bottom-bar-color;
        background-color: $footer__bottom-bar-background;
        padding: 0;
        @include mq($screen-xl) {
            background-color: $footer__bottom-bar-background\@large;
        }
    }

    &__bottom-bar-handler {
        text-align: center;
        @include mq($screen-xl) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        @include mq($screen-m) {
            margin: 0 30px;
        }

        @include mq($screen-xl) {
            margin: 0 20px;
        }

        @include mq($screen-xml) {
            margin: 0 30px;
        }
    }

    &__social-handler {
        margin: $footer__social-handl-margin;

        @include mq($screen-xl) {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            order: 1;
            margin: $footer__social-handl-margin\@medium;
        }
    }

    &__social-list-subtitle {
        display: block;
        text-align: center;
        font-size: $font-size-medium;
        font-weight: 900;
        margin-bottom: 24px;
        color: $footer__title-color;
        @include mq($screen-xl) {
            margin-right: $footer__padding;
            text-align: left;
            margin-bottom: 0;
        }
    }

    &__social-list {
        .footer__social-link {
            padding: 0 6px;
        }
    }


    &__social-icon {
        width: 36px;
        height: 36px;
        fill: $footer__social-icon-color;
    }

    &__payments-handler {
        margin: 0;
        min-height: 36px;
        @include mq($screen-xl) {
            padding-left: $spacer--medium;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            order: 1;
            margin: $footer__social-handl-margin\@medium;
        }
    }

    &__payments-list-subtitle {
        display: block;
        font-size: $font-size-medium;
        font-weight: 900;
        margin-bottom: 24px;
        text-align: center;
        color: $footer__title-color;
        flex-shrink: 0;
        margin-top: -10px;
        @include mq($screen-xl) {
            text-align: left;
            margin-right: 35px;
            margin-bottom: 0;

        }
    }

    &__payments-list {
        justify-content: center;
        align-items: center;
        @include mq($screen-xl) {
            justify-content: flex-start;
        }
    }

    &__payments-item {
        margin-right: 25px;
        img {
            height: 40px;
            width: auto;
            margin-bottom: 10px;
        }
        &:last-child {
            margin-right: 0;
        }
    }

    &__copyright {
        display: block;
        padding: $footer__copywrite-padding 0 $spacer;
        margin-left: 0;
        font-size: $footer__bottom-bar-font-size;
        text-align: center;

        @include mq($screen-xl) {
            margin-left: 8px;
            padding: $footer__copywrite-padding 0;
        }

        a {
            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__netdesign {
        margin: 0 0 $spacer;
        display: inline-block;
        @include mq($screen-xl) {
            margin: 0;
        }
        img {
            width: auto;
        }
    }

    &__scroll-top {
        flex-direction: column-reverse;
        position: fixed;
        bottom: 60px;
        right: 30px;
        border-radius: $border-radius;
        height: 40px;
        min-width: 40px;
        line-height: 1;
        transition: none;
        z-index: 10;
        display: none;
        @include mq(0, $screen-xl) {
            display: none !important; //sass-lint:disable-line no-important
        }
        @include mq($screen-xl) {
            &[style*='display: block'] {
                display: flex !important; //sass-lint:disable-line no-important
            }
        }


        .footer__icon {
            fill: $white;
            width: 10px;
            height: 10px;
            margin-bottom: $spacer--small;
        }

        &:hover,
        &:focus {
            .footer__icon {
                fill: $white;
            }
        }
    }

    &__intro-handler {
        border-bottom: 1px solid $gray-lighter;
        padding-bottom: 24px;
        margin: 0 0 16px;

        @include mq($screen-l) {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
        }
    }

    &__intro-logo {
        padding: 0;
    }

    &__logo {
        max-width: 190px;
        margin: 0 auto 30px;
        
        .logo__image {
            height: auto;
            max-height: 100%;
            flex-shrink : 0; 
        }
        @include mq($screen-l) {
            margin: 0;
        }
    }

    &__intro-text {
        margin-bottom: 30px;
        color: #fff;
        font-size: $font-size-medium;
        text-align: center;
        padding: 0;
        @include mq($screen-m) {
            padding: inherit;
        }
        @include mq($screen-l) {
            padding: 0 0 0 40px;
            margin-bottom: 0;
            text-align: left;
        }
        @include mq($screen-xl) {
            padding: 0 40px 0 80px;
        }
    }

    &__intro-button {
        text-align: center;
        a {
            font-family: $font-family-sans-serif-alt;
            font-weight: 800;
            color: $black;
            padding: 0 20px;
            background: $gray-lightest;
            &:hover {
                background: $gray-lighter;
                color: $black;
            }
        }
    }

    &__icon {
        width: $footer__icon-size;
        height: $footer__icon-size;
        fill: $footer__icon-fill;
    }

    &__social {
        order: 2;
    }

    &__payments {
        order: 3;
    }

    &__bio {
        margin-bottom: $footer__padding;
        text-align: left;
        padding-left: 20px;
        padding-right: 20px;
      @include mq($screen-m) {
          padding-left: inherit;
          padding-right: inherit;
      }
    }

    &__bio-title {
        margin: 16px 16px 24px 0;
        font-weight: 900;
        font-size: $font-size-medium;
        color: $footer__title-color;
        text-align: center;
        @include mq($screen-m) {
            text-align: left;
        }
    }
    &__bio-text {
        margin: 8px 16px 8px 0;
        color: #fff;
        line-height: 1.3;

        a {
            text-decoration: underline;
        }
    }
}
