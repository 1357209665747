$mega-menu__margin                           : 0 !default;
$mega-menu__border-bottom                    : 1px solid $gray-light !default;
$mega-menu__font-color                       : $gray-darker !default;
$mega-menu__font-weight                      : 700 !default;
$mega-menu__font-size                        : 15px !default;
$mega-menu__font-size--large                 : 15px !default;
$mega-menu__background-color                 : $white !default;

$mega-menu__list-padding                     : 0 !default;
$mega-menu__list-justify-content             : flex-start !default;
$mega-menu__list-background-color            : $white !default;

$mega-menu__inner-list-padding               : 0 !default;
$mega-menu__inner-list-background-color      : $white !default;
$mega-menu__inner-list-box-shadow--level1    : none !default;
$mega-menu__inner-list-border--level1        : 1px solid $gray-light !default;

$mega-menu__inner-item-padding--parent       : $spacer--large 0 $spacer--large ($spacer--large * 2) !default;
$mega-menu__inner-item-padding--parent-child : 0 !default;
$mega-menu__inner-item-maargin--parent-child : 0 0 $spacer--medium !default;

$mega-menu__inner-item-padding--image        : 0 0 0 112px !default;

$mega-menu__link-padding                     : 18px $spacer--medium 18px 12px !default;
$mega-menu__link-margin                      : 0 !default;
$mega-menu__link-color                       : $darkblue !default;
$mega-menu__link-color--active               : $white !default;
$mega-menu__link-text-decoration             : none !default;

$mega-menu__inner-link-margin                : 0 !default;
$mega-menu__inner-link-padding               : 0 !default;
$mega-menu__inner-link-font-weight           : $font-weight-normal !default;
$mega-menu__inner-link-text-decoration       : none !default;
$mega-menu__inner-link-color                 : $gray-darker !default;
$mega-menu__inner-link-color--active         : $color-primary !default;

.mega-menu {
    display: none;
    background: $mega-menu__background-color;
    color: $mega-menu__font-color;
    margin: $mega-menu__margin;
    will-change: min-height;
    z-index: 200;
    position: relative;
    
    &.active-megamenu {
        position: fixed;
        top: 137px;
        bottom: 0;
        overflow-y: auto;
    }

    @include mq($screen-xl) {
        display: inline-block;
    }

    &__title {
        padding: $mega-menu__link-padding;
        text-align: center;
        font-weight: 900;
        font-size: $font-size-medium;
        color: $darkblue;
        border-bottom: 1px solid $gray-lighter;
        border-right: 1px solid $gray-lighter;
        font-family: $font-family-sans-serif-alt;
    }

    &__list {
        flex-wrap: nowrap;
        justify-content: $mega-menu__list-justify-content;
        padding: $mega-menu__list-padding;
        list-style: none;
        background-color: $mega-menu__list-background-color;
        margin: 0;
        z-index: 4;
        width: 250px;
        height: 100%;
        transform: translate3d(0, 0, 0);
        will-change: position, transform;
    }

    &__inner-list {
        padding: $mega-menu__inner-list-padding;
        background-color: $mega-menu__inner-list-background-color;
        list-style: none;
        &--level1 {
            display: none;
            position: absolute;
            top: 0;
            left: 250px;
            right: 0;
            box-shadow: $mega-menu__inner-list-box-shadow--level1;
            border-left: 5px solid $color-primary;
            bottom: 0;
            @include clearfix;
            
            .columns & {
                display: flex;
                position: fixed;
                top: 137px;
                z-index: 200;
                margin: 0;
            }
        }

        &--level2 {
            background: transparent;
        }
    }


    &__inner-item {
        &--parent {
            padding: $mega-menu__inner-item-padding--parent;
            & > .mega-menu__inner-link {
                display: block;
                margin: $mega-menu__inner-item-maargin--parent-child;
                padding:  $mega-menu__inner-item-padding--parent-child;
                font-size: $mega-menu__font-size;
                font-weight: $mega-menu__font-weight;
            }
        }

        &--image {
            display: flex;
            flex-basis: 100%;
            justify-content: flex-end;
            padding: $mega-menu__inner-item-padding--image;
            img {
                width: auto;
            }
        }
    }

    &__link {
        display: block;
        margin: $mega-menu__link-margin;
        padding: $mega-menu__link-padding;
        font-size: $mega-menu__font-size--large;
        font-weight: $mega-menu__font-weight;
        font-family: $font-family-sans-serif-alt;
        color: $mega-menu__link-color;
        text-decoration: $mega-menu__link-text-decoration;
        border-bottom: 1px solid $gray-lighter;
        border-right: 1px solid $gray-lighter;


        &:focus,
        &:hover,
        .active & {
            text-decoration: $mega-menu__link-text-decoration;
            color: $mega-menu__link-color--active;
            background: $color-primary;
            border-color: $color-primary;

            .mega-menu__icon {
                fill: $white;

                &--all {
                    fill: $color-primary;
                }
            }
        }

        &--all,
        .mega-menu__item--all > & {
            border: 0;
            color: $color-primary;
            text-align: center;
            margin: 10px 0;

            &:focus,
            &:hover {
                color: $color-primary;
                background: $white;
                text-decoration: underline;

                .mega-menu__icon {
                    fill: $color-primary;
                }
            }
        }
    }

    &__inner-link {
        margin: $mega-menu__inner-link-margin;
        padding: $mega-menu__inner-link-padding;
        white-space: nowrap;
        font-size: $mega-menu__font-size;
        font-weight: $mega-menu__inner-link-font-weight;
        color: $mega-menu__inner-link-color;
        text-decoration: $mega-menu__inner-link-text-decoration;
        &:focus,
        &:hover {
            text-decoration: $mega-menu__inner-link-text-decoration;
            color: $mega-menu__inner-link-color--active;
        }
    }

    &__column-hidden {
        > .mega-menu__inner-link {
            display: none;
        }
    }

    &__icon {
        float: right;
        fill: $color-primary;
        height: 10px;
        width: 10px;
        padding: 0;
        margin-top: 6px;

        &--all,
        .mega-menu__item--all & {
            float: none;
            display: inline;
            margin-left: 10px;
        }
    }

    &__preicon {
        font-size: 24px;
        margin-right: 15px;
        float: left;
        width: 30px;
        text-align: center;
    }

    &__close {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 32px;
        height: 32px;
        border-radius: 16px;
        background: $gray-darker;

        &:hover {
            background: $color-primary;
        }
    }

    &__close-icon {
        fill: #fff;
        margin: 0 auto;
        width: 20px;
        height: 32px;
    }
}

.node-block {
    width: 70%;
    background: $gray-lightest url(../images/pattern.png) repeat;
    padding: 40px 120px 40px 40px;
    overflow: auto;
    padding-bottom: 20px;
}
.node-subcategories {
    width: 40%;
    display: flex;
    overflow: auto;
}

.node-banner {
    margin-bottom: 30px;
}

.node-widget__title {
    font-family: $font-family-sans-serif-alt;
    font-weight: 900;
    font-size: 28px;
    margin-bottom: 35px;
    color: $darkblue;
}

.category-children {
    margin: 40px auto;

    &__title {
        font-family: $font-family-sans-serif-alt;
        font-weight: 900;
        font-size: 18px;
        margin-bottom: 40px;
        color: $darkblue;
    }

    &__list {
        font-family: $font-family-sans-serif-alt;
        font-size: 15px;
        margin-bottom: 40px;
        list-style: none;
        padding: 0;

        .button__icon {
            fill: $color-primary;
            width: 12px;
            height: 12px;
            margin-right: 10px;
        }
    }

    &__item {
        padding: 6px 0;
        a {
            color: $gray-darkest;
            &:hover {
                color: $color-primary;
            }
        }
    }


    &__button {
        font-size: 13px;
        height: 42px;
        max-width: 280px;
        padding: 0 24px;
        text-align: center;
        margin-bottom: 20px;
        .button__icon {
            width: 10px;
            height: 10px;
            margin-left: 10px;
        }
    }


}

