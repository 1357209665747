.cms-header {
    height: 204px;
    background: $color-secondary url(../images/cms/banner.png);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-repeat: no-repeat;
    @include mq(0, $screen-m) {
        min-height: 204px;
        height: auto;
    }

    .title-wrapper {
        text-align: center;
        margin: 0;
    }

    .heading {
        color: #fff;
        text-shadow: $text-shadow__title;
        @include mq(0, $screen-m) {
            font-size: 32px;
        }
    }
}
