$registration__padding-top                                  : 0 !default;

$registration__content-margin                               : 0 auto $spacer--extra-large !default;
$registration__input-margin                                 : 0 0 $spacer--medium !default;

$registration__fieldset-margin                              : 0 0 $spacer--medium !default;

$registration__legend-font-size                             : $font-size-base !default;
$registration__legend-font-weight                           : $font-weight-bold !default;
$registration__legend-margin                                : 0 !default;
$registration__legend-padding                               : 0 0 $spacer--medium 0 !default;
$registration__legend-width                                 : 100% !default;

$registration__newsletter-checkbox-checked-label-font-weight: $font-weight-bold !default;

$registration__password-wrapper-margin                      : 0 !default;
$registration__password-margin                              : 0 !default;

.registration {
    padding-top: $registration__padding-top;

    .row {
        @include fix-gutter(40px);
    }

    &__divider {
        margin-bottom: $spacer--large;
    }

    &__content {
        margin: $registration__content-margin;
        padding: 20px;
        box-shadow: $box-shadow__form;
        display: flex;
        flex-direction: column;
        background: $white;

        @include mq($screen-m) {
            padding: $spacer--extra-large;
        }

        @include mq($screen-l) {
            padding: $spacer--extra-large calc(#{$spacer--extra-large} * 2);
            max-width: 1120px;
        }
    }

    &__input {
        margin: $registration__input-margin;
    }

    &__legend {
        position: relative;
        margin: $registration__legend-margin;
        padding: $registration__legend-padding;
        width: $registration__legend-width;
        font-size: $registration__legend-font-size;
        font-weight: $registration__legend-font-weight;
    }


    &__fieldset {
        margin: $registration__fieldset-margin;
        margin-top: 30px;
        &.no-margin {
            margin-top: 0;
        }
    }

    &__newsletter-checkbox-field {
        &:checked ~ .checkbox__label {
            font-weight: $registration__newsletter-checkbox-checked-label-font-weight;
        }
    }
    &__newsletter-checkbox-icon {
        left: $spacer--small;
        top: $spacer--medium;
    }
    &__password-wrapper {
        margin: $registration__password-wrapper-margin;
    }

    &__password {
        margin: $registration__password-margin;
    }

    &__button {
        margin: $spacer--extra-large auto $spacer--large;
        padding: 0 $spacer--medium;
    }
    &__button-icon {
        margin-left: $spacer--medium;
    }


    .radio__handler {
        display: inline-block;
        .radio__label {
            font-size: 14px;
            font-weight: normal;
            font-family: Roboto, sans-serif;
        }
    }

    
    button.ui-datepicker-trigger {
        display: none !important;
    }

    .input__label_civility {
        display: inline-block;
    }

    p.small {
        font-size: 12px;
        color: #666;
        @include mq($screen-l ) {
            margin-left: -30px;
            margin-right: -55px;
            margin-top: 34px;
        }
        &.no-margin {
            margin: 0;
        }
        &.center {
            text-align:center;
        }
    }

    .checkbox__label {
        padding: 0;
        padding-left: 40px;
        font-weight: normal;
        font-size: 14px;
        font-family: Roboto, sans-serif;
        &:before {
            top: 2px !important;
            left: 8px;
        }
    }
    .checkbox__icon {
        top: 2px !important;
        left: 8px;
    }


    .separate-form {
        margin-left: -96px;
        margin-right: -96px;
        margin-top: 30px;
        margin-bottom: 30px;
        border: none;
        height: 1px;
        background: #bcc4c5;
        @include mq(0, $screen-m) {
            margin-left: -20px;
            margin-right: -20px;
        }
        @include mq($screen-m, $screen-l) {
            margin-left: -48px;
            margin-right: -48px;
        }
    }
    .row {
        &.margin-top {
            margin-top: 30px;
        }
    }

    .has_complement_checkbox {
        .has_complement_help {
            padding-left: 35px;
            font-size: $font-size-small;
            font-family: $font-family-sans-serif;
        }
        .checkbox__label {
            padding-left: 35px;
            span {
                font-size: $font-size-small;
                color: $color-primary;
                text-decoration: underline;
                font-family: $font-family-sans-serif;
            }
            &:before {
                content: "+";
                height: 22px;
                width: 22px;
                display: inline-block;
                color: $white;
                background: $color-primary;
                margin-right: $spacer;
                border-radius: 50% !important;
                text-align: center;
                line-height: 22px;
                font-size: 22px;
                font-weight: 700;
                font-family: $font-family-sans-serif-alt;
                overflow: hidden;
                border: none;
                -webkit-box-shadow: none hoff voff blur #000;
                -moz-box-shadow: none hoff voff blur #000;
                box-shadow: none hoff voff blur #000;
            }
        }
        .checkbox__field:checked + .checkbox__icon {
            opacity: 0;
        }
    }
    .manual-filling-btn {
        font-size: 14px;
        color: $darkblue;
        span {
            color: $color-primary;
            text-decoration: underline;
        }
    }
    .has_complement_show {
        margin-top: 20px;
    }
}
