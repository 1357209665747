.promo {

    @include mq($screen-m) {
        @include fix-gutter(42px);
    }

    > div {
        &.second {
            overflow: hidden;
            @include mq($screen-m) {
                overflow: initial;
            }
        }
    }

    &__item {
        position: relative;
        min-height: 100%;
        &--subscription {
            background: $pink;
            @include mq($screen-xml) {
                padding-right: 75px;
            }
        }
        &--service {
            background:  $blue;
            margin-top: 65px;
            @include mq($screen-m) {
                margin-top: 0;
            }
        }
    }

    &__title {
        color: #fff;
        line-height: 1.15;
        font-size: 24px;
        @include mq($screen-m) {
            font-size: 30px;
        }
    }
    
    &__inner {
        padding: 25px;
        @include mq($screen-l) {
            padding-left: 205px;
            min-height: 190px;
        }
    }

    &__link {
        font-family: $font-family-serif;
        font-size: $font-size-small;
        &:hover {
            text-decoration: underline;
        }
    }

    &__icon {

        margin: 0 auto;
        position: relative;


        &--subscription {
            width: 105px;
            top: -75px;
            margin-bottom: -80px;
            left: -10px;
            @include mq($screen-l) {
                left: auto;
            }
        }
        &--service {
            width: 115px;
            top: -60px;
            margin-bottom: -68px;
        }


        @include mq($screen-l) {
            width: auto;
            position: absolute;
            margin-bottom: 0;
            &--subscription {
                top: -72px;
                left: 0;
            }
            &--service {
                left: 20px;
                top: 10px;
            }
        }

        @include mq($screen-xxl) {
            &--subscription {
                left: 0;
            }
            &--service {
                left: 50px;
            }
        }
    }
}

