$dashboard-items-padding: $spacer--large !default;
$dashboard-items-margin : $spacer--large !default;

.dashboard-items {
    background: $white;
    box-shadow: $box-shadow__dashboard;
    &__title {
        margin-bottom: $dashboard-items-margin;
        padding-bottom: 10px;
        font-weight: 700;
        font-size: $font-size-large;
    }

    &__content {
        margin: 0;
    }

    &__address {
        margin-bottom: $dashboard-items-margin;
        padding-left: 68px;
        position: relative;


        @include mq($screen-xml) {
            padding-left: 68px;
        }
        
        p {
            overflow: hidden;
            text-overflow: ellipsis;
        }

        address {
            font-style: normal;
            color: $darkblue;
            line-height: 1.4;
            font-family: $font-family-sans-serif-alt;
            a {
                color: $darkblue;
            }
            strong {
                font-weight: 800;
            }
        }
    }

    &__address-icon {
        position: absolute;
        left: 0;
        fill: $darkblue;
        width: 48px;
        height: 38px;
    }

    &__item {
        padding: 0 $dashboard-items-padding;
        margin: $dashboard-items-margin 0;

        @include mq($screen-m) {
            padding: 0 20px;
        }

        @include mq($screen-xl) {

            .customer-account-index &:nth-of-type(2) {
                border-left: 1px solid $gray-lighter;
                border-right: 1px solid $gray-lighter;
            }
        }

        @include mq($screen-xml) {
            padding: 0 32px;
        }


        &.first {
            @include mq($screen-xl, $screen-xml) {
                max-width: 40%;
                flex-basis: 40%;
            }
        }
        &.last, &.second {
            @include mq($screen-xl, $screen-xml) {
                max-width: 30%;
                flex-basis: 30%;
            }
        }
    }
}
