$logo__max-width               : 94px !default;
$logo__max-width\@medium       : 229px !default;
$logo__image-height            : 25px !default;
$logo__image-height\@medium    : 96px !default;

.logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 $logo__max-width;
    justify-content: center;
    max-width: $logo__max-width;

    @include mq($screen-xl) {
        text-align: left;
        align-items: start;
        flex: 1 $logo__max-width\@medium;
        max-width: $logo__max-width\@medium;
        img {
            max-width: 199px !important;
        }
    }

    &__tagline {
        font-family: $font-family-sans-serif-alt;
        font-size: 13.4px;
        color: $darkblue;
        margin-top: 5px;
        strong {
            font-weight: 800;
        }

        display: none;
        @include mq($screen-xl) {
            display: block;
        }
    }
}
