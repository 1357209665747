$price__spacing             : $spacer !default;
$price__color--special      : $green !default;
$price__font-weight         : 900 !default;
$price__font-weight--old    : $font-weight-normal !default;
$price__font-weight--special: 900 !default;

.price {
    text-align: center;
    margin: 0 auto;
    &__time {
        display: block;
        color: $pink;
        font-family: $font-family-condensed;
        font-size: $font-size-small;
        margin-bottom: $spacer--small;
        font-weight: normal;
        line-height: 1;
    }
    &__value {
        color: $pink;
        padding: 0 8px;
        border: 1px solid;
        display: inline-block;
        font-weight: $price__font-weight;
        font-size: 22px;
        font-family: $font-family-sans-serif-alt;
        vertical-align: top;
        margin: 2px 0;
        line-height: 33px;
        height: 33px;
        & > ins {
            text-decoration: none;
        }

        .price-currency {
            font-weight: 400;
            font-size: 65%;
            top: -0.35em;
            position: relative;
            margin-right: 0.3em;
        }

        &--percentage {
            font-size: $font-size-medium;
            top: -2px;
            position: relative;
            top: auto;
            vertical-align: top;
            margin: 2px 0;
            background: $pink;
            color: white;
            border-color: $pink;
        }

        &--old {
            font-weight: $price__font-weight--old;
            margin-right: $price__spacing;
            height: auto;
            display: block;
            color: #455966;
            padding: 0;
            border: 0;
            margin-top: $spacer--small;
            font-size: 13px;
            font-family: $font-family-condensed;
            /* text-align: left; */
            height: auto;
            line-height: inherit;
            del {
                text-decoration: line-through;
                margin-right: $spacer--small;
                font-size: 14px;
            }

            .price-currency {
                top: 0;
                font-size: 100%;
            }
        }

        &--special {
            color: $price__color--special;
            font-weight: $price__font-weight--special;
        }

        &--invert {
            background: $pink;
            color: $white;
        }

        &--between {
            display: block;

            &:last-child {
                margin-top: $price__spacing;
            }
        }
    }

    &__text {
        font-weight: $price__font-weight--old;
    }
}
