.hide {
    display: none;
}
@include mq($screen-xs, $screen-s) {
    .hide-xs {
        display: none;
    }
}
@include mq($screen-s, $screen-m) {
    .hide-s {
        display: none;
    }
}
@include mq($screen-m, $screen-l) {
    .hide-m {
        display: none;
    }
}
@include mq($screen-l, $screen-xl) {
    .hide-l {
        display: none;
    }
}
@include mq($screen-xl, $screen-xxl) {
    .hide-xl {
        display: none;
    }
}
@include mq($screen-xxl) {
    .hide-xxl {
        display: none;
    }
}