.checkout-steps {

    padding: 0;
    margin: $spacer--medium 0 70px;

    &__item {
        font-family: $font-family-sans-serif-alt;
        color: #666;
        margin-right: $spacer--extra-large;
        margin-bottom: $spacer--small;
        display: flex;
        align-items: center;

        &:last-of-type {
            margin-bottom: 0;
        }

        @include mq($screen-xl) {
            display: inline-flex;
            margin-bottom: 0;
        }

        &.active {
            color: $darkblue;
            font-weight: 900;

            .checkout-steps__number {
                background: $color-primary;
                color: $white;
            }
        }

        &.complete {
            color: $darkblue;
            .checkout-steps__number {
              position: relative;
                &:before {
                    content: "";
                    color: $white;
                    background: $color-primary url(../images/check.svg) center no-repeat;
                    font-size: 24px;
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    background-size: 19px;
                    position: absolute;
                  position: absolute;
                  top: -1px;
                  left: -1px;
                }
            }
        }
    }
    &__number {
        min-width: 35px;
        height: 35px;
        border-radius: 50%;
        border: 1px solid $color-primary;
        color: $color-primary;
        font-family: $font-family-sans-serif-alt;
        font-weight: 900;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: $spacer--medium;
    }
}

body.site-leserservice {
    .checkout-steps {
        &__item {
            margin-right: $spacer--medium;
            &.complete {
                .checkout-steps__number {
                    &:before {
                    }
                }
            }
        }
        &__number {
            margin-right: $spacer;
        }
    }
}