.home-products {
    margin-bottom: 55px;
    text-align: center;
    @include mq($screen-l) {
        margin-bottom: 105px;
    }
    &__title {

        @include mq(0, $screen-s) {
            font-size: 30px;
        }

        color: $darkblue;
        text-align: center;
        margin: 0 auto $spacer--large;
        max-width: 700px;

        &:after {
            content: "";
            width: 75px;
            height: 3px;
            background: $color-primary;
            display: block;
            margin: 30px auto 0;
        }


    }

    &__filters {
        margin-bottom: 30px;
        @include mq($screen-s) {
            margin-bottom: 60px;
        }
    }

    &__filters-item {
        min-width: unset;
        &:last-of-type {
            .home-products__filters-link {
                margin-right: 0;
            }
        }
    }

    &__filters-link {
        text-transform: initial;
        font-weight: bold;
        font-family: $font-family-sans-serif-alt;
        transition: none;
        margin: 0 $spacer 0 0;
        padding: 0 $spacer--medium;
        font-size: 16px;
        height: 30px;
        line-height: 1;
        cursor: pointer;
        &.active {
            background: $color-primary;
            color: $white;
        }
    }

    &__link {
        margin-top: 28px;
        padding: 0 $spacer--large;
    }
    &__link-icon {
        width: 12px;
        height: 12px;
        margin-left: $spacer--medium;
    }
}

@include mq(0, $screen-s) {

    /*
    Nécessite un nouveau wrapper autour du ul.home-products__filters
    */
    .home-products__filters__wrapper {
        margin-left: -15px;
        margin-right: -15px;
        overflow: auto;
        padding-left: 30%;
    }


    .home-products__filters {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        width: auto;
        margin: 0;
    }

    .home-selection__filters-item {
        float: left;
        width: calc(62% - 12px);
        min-width: calc(62% - 12px);
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 6px 12px;
    }

    .home-products__filters-link {
        margin: 0;
        width: 100%;
    }
}