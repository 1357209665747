@font-face {
  font-family: 'edigroup';
  src: local('Edigroup'),
  url('../fonts/edigroup.woff2') format('woff2'),
  url('../fonts/edigroup.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^="edi-"], [class*=" edi-"] {
  font-family: 'edigroup' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.edi-culture:before {
  content: "\e900";
}
.edi-international:before {
  content: "\e901";
}
.edi-knowledge:before {
  content: "\e902";
}
.edi-leisure:before {
  content: "\e903";
}
.edi-paper:before {
  content: "\e904";
}
.edi-sport:before {
  content: "\e905";
}
.edi-swiss:before {
  content: "\e906";
}
.edi-wellness:before {
  content: "\e907";
}
.edi-women:before {
  content: "\e908";
}
.edi-youth:before {
  content: "\e909";
}
.edi-belgium:before {
  content: "\e90a";
}
.edi-action:before {
  content: "\e90b";
}
.edi-gift:before {
  content: "\e90c";
}
.edi-percent:before {
  content: "\e90d";
}
.edi-student:before {
  content: "\e90e";
}
.edi-austria:before {
  content: "\e90f";
}
