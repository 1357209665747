.contact__intro {
    text-align: center;
    margin-bottom: $spacer--extra-large;
    font-family: $font-family-sans-serif-alt;
    font-weight: 700;
    font-size: $font-size-large;
    color: $darkblue;
    line-height: $font-line-height;
}

.contact__form {
    padding: $spacer--extra-large 0;
    box-shadow: $box-shadow__form;
    display: flex;
    flex-direction: column;
    padding: 20px;
    @include mq($screen-l) {
        padding: $spacer--extra-large calc(#{$spacer--extra-large} * 2);
    }

}

.contact__form-row {
    @include fix-gutter(40px);
}

.contact__submit {
    margin: $spacer--extra-large auto $spacer--large;
    padding: 0 $spacer--large 0 $spacer--extra-large;
}

.contact__submit-icon {
    margin-left: $spacer--large;
}
