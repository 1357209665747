.history__title {
    background: url(../images/why/history.png) center no-repeat;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    min-height: 180px;
    background-size: 80px;
    @include mq($screen-m) {
        min-height: auto;
        background-size: auto;
    }
    @include mq($screen-m, $screen-l) {
        background-size: 70px;
    }
    @include mq($screen-xl, $screen-xxl) {
        background-size: 70px;
    }
}
.history__title-text {
    font-size: $font-size-medium;
    font-family: $font-family-serif;
    font-weight: $font-weight-normal;
    color: $darkblue;
    max-width: 160px;
    margin-bottom: 30px;
    @include mq($screen-m, $screen-l) {
        font-size: $font-size-base;
    }
    @include mq($screen-xl, $screen-xxl) {
        font-size: $font-size-base;
    }


    strong {
        white-space: nowrap;
        font-size: 36px;
        &.site-leserservice, &.site-mymagazines {
            font-size: 32px;
            @include mq($screen-m, $screen-l) {
                font-size: $font-size-medium;
            }
            @include mq($screen-xl, $screen-xxl) {
                font-size: $font-size-medium;
            }
        }
        font-family: $font-family-sans-serif-alt;
        font-weight: 900;
        color: $color-primary;
        text-transform: uppercase;
        @include mq($screen-m, $screen-l) {
            font-size: $font-size-medium;
        }
        @include mq($screen-xl, $screen-xxl) {
            font-size: $font-size-medium;
        }
    }
    span.text-suffix {
        margin-top: 5px;
        display: block;
    }


}

.history__list {
    border-left: 1px solid $gray-lighter;
    padding: 25px 0;
    list-style: none;
    margin-left: 20px;
    @include mq($screen-s) {
        margin: 0 auto;
        max-width: 350px;
    }
    @include mq($screen-m) {
        margin: 0;
    }
}

.history__list-item {
    position: relative;
    padding-left: 45px;
    margin-bottom: 35px;
    font-family: $font-family-sans-serif-alt;
    font-size: $font-size-large;
    color: $darkblue;


    &:last-child {
        margin-bottom: 0;
    }

    &:before {
        content: "";
        width: 18px;
        height: 18px;
        display: inline-block;
        background: $color_primary;
        border: 4px solid #fff;
        outline: 1px solid $color-primary;
        position: absolute;
        top: 50%;
        left: -9px;
        transform: translateY(-50%) rotate(45deg);
    }
}

.happiness {
    margin-top: 40px;
    @include mq($screen-s) {
        max-width: 390px;
        margin: 40px auto 0;
    }
    @include mq($screen-m) {
        margin: 0;
        max-width: initial;
        background: url(../images/why/hapiness.png) center left no-repeat;
        padding-left: 150px;
    }
    @include mq($screen-m, $screen-l) {
        padding-left: 80px;
        background-size: 70px;
    }
    @include mq($screen-xl, $screen-xxl) {
        padding-left: 80px;
        background-size: 70px;
    }

}
.happiness__title {
    color: $pink;
    font-family: $font-family-sans-serif-alt;
    font-size: 24px;
    text-align: center;
    @include mq($screen-s) {
        text-align: left;
    }
}
.happiness__list {
    margin: 30px 0 0;
    list-style: none;
    padding: 0;
}
.happiness__list-item {
    color: $darkblue;
    font-size: $font-size-medium;
    font-family: $font-family-sans-serif-alt;
    font-weight: 700;
    margin-bottom: 15px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 250px;
    &:last-child {
        margin-bottom: 0;
    }
}
.happiness__list-percent {
    background: $color-primary;
    color: #fff;
    min-width: 57px;
    height: 57px;
    line-height: 57px;
    text-align: center;
    display: inline-block;
    border-radius: 50%;
    font-weight: 900;
    font-size: 25px;
    margin-right: 20px;
    sup {
        font-size: $font-size-small;
    }
}

