body.blog-page {
  /* Animation Zoom Templates*/
  [class^=template-] .image-container .animation-type-zoom {-webkit-transition: transform 0.3s ease, opacity 0.3s;transition: transform 0.3s ease, opacity 0.3s;}
  [class^=template-] .image-container:hover .animation-type-zoom {transform: scale3d(1.1,1.1,1); -webkit-transform: scale3d(1.1,1.1,1);}


  /* All Widget Templates ***********************************************************************************************/
  [class^=template-] {position: relative;overflow: hidden;}
  [class^=template-] * {-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;}
  [class^=template-] *:before,
  [class^=template-] *:after {-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;}
  [class^=template-] a {text-decoration: none;font-family: sans-serif;color: #222222;}
  [class^=template-] a:hover {color: $color-primary;}
  /* Block Title */

  [class^=template-] .block-title-wrap {border-bottom: 2px solid #222222;margin-bottom: 26px;}
  [class^=template-] .block-title-wrap h4 {display: inline-block;background: #222222;color: #fff;padding: 7px 12px 4px;font-weight: 500;}
  /* Images Container */
  [class^=template-] .block-content .image-container {position: relative;}
  /* Images */
  [class^=template-] .image-wrap {display: block;line-height: 0;overflow: hidden;}
  [class^=template-] .image-wrap img {
    display: block;width: 100%;height: 100%;position: absolute;
    background-size: cover;background-position: center center;
    -webkit-transition: transform 0.3s ease, opacity 0.3s;transition: transform 0.3s ease, opacity 0.3s;
  }
  /* Span Images */
  [class^=template-] .block-content .image-wrap {display: block;position: relative;padding-bottom: 67%;overflow: hidden;}
  [class^=template-] .block-content .image-wrap .bg-img {position: absolute;display: block;width: 100%;height: 100%;background-size: cover;background-position: center;}
  /* Post Category  */
  [class^=template-] .image-container .post-category {position: absolute;left: 0;bottom: 0;display: block;font-size: 0;line-height: 1;}
  [class^=template-] .image-container .post-category a {display: inline-block;margin: 0 5px 0 0;padding: 5px 10px;background: #000;color: #fff;font-size: 11px;line-height: 12px;font-weight: 500;text-decoration: none;text-transform: uppercase;transition: all 0.3s ease;}
  [class^=template-] .image-container .post-category a:hover {background: #4db2ec;}
  /* Post Title */
  [class^=template-] .post-info h3.post-title a {font-size: 20px;line-height: 25px;font-weight: 900 !important;color: #222222;}
  [class^=template-] .post-info h3.post-title a:hover {color: $color-primary;}
  /* Post Date */
  [class^=template-] .post-data-wrap {font-size: 11px;}
  [class^=template-] .post-data .block-post-author-name a {color: #000;line-height: 18px;}
  [class^=template-] .post-data {font-size: 11px;color: #bbbbbb;}
  [class^=template-] .post-data span {color: #bbbbbb;}
  /* Post Author */
  [class^=template-] .post-author {font-weight: 600;font-size: 11px;}
  /* Post View */
  [class^=template-] .post-view {display: block;background: #000;color: #fff;font-size: 10px;font-weight: 600;text-align: center;line-height: 11px;position: relative;top: -5px;padding: 4px 6px;}
  [class^=template-] .post-view:after {position: absolute;bottom: -3px;left: 0;content: '';width: 0;height: 0;border-style: solid;border-width: 3px 3px 0 0;border-color: #000 transparent transparent;}
  /* Post Description */
  [class^=template-] .post-description, [class^=template-] .post-description p {color: #999;line-height: 21px;font-size: 15px;}
  /* Post List */
  [class^=template-] .post-list .post-item {margin-bottom: 1.5rem;display: -ms-flexbox;display: flex;}
  [class^=template-] .post-list .image-container {-ms-flex: 0 0 30%;flex: 0 0 30%;max-width: 30%;width: 30%;order: 0;position: relative;}
  [class^=template-] .post-list .post-info {padding: 0 0 0 25px;width: 100%;}
  [class^=template-] .block-content .post-list .post-info h3 a {font-size: 14px;line-height: 20px;}
  /* Images Format */
  [class^=template-] .image-wrap._16x9 {padding-bottom: 35% !important;}
  [class^=template-] .image-wrap._16x9 {padding-bottom: 35% !important;}
  [class^=template-] .image-wrap._4x3 {padding-bottom: 67% !important;}
  [class^=template-] .image-wrap._3x2 {padding-bottom: 55% !important;}
  [class^=template-] .image-wrap._1x1 {padding-bottom: 100% !important;}
  @media (min-width: 768px) {
    [class^=template-] .post-list .post-item {margin-bottom: 2.5rem !important;}
  }
  @media (max-width: 767px) {
    [class^=template-] .post-list .post-item {margin-bottom: 2.5rem !important;}
    [class^=template-] .post-list .post-info {padding: 0 0 0 15px !important;}
    [class^=template-] .post-list .post-data-wrap {margin: 0 !important;}
  }
  /* Widget All Template 2 **********************************************************************************************/
  [class^=template-2] .block-post-grid {position: relative;overflow: hidden;margin-left: 0;margin-right: 0;}
  [class^=template-2] .block-content {}
  [class^=template-2] .block-post-grid .block-post .image-container {position: relative;}
  [class^=template-2] .post-info {padding: 8px;}
  @media (max-width: 767px) {
    [class^=template-2] .block-content {margin: 0 0;}
  }
  .template-2-1 .block-post {width: 33.3%;float: left;padding: 0 15px 15px;margin-bottom: 15px;}
  .template-2-1 .block-post:nth-child(3n+1) {clear: both;}
  @media (max-width: 1024px) {
    .template-2-1 .post-view {display: none !important;}
  }

  @media (max-width: 767px) {
    .template-2-1 .block-post-grid {margin-left: -7.5px;margin-right: -7.5px;}
    .template-2-1 .block-post {width: 100%;float: left;padding: 0 7.5px 7.5px;margin-bottom: 25px;}
  }

  .column.main{
    padding: 0 1em;
  }

  .breadcrumbs{
    margin-bottom: 0;
  }
  .sidebar .widget.block:not(:last-child), .sidebar .widget:not(:last-child){
    margin-bottom: 40px;
  }
  .sidebar .widget .block-title{
    padding-bottom: 0.2em;
    color: $color-primary;
    border-bottom: 1px solid #999;
  }
  .sidebar .widget .block-title strong{
    font-weight: normal;
  }
  .widget.block-categories ul li a{
    font-weight: normal;

  }
  .sidebar .widget *{
    font-size: 14px;
  }
  .sidebar .widget .post-item-date{
    font-size: 12px;
  }
  @media (min-width: 768px){
    .sidebar {
      margin-top: 8em;
    }
  }
  .widget.blog-search .action.search{
    width: auto;
  }
  .widget.blog-search input {
    height: 32px;
  }
  #blog_search_mini_form > * {
    float: left
  }
  #blog_search_mini_form .action.search {
    position: relative;
  }
  .widget.block-archive .archive-item-link {
    font-weight: normal;
  }

  .column.main {
    padding: 0 1em;
  }
  .post-nextprev.row-cols-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .float-right {
    float: right !important;
  }


  .post-bottom .block.related .block-title {
    line-height: initial;
  }
  .mfblog-replated-posts-block.block.related .post-list .image-container .image-wrap .bg-img {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }
  .mfblog-replated-posts-block.block.related .post-list .post-item {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 2rem !important;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
    padding-left: 10px;
    padding-right: 10px;
    width: 25%;
    box-sizing: border-box;
  }
  .d-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .post-description p {
    color: #777;
    font-size: 14px;
    line-height: 1.5;
  }
  .mfblog-replated-products-block .product .product-item-name {
    line-height: 1.5;
    padding: 0.4em 0;
    display: block;
  }
  .mfblog-replated-products-block .product .product-item-name a{

    text-align: center;
    line-height: 1.1;
    display: block;
    color: $color-primary;
    font-weight: 600;
    max-width: 80%;
    margin: 0 auto;
  }
  .post-data-wrap {
    font-size: 13px;
    font-family: sans-serif;
    margin-bottom: 30px;
  }
  .mb-4, .my-4 {
    margin-bottom: 1.5rem !important;
  }
  .breadcrumbs {
    margin-bottom: 0;
  }
  .post-category a, .post-tag-title {
    background-color:$color-primary !important;
  }
  .toolbar {
    position: relative;
    .pager {
      display: block;
      button {
        width: auto;
      }
    }
  }
  .post-title a  {
    font-family: $font-family-sans-serif-alt !important;
    font-weight: 600 !important;

  }

  .post-list-wrapper .post-container {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }


}