$offer__col-base-spacing: $spacer--extra-large;
$offer__col-base-reduce-width: $offer__col-base-spacing / 2;
$offer__col-left-base-width: 34%;
$offer__col-left-width: calc(#{$offer__col-left-base-width} - #{$offer__col-base-reduce-width});
$offer__col-right-base-width: 100% - #{$offer__col-left-base-width};
$offer__col-right-width: calc(#{$offer__col-right-base-width});

.offer {
  border: 1px solid $gray-lighter;
  box-shadow: $box-shadow__offer;
  border-radius: 0;
  padding: $spacer--medium $spacer--large 0 $spacer--medium;
  margin-bottom: $spacer--medium;
  width: 100%;
  background: white;
  z-index: 10;
  &:last-child {
    margin-bottom: 0;
  }

  &:hover,
  &:focus,
  &.selected {
    background: $white;
  }

  @include clearfix;

  &__header {
    color: $white;
    background: $color-primary;
    margin: -17px -33px 20px -17px;
    text-align: center;
    padding: 12px 0;
    font-size: $font-size-medium;
    font-family: $font-family-sans-serif-alt;
    font-weight: bold;

    &--student {
      background: $color-secondary;
    }

    span[class*="edi-"] {
      margin-right: $spacer--medium;
      font-size: 28px;
      line-height: 0;
      position: relative;
      top: 6px;
    }
    strong {
      font-weight: 900;
      font-size: $font-size-large;
      text-transform: uppercase;
      margin-right: $spacer;
    }
    a.edi-student-link-cond {
      position: relative;
      top: -0.05em;
      display: inline-block;
      font-family: $font-family-sans-serif !important;
      font-size: 0.9em;
      color: #fff;
      line-height: 1.1;
      text-transform: uppercase;
    }
  }

  &__image-wrapper {
        display: flex;
        justify-content: center;
        margin-bottom: $spacer--medium;
        text-align: center;
        height: 190px;

        a {
            display: inline-block;
        }
  }

  &__image {
    width: auto;
    max-width: 100%;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 10px;
    max-height: 100%;


    &.offer__image_secondary {
      @include mq(0, $screen-s) {
        height: 50px;
        margin-left: -8px;
      }
    }

  }
  .is-100-percent-digital{
    background: url(../images/offers/only-numerique.png) center no-repeat;
    width: 142px;
    height: 184px;
    margin-bottom: 15px;
    background-size: contain;
    img{
      margin-top: 35px;
      margin-left: 0px;
    }
  }
  @media only screen and (max-width : 991.5px) {
    .is-100-percent-digital{
      img{
        margin-left: 3px;
      }
    }
  }
  .numeric-edition-included {
    display: inline-block;
    margin-left: 15px;
    @include mq(0, $screen-s) {
      margin-left: -3px;
    }
    svg {
      fill: $color-primary;
      width: 100%;
      width: 62px;
      height: 37px;
      position: relative;
      top: 4px;
      @include mq(0, $screen-s) {
        width: 42px;
        height: 26px;
      }
    }
  }

  .more {
    color: $color-primary;
    font-size: 24px;
    width: 22px;
    height: 22px;
    box-shadow: 0 2px 8px rgba(24, 52, 70, 0.3);
    display: inline-block;
    border-radius: 50%;
    line-height: 22px;
    text-align: center;
    font-weight: 400;
    top: 3px;
    position: relative;
    z-index: 10;
    background: white;
    margin-left: -12px;
    margin-right: -20px;
    @include mq(0, $screen-s) {
      margin-left: -20px;
      margin-right: 0;
    }
  }

  &__infos {
    width: 100%;
    @include mq($screen-l) {
      width: $offer__col-right-width;
      float: right;
    }
    .price {
      text-align: left;
    }
    .offer__shipping {
      text-align: left;
      justify-content: left;
    }

      .row {
          flex-direction: column !important;
      }

  }

  &__title {
    font-size: 16px;
    margin-bottom: $spacer--medium;
      height: 2.5em;
    @include mq($screen-m) {
      font-size: $font-size-medium;;
    }

  }

    .col-lg-offer__sku {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        position: relative;

        .product-modal-content {
            grid-column: 1 / 3;
            grid-row: 2;
        }
    }
  &__sku {
      grid-column: 1 / 2;
      grid-row: 1;
    font-weight: 300;
    font-size: $font-size-small;
    margin-bottom: $spacer;
    color: $darkblue;
  }

  &__link {
      grid-column: 2 / 2;
      grid-row: 1;
    font-size: $font-size-small;
    color: $color-primary;
    text-decoration: underline;
    font-family: $font-family-sans-serif;
    margin-bottom: $spacer--medium;
    @include mq($screen-l) {
      margin-bottom: 0;
    }
    margin-top: 0.5em;


  }

  &__checkbox {
    max-width: 165px;
    margin: 0 auto;
  }
  &__checkbox-label {
    font-size: $font-size-small;
    font-weight: normal;
    font-family: $font-family-sans-serif;
    padding-right: 0;
  }
  &__shipping {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $font-family-condensed;
    font-size: $font-size-small;
    color: $darkblue;
    text-align: center;
    font-weight: normal;
    margin-bottom: $spacer--medium;
    @include mq($screen-l) {
      margin-bottom: 0;
    }
  }
  //.price__value--old {
  //    text-align: left;
  //    @include mq($screen-l) {
  //        text-align: center;
  //    }
  //}

  &__shipping-icon {
    display: inline-block;
    height: 36px;
    width: 36px;
    margin-right: $spacer--small;
  }

  &-student__icon {
    display: none;
    fill: $color-secondary;
    width: 40px;
    height: 40px;
    margin-left: $spacer;

    @include mq($screen-l) {
      display: block;
    }
  }
}

// POPIN CONDITIONS STUDENT OFFER
.edi-student-modal {
    font-family: $font-family-sans-serif !important;
    line-height: 1.5;
}

// SWATCH CONFIGURABLE PRODUCT OFFERS
.product-view__options .offer_number {

  margin-top: $spacer--large;

    padding: 2em 0;
    width: 100%;
    //height: 530px;

    .swatch__wrapper {
        display: flex;
        height: 100%;
        transition: transform 0.2s ease-in-out;
        flex-wrap: nowrap;
        align-items: center;
        width: auto;
        min-width: 100%;

        .swatch__option-container {
            // width: 380px;
            min-width: calc(min(380px,100vw) - 4em);

            min-height: 520px;
            flex-shrink: 0;
            padding: 0;
            margin: 0 0 0 0;

            &.not-applicable {
                opacity: 0.15;
            }

            .offer__image-wrapper {
                width: 100%;
                float:none;
                margin-bottom: 0;
                text-align: center;
                padding: 2em;
                align-items: center;
                justify-content: center;

            }
            position: relative;
            .offer__infos {
                width: 100%;
                padding: 2em;
            }
            .offer__header {
               margin: 0;
                width: 100%;
                padding: 10px 1em;
                line-height: 18px;
                height: calc(54px + 20px);
                display:flex;
                align-items: center;
                justify-content: center;
                &-empty {
                    background: none !important;
                }
            }
        }
    }
    .nav button {
        position: absolute;
        top: 50%;
        transform: translatey(-50%);
        width: 60px;
        height: 60px;
        border-radius: 50%;
        outline: none;
        border: 1px solid #000;
        cursor: pointer;
    }
    .nav .prev {
        left: -30px;
        display: none;
    }
    .nav .prev.show {
        display: block;
    }
    .nav .next {
        right: -30px;
    }
    .nav .next.hide {
        display: none;
    }

  @include mq($screen-l) {
    margin-top: $spacer--medium;
  }

  .swatch__option-container {
    @extend .offer;
    margin-right: 0;
    margin-bottom: $spacer--medium;
    cursor: auto;
  }

  .swatch__option {
    display: block;
    .row {
      .col-lg {
        margin-bottom: 1em;
      }
      @include mq($screen-l) {
        .col-lg {
          margin-bottom: 1.5em;
        }
      }
    }
  }

  .swatch__title {
    display: none;
  }

    .swiper-pagination-bullet {
        background-color: $slider__dot-inside-background !important;
        width: 10px;
        height: 10px;
        border-radius: 8px;
        opacity: 1 !important;

        &-active {
            background-color: $slider__dot-inside-background--active !important;
        }
    }

    .swiper-button-disabled {
        display: none;
    }

    .swiper-button-next , .swiper-button-prev {
        font-weight: bold;
        color: #aaa;

    }

    /*
    .swiper-button-next {
        &:after {
            content: "";
            background: url(../images/checkbox.png) center no-repeat;
            width: 27px;
            height: 30px;
            background-size: 50px;
            background-position-x: 0;
        }
    }*/
}

.page-product-configurable {
  .product-view__main-actions {
    display: none;
  }

  @include mq($screen-l) {
    .product-view__wrapper {
      position: relative;
    }
    .product-view__gallery {
      max-width: calc(33% - 24px);
      max-height: 400px;
      position: absolute;
      overflow: hidden;
    }
    .product-view__secondary-details {
      width: 100%;
    }
    .product-view__secondary-details > div,
    .product-view__secondary-details .swatch {
      width: calc(100% - 33% - 24px);
      float: right;
    }
    .product-view__secondary-details .swatch.offer_number {
        width: 100%;
        overflow: hidden;
        position: relative;
        min-height: 590px;

    }
  }
  .student_offer {
    position: relative;
    background: rgb(229, 244, 247);
    padding: $spacer--medium;
    display: block;

    margin-left: -$spacer--medium;
    margin-right: -$spacer--medium;
    margin-top: $spacer--large;
    @include mq($screen-l) {
      margin: $spacer--medium auto 0;
      min-height: 72px;
    }

    &:after {
      width: 0;
      height: 0;
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
      border-top: 15px solid rgb(229, 244, 247);
      content: '';
      position: absolute;
      bottom: -15px;
      left: calc(50% - 16px);
    }
    .swatch__title {
      font-weight: 900;
      font-size: $font-size-large;
      color: $darkblue;
      max-width: 220px;
      margin: 0 auto $spacer--medium;

      @include mq($screen-l) {
        float: left;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin: 0;
        max-width: 300px;
      }
      @include mq($screen-xl) {
        max-width: 220px;
      }
      @include mq($screen-xxl) {
        max-width: unset;
      }
    }
    .swatch__option-container {
      background: transparent !important; //sass-lint:disable-line no-important
      color: #000 !important; //sass-lint:disable-line no-important
      font-weight: 500;
      border: none;
      outline: none;
      &:hover {
        background: transparent !important; //sass-lint:disable-line no-important
        color: #000 !important; //sass-lint:disable-line no-important

      }
    }
    > div {
      display: block;
    }
    .swatch__wrapper {
      position: relative;
      width: auto;
      .swatch__option-container {

        .swatch__option {
          color: $darkblue;
          font-family: $font-family-sans-serif;
          float: none;

          @include mq($screen-l) {
            float: right;
            max-width: 230px;
          }

          &:before {
            background: url(../images/checkbox.png) center no-repeat;
            width: 27px;
            height: 30px;
            background-size: 50px;
            background-position-x: 0;
            content: '';
            margin-right: $spacer;
            @include mq($screen-l) {
              width: 40px;
            }
          }
        }
        &.selected .swatch__option {
          &:before {
            background-position-x: -24px;
          }
        }
      }
    }
  }
}

.offer-image-product-modal {
  .modal-content {
    text-align:center;
    img {
      width: auto;
      height: auto;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.offer__checkbox-label-explanation {
    font-size: $font-size-small;
    font-weight: normal;
    font-family: $font-family-sans-serif;
    padding-right: 0;
    color:#183446;

}
