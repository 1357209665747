.cms-page-view .page-content {

    @include mq($screen-xml) {
        margin: 54px 50px;
    }

    h2 {
        margin-bottom: 36px;
    }
    h3 {
        margin-bottom: 32px;
    }
    h4 {
        font-size: 24px;
        margin-bottom: 22px;
    }
    h5 {
        font-size: 18px;
        margin-bottom: 20px;
    }
    h6 {
        font-size: 16px;
        margin-bottom: 18px;
    }

    p {
        font-size: $font-size-small;
        color: $gray;
        margin-bottom: 0;
    }

    .lead {
        font-size: $font-size-large;
        font-family: $font-family-sans-serif-alt;
        font-weight: bold;
        line-height: 1.3;
        color: $darkblue;
    }

    ul {
        font-family: $font-family-sans-serif-alt;
        color: $darkblue;
        font-size: $font-size-medium;
        list-style: none;

        > li {
            margin-bottom: $spacer;
            &:last-of-type {
                margin-bottom: 0;
            }
            &:before {
                content: "\2022";
                color: $color-primary;
                font-weight: bold;
                display: inline-block;
                width: 1em;
                margin-left: -1em;
            }

            &.dropdown-list__item--collapse {
                margin-bottom: 0;
                &:before {
                    display: none;
                }
            }
        }
    }
    .section {
        margin-bottom: 60px;
    }

    .row {
        @include fix-gutter(42px);
    }
}

/* Site Map */
body.sitemap-index-index, body.mageworx_htmlsitemap-index-index {
    .col-bss-1 {
        width: 100%;
        margin: 0 0 20px 0;
        float: left;

    }
    .col-bss-2, .xsitemap > div {
        width: 100%;
        h2, h3 {
            &.bss_h2 {
                border-bottom: 1px solid $gray-lighter;
                padding-bottom: 5px;
            }
            &.bss_product_list {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                font-size: 14px;
                margin: 5px 0 5px 0;
                padding: 5px 5px 3px 0;
                text-transform: capitalize;
            }
        }


        .bss-category-list, .bss-cmspage-list2 {
            clear:both;
            padding-top: 16px;
            h3 {
                > a {
                    display: block;
                    margin: $mega-menu__link-margin;
                    padding: $mega-menu__link-padding;
                    font-size: $mega-menu__font-size--large;
                    font-weight: $mega-menu__font-weight;
                    font-family: $font-family-sans-serif-alt;
                    color: $mega-menu__link-color;
                    text-decoration: $mega-menu__link-text-decoration;
                }

            }
            ul {
                list-style-type: disc;
            }
        }
    }
}
