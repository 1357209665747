
.category-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background: rgba($blue, 0.9);
    position: relative;
    min-height: 216px;
    overflow: hidden;

    &__content {
        width: 100%;
        max-width: 720px;
        margin: 0 auto;
        padding: 20px;

        @include mq ($screen-xl) {
            margin: 0 30px 0 60px;
        }
        @include mq ($screen-xxl) {
            margin: 0 60px 0 120px;
        }

        #showMoreButton {
            color: #fff;
            font-weight: bold;
            display: inline-block;
            padding-top: 0.5em;
        }
    }

    &__title {
        color: #fff;
        text-transform: none;
        margin: 0;
        text-shadow: $text-shadow__title;
    }
    &__subtitle {
        color: #fff;
        text-transform: uppercase;
        font-family: $font-family-sans-serif-alt;
        font-size: $font-size-medium;
        text-shadow: $text-shadow__title;
    }

    &__description {
        margin-top: 30px;
        display: none;

        @include mq($screen-l) {
            display: block;
        }

        &,
        p {
            color: #fff;
            font-size: $font-size-small;
            line-height: $font-line-height;
            margin-bottom: 0;
        }

        #showMoreButton {
            color: #fff;
            font-weight: bold;
            display: inline-block;
            padding-top: 0.5em;
        }
    }

    &__image-wrapper {
        position: absolute;
        z-index: -1;
        width: 100%;
        top: 0;
        left: 0;

        img {
            background-size: cover;
        }
    }

    &__link {
        color: lighten($color-primary, 15%);
        text-decoration: underline;
        &:hover,
        &:focus,
        &:active {
            color: #fff;
        }
    }

    &__longdesc {
        display: inline;

        &.hidden {
            display: none;
        }
    }
}
