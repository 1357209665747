.filter-content {
    padding: $spacer--large;
    .checkbox__label {
        font-size: $font-size-small;
        padding: $spacer--small 26px;

        &:before {
            top: 5px;
        }
    }

    .checkbox__icon {
        top: 5px;
    }
}
