$dashboard-nav-padding: 0 !default;
$dashboard-nav-margin : 0 !default;

.dashboard-nav {
    margin-bottom: $dashboard-nav-margin;

    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 0;

    &__mobile {
        display: block;
        position: relative;
        padding: $spacer 0;
        cursor: pointer;
        background: $white;
        border-bottom: 1px solid $gray-lighter;

        @include mq($screen-m) {
            display: none;
        }

    }

    &__content {
        display: none;
        background-color: $white;
        padding: $dashboard-nav-padding;
        margin-bottom: $dashboard-nav-margin;
        position: absolute;
        top: 36px;
        width: 100%;
        box-shadow: $box-shadow__default;

        &--visible {
            display: block;
        }

        @include mq($screen-m) {
            display: block;
            position: static;
            width: auto;
            background-color: rgba($color-primary, 0.7);
            box-shadow: none;
        }
    }

    &__list {
        margin: 0;
    }

    &__item {
        padding: 0;
        display: block;

        @include mq($screen-m) {
            display: inline-block;
        }

        &:hover {
            background-color: $white;
        }
    }

    &__title {
        margin-bottom: $dashboard-nav-margin;
        padding-bottom: 10px;
        border-bottom: 1px solid $gray;
    }

    &__item > a,
    &__link {
        display: block;
        padding: $spacer--small $spacer;
        font-family: $font-family-sans-serif-alt;
        transition: none;
        color: $blue;
        background: $white;
        position: relative;
        z-index: 10;
        border-bottom: 1px solid $gray-lightest;

        &:hover {
            color: $darkblue;
        }
        @include mq($screen-m) {
            display: inline-block;
            padding: 13px 20px;
            font-size: 12px;
            background: transparent;
            border: 0;
            color: $white;
        }

        @include mq($screen-l) {
            padding: 13px 24px;
            font-size: 15px;
        }
    }

    // This class is changing by Magento
    .current {
        padding: $spacer--small $spacer;
        margin-left: -$dashboard-nav-margin;
        color: $darkblue;
        background: $white;
        font-family: $font-family-sans-serif-alt;
        position: relative;
        z-index: 10;
        border-bottom: 1px solid $gray-lightest;


        strong {
            font-weight: normal;
        }

        & > a {
            padding: 0;
            line-height: 1;
        }

        @include mq($screen-m) {
            border: 0;
            padding: 16px 24px;
        }
        @include mq($screen-l) {
            padding: 16px 42px;
        }
    }

    // This clases to disabled links module
    .nav.item {
        @extend .list__item;
        @extend .dashboard-nav__item;
    }

    .nav.item.current {
        @extend .current;
    }

    .nav.item.current {
        & > a {
            color: $darkblue;
        }
    }

    .nav.item > a {
        @extend .link--invert;
    }
}
