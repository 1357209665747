// AMASTY SOCIAL LOGIN + POPUP
.am-login-popup.-social-right .am-login-content .am-form,
.am-login-popup.-social-left .am-login-content .am-form,
.am-login-popup .am-form {
    margin-bottom: 0 !important;
    @include mq(0, $screen-s) {
        margin-left: $spacer--small;
        margin-right: $spacer--small;
    }
    @include mq($screen-l) {
        width: 800px;
        max-width: initial !important;
    }

    .login__title {
        display: none;
    }
    .login__form {
        margin: 0;
    }
    .login__form-column,
    .login__social {
        box-shadow: none;
    }
    .login__form-column {
        @include mq(0, $screen-s) {
            padding: $spacer--extra-large 0;
        }
    }
    .registration__content {
        box-shadow: none;
        @include mq(0, $screen-s) {
            padding: $spacer--extra-large $spacer--small;
        }
    }

    > .col-md-6 {
        max-width: 100%;
    }

    .checkbox {
        height: auto;
    }
}

.am-login-popup .am-register-content {
    @include mq($screen-l) {
        width: unset !important;
    }
}

/** XSEARCH */
.amasty-xsearch-loader {
    background-image: url('../Amasty_Xsearch/images/loader.gif')!important;
}