$side-menu__trigger-wrapper-border      : 1px solid $gray-light !default;

$side-menu__trigger-size                : 48px !default;
$side-menu__trigger-margin              : 0 !default;
$side-menu__trigger-padding             : 0 !default;
$side-menu__trigger-border              : none !default;
$side-menu__trigger-background-color    : $white !default;

$side-menu__trigger-text-font-size      : 10px !default;
$side-menu__trigger-text-line-height    : 10px !default;
$side-menu__trigger-text-alignment      : center !default;
$side-menu__trigger-text-color          : $gray !default;
$side-menu__trigger-icon-size           : 18px !default;
$side-menu__trigger-icon-margin         : 0 0 2px 0 !default;
$side-menu__trigger-icon-margin\@medium : 0 0 4px 0 !default;
$side-menu__trigger-icon-padding        : 0 0 4px 0 !default;
$side-menu__trigger-icon-color          : $gray-darker !default;
$side-menu__trigger-icon-color--hover   : $color-primary !default;

$side-menu__content-top                 : 50px !default;
$side-menu__content-bottom              : 0 !default;
$side-menu__content-right               : calc(100% + 2px) !default;
$side-menu__content-height              : 100% !default;
$side-menu__content-max-width           : 100% !default;
$side-menu__content-max-width\@medium   : 400px !default;
$side-menu__content-max-height          : 100vh !default;
$side-menu__content-transform           : translateX(0) !default;
$side-menu__content-transform--open     : translateX($side-menu__content-right) !default;
$side-menu__content-padding             : 0 0 60px !default;
$side-menu__content-background-color    : $white !default;
$side-menu__content-transition          : $transition-base !default;
$side-menu__content-box-shadow          : 0 !default;

$side-menu__dropdown-width              : 100% !default;
$side-menu__dropdown-margin             : 0 !default;

$side-menu__overlay-background-color    : rgba(0, 0, 0, 0.6) !default;

.side-menu {
    @include mq($screen-xl) {
        display: none;
    }

    &--is-open {
        .side-menu__content {
            opacity: 1;
            transform: $side-menu__content-transform--open;
        }

        .side-menu__overlay {
            width: 100%;
            opacity: 1;
        }
    }

    &__trigger-wrapper {
        display: flex;
        justify-content: flex-end;
        border-bottom: $side-menu__trigger-wrapper-border;
    }

    &__trigger {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: $side-menu__trigger-size;
        min-width: $side-menu__trigger-size;
        margin: $side-menu__trigger-margin;
        padding: $side-menu__trigger-padding;
        border: $side-menu__trigger-border;
        background-color: $side-menu__trigger-background-color;
        cursor: pointer;

        &:focus,
        &:hover {
            .side-menu__trigger-icon {
                fill: $side-menu__trigger-icon-color--hover;
            }
        }
    }



    &__content {
        position: fixed;
        top: $side-menu__content-top;
        bottom: $side-menu__content-bottom;
        right: $side-menu__content-right;
        width: $side-menu__content-height;
        height: $side-menu__content-height;
        max-width: $side-menu__content-max-width;
        max-height: $side-menu__content-max-height;
        padding: $side-menu__content-padding;
        z-index: 100;
        overflow-y: auto;
        background: $side-menu__content-background-color;
        box-shadow: $side-menu__content-box-shadow;
        opacity: 1;
        transform: $side-menu__content-transform;
        transition: $side-menu__content-transition;
        border-top: 1px solid $gray-lighter;
        -webkit-overflow-scrolling: touch;
        @include mq($screen-l) {
            max-width: $side-menu__content-max-width\@medium;
        }
    }

    &__overlay {
        position: fixed;
        top: $side-menu__content-top;
        bottom: 0;
        left: 0;
        z-index: 5;
        background-color: $side-menu__overlay-background-color;
        transition-duration: 0.3s;
        transition-property: opacity;
        transition-timing-function: ease-in-out;
        opacity: 0;

        @include mq($screen-xl) {
            display: none;
        }
    }

    &__header {
        box-shadow: $box-shadow__side-menu;
        position: relative;
        z-index: 1;
        display: flex;
    }
    &__button {
        flex: 0 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $darkblue;
        padding: $spacer--medium 0;

        & + & {
            border-left: 1px solid $gray-lighter;
        }
        &:hover,
        &:focus {
            color: $darkblue;
            background: $gray-lightest;
        }
    }
    &__button-counter {
        position: absolute;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        line-height: 18px;
        font-size: 11px;
        font-weight: bold;
        text-align: center;
        color: $white;
        background-color: $pink;
        overflow: hidden;
        margin-left: -24px;
        margin-top: -$spacer--small;
        &--hidden {
            display: none;
        }
    }
    &__button-icon {
        fill: $darkblue;
        order: -1;
        margin-right: $spacer--medium;
        height: 24px;
        width: 24px;
    }

    &__dropdown {
        margin: $side-menu__dropdown-margin;
        width: $side-menu__dropdown-width;
    }

    &__secondary {
        background: $color-secondary;
        padding: $spacer--large;
    }
    &__secondary-list {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    &__secondary-item {
        padding: $spacer--small 0;
        a:hover {
            text-decoration: underline;
        }
    }
    &__main-list {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .node-subcategories {
        width: 100%;
        display: block;
    }

    // THIS PART COULD BE REFACTORED
    .node-block {
        width: 100%;
        padding: 0;
    }

    .mega-menu__link {
        border-right: 0;
    }

    .category-children,
    .category-children__list {
        margin: 0;
    }
    .dropdown-list {
        background: transparent;
    }

    .dropdown-list__item {
        border: 0 !important; // sass-lint:disable-line no-important
        .dropdown-list__content {
            display: block;
        }
    }
    .node-widget {
        padding: $spacer--medium 0 $spacer--extra-large;
    }
    .node-widget__title {
        text-align: center;
        padding: 0 $spacer;
    }

    .dropdown-list__label[aria-expanded="true"] {
        .icon {
            transform: rotate(90deg);
        }
    }
    .mega-menu__link {
        &:hover,
        &:focus {
            background: $white;
            color: $darkblue;
            border-color: $gray-lighter;
        }
        .icon {
            fill: $color-secondary;
        }
    }


}



