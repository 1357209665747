$header-button__size                : 50px !default;
$header-button__size\@extra-large   : 102px !default;
$header-button__margin              : 0 0 0 0 !default;
$header-button__margin--extra-large : 0 0 0 0 !default;
$header-button__padding             : 0 !default;

$header-button__icon-margin         : 0 0 0 !default;
$header-button__icon-margin--medium : 5px 0 0 !default;
$header-button__icon-padding        : 0 0 4px 0 !default;
$header-button__icon-color          : $darkblue !default;
$header-button__icon-color--hover   : $color-primary !default;
$header-button__transition          : none !default;

$header-button__text-font-family    : $font-family-sans-serif-alt !default;
$header-button__text-color          : $darkblue !default;
$header-button__text-font-size      : $font-size-base !default;
$header-button__text-line-height    : $font-line-height !default;

$header-button__counter-top         : 0 !default;
$header-button__counter-top--medium : 16px !default;
$header-button__counter-right       : 16px !default;
$header-button__counter-font-size   : $font-size-base !default;
$header-button__counter-background  : $pink !default;
$header-button__counter-color       : $white !default;
$header-button__counter-size        : 32px !default;
$header-button__counter-border      : 0 !default;

$header-button__counter-dot-size    : 2px !default;
$header-button__counter-dot-bg-color: $white !default;

.header-button {
    position: relative;
    display: flex;
    padding: $header-button__padding;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
    text-decoration: none;
    background-color: #fff;
    font-family: $header-button__text-font-family;
    transition: none;

    margin: $header-button__margin;
    min-width: ($header-button__size + 5px);
    height: $header-button__size;
    @include mq($screen-xl) {
        margin: $header-button__margin--extra-large;
        min-width: ($header-button__size\@extra-large + 20px);
        height: $header-button__size\@extra-large;
    }

    &:focus,
    &:hover {
        text-decoration: none;
        .header-button__icon {
            fill: $header-button__icon-color--hover;
        }
        .header-button__text {
            color: $color-primary;
        }
    }

    &--like,
    &--customer {
        display: none;
        @include mq($screen-xl) {
            display: flex;
        }
    }

    &__icon {
        margin: $header-button__icon-margin;
        fill: $header-button__icon-color;
        transition: $header-button__transition;
        width: 30px;
        height: 30px;
        @include mq($screen-m) {
            margin: $header-button__icon-margin--medium;

        }
    }

    &__text {
        font-size: $header-button__text-font-size;
        line-height: $header-button__text-line-height;
        text-align: center;
        color: $header-button__text-color;
        display: none;
        @include mq($screen-xl) {
            display: flex;
        }
    }

    &__counter {
        position: absolute;
        top: $header-button__counter-top;
        right: 6px;
        width: 16px;
        height: 16px;
        border: $header-button__counter-border;
        border-radius: $header-button__counter-size;
        line-height: 16px;
        font-size: 10px;
        font-weight: bold;
        text-align: center;
        color: $header-button__counter-color;
        background-color: $header-button__counter-background;
        overflow: hidden;
        &--hidden {
            display: none;
        }

        @include mq($screen-xl) {
            font-size: $header-button__counter-font-size;
            right: $header-button__counter-right;
            top: $header-button__counter-top--medium;
            width: $header-button__counter-size;
            height: $header-button__counter-size;
            line-height: $header-button__counter-size;
        }


        &-dot {
            width: $header-button__counter-dot-size;
            height: $header-button__counter-dot-size;
            border-radius: $header-button__counter-dot-size;
            margin: auto;
            background-color: $header-button__counter-dot-bg-color;
        }
    }
}

.header__minicart-button {
    background: $color-primary;

    .header-button__icon {
        fill: $white;
        height: 28px;
        width: 28px;
    }

    .header-button__text {
        color: #fff;
        text-transform: uppercase;
    }

    &:hover,
    &:focus {
        background: darken($color-primary, 3%);

        .header-button__icon {
            fill: $white;
        }
        .header-button__text {
            color: #fff;
        }
    }
}
