$dashboard__margin        : $spacer--large !default;
$dashboard__padding       : $spacer--medium !default;
$dashboard__title--margin : 0 0 $spacer--medium 0 !default;
$dashboard__title--padding: 0 0 $spacer 0 !default;

.dashboard {
    margin-top: 0;
    margin-bottom: 0;

    &__actions {
        justify-content: space-between;
    }

    &__handler {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        margin-bottom: $dashboard__margin;
    }

    &__title {
        margin: $dashboard__title--margin;
        padding: $dashboard__title--padding;
        font-size: $heading-font-size--third-level;

        &--wrapped {
            margin-bottom: 0;
            margin-right: $dashboard__margin;
        }
    }

    &__status {
        border: 1px solid $gray;
        border-radius: $border-radius;
        padding: 5px 15px;
        font-size: $font-size-small;
    }

    &__item {
        margin-bottom: $dashboard__margin;
        
        .action__handler {
            .action__link {
                @extend .button;
                @extend .button--link;
            }
        }
    }

    &__form {
        padding: 0 15px;

        &:first-child {
            padding-left: 5px;
        }

        &:last-child {
            padding-right: 5px;
        }
    }

    &__product {
        border: 0;
        padding: 0;
    }

    &__form {
        &--hidden {
            display: none;
        }
    }

    &__field-qty {
        max-width: 100px;
        text-align: right;
    }

    &__input {
        margin-bottom: $dashboard__margin;
    }

    &__label {
        margin: 0 20px 0 0;
    }

    &__link {
        display: flex;
        color: $color-primary;
        text-decoration: underline;
        margin-left: 68px;
        padding: 0;
        transition: none;

        &:hover {
            color: darken($color-primary, 8%);
            .dashboard__link-icon {
                fill: darken($color-primary, 8%);
            }
        }

        & + & {
            margin-top: $spacer--small;
        }


    }
    &__link-icon {
        fill: $color-primary;
        margin-right: $spacer;
        width: 20px;
        height: 20px;
    }

    &__list {
        display: none !important;
        
        margin-bottom: 1.5em;
        border-bottom: 1px solid #777;
        
        .nav.item {
            @extend .list__item;
            @extend .dashboard__list-item;
        }

        .nav.item.current {
            @extend .dashboard__list-item--current;
        }

        .nav.item > a {
            @extend .link--invert;
            @extend .dashboard__list-link;
        }
    }

    &__list-item {
        display: flex;
        align-items: center;
        position: relative;
        padding: 0;
        margin-right: 5px;
        background-color: $gray-lighter;
        border: 1px solid $gray;
        border-bottom: 0;

        &--current {
            padding: $dashboard__padding;
            background-color: $white;

            &:after {
                content: "";
                position: absolute;
                bottom: -1px;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: $white;
            }
        }
    }

    &__list-link {
        padding: $dashboard__padding;
    }
}

.order-details-items {
    .actions-toolbar {
        margin-bottom: $dashboard__margin;
        
        .action {
            @extend .button;
            @extend .button--link;
            width: auto;
        }
    }
}
