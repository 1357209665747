$dashboard-table-margin: $spacer--extra-large !default;

.dashboard-table {
    overflow: hidden;
    text-align: center;
    &--tab {
        padding: 10px;
        margin-bottom: $dashboard-table-margin;

        .dashboard-table__content {
            margin-bottom: 0;
        }
    }

    &__header {
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-end;
        margin-bottom: $dashboard-table-margin;
    }

    &__content {
        margin-bottom: $dashboard-table-margin;
        
        table {
            box-shadow: $box-shadow__dashboard;
            overflow-x: auto;
        }
        strong {
            color: $darkblue;
        }
    }

    &__table {
        margin: 0;
        text-align: left;
    }

    &__link {
        display: inline-flex;
        margin-bottom: $spacer--extra-large;
        padding: 0 $spacer--large;
    }

    &__link-icon {
        margin-left: 10px;
    }

    &__spacer {
        margin-right: $spacer;
    }

    &__title {
        margin-bottom: 0;
        margin-right: $dashboard-table-margin;
        font-size: $heading-font-size--third-level;
    }
    
    .actions {
        @include mq($screen-m) {
            text-align: center;
        }
    }
}
