.checkout-cart-index {
  .title-wrapper {
    display: none;
  }

  .cart-container {
    margin: 30px 0;

    > div {
      float: left;
    }

    .page.messages{
        float:none;
        display:block;
        clear: both;
        margin-bottom: 0;
        width: 100%;

        .message {
            text-align: center;
            display: inline-block;
            font-weight: 600;

            &::after {
                display: inline-block;
                position: relative;
                left: 5px;
                top: 3px;

            }

            > div {
                display: inline-block;
            }
        }
    }
    .cart-part-left {
      width: 100%;
      @include mq($screen-l) {
        width: calc(100% - 400px);
        #shopping-cart-table {
          box-shadow: $box-shadow__grid;
          .offer__checkbox {
            overflow: hidden;
            line-height: 28px;
            .offer__checkbox-label {
              float: left;
              margin-right: 1em;
              margin-left: 0;
              line-height: 28px;
            }
            .switch-flat {
              float: left;
              margin-top: 0;
            }
          }
        }

      }
    }

    .cart-part-right {
      width: 100%;
      @include mq($screen-l) {
        width: 360px;
        margin-top: 77px;
        margin-left: 40px;
        .cart-summary {
          box-shadow: $box-shadow__grid;
        }
      }
    }

    @include mq($screen-l) {
      margin: 30px;
      display: block;
    }

    @include mq($screen-xl) {
      margin: 14px 40px 54px;
    }
    @include mq($screen-xml) {
      margin: 14px 100px  54px;
    }


    p.title {
      font-size: 36px;
      font-family: Urbani, sans-serif;
      font-weight: 900;
      line-height: 1;
      color: #183446;
      padding: 0 20px;
      @include mq($screen-l) {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 25px;
      }
      .sub {
        font-size: 17px;
        font-weight: 500;
        text-transform: none;
        margin-left: 10px;
      }
    }

    .cart-summary {
      background: white;
      padding: 25px 20px;
      padding-bottom: 30px;
    }
  }

  .cart-list-item__name {
    font-size: 18px;
    font-family: Urbani, sans-serif;
    font-weight: 900;
    color: #183446;
    float: left;
    padding-right: 40px;
  }

  .discount__title {
    display: block;
  }

  .discount {
    overflow: auto;
    padding: 0 10px;
  }

  .discount__title-text {
    font-weight: bold;
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    font-family: Urbani, sans-serif;
  }

  .discount__title-text-message {
    float: right;
    margin-right: 0;

    p {
      font-size: 12px;
      text-decoration: underline;
      color: $color-primary;
      line-height: 18px;
      max-width: 115px;
      text-align: center;
      line-height: 1.2;
    }
  }

  .discount__title-icon {
    display: none;
  }

  .discount__content {
    display: block !important; //sass-lint:disable-line no-important
    clear: both;
  }

  .discount__button, .ampromo-items-add button {
    position: relative;
    float: right;
    width: 37%;
    overflow: hidden;
    padding: 0;
    background: #18587c;
    line-height: 30px;
    height: 30px;
    letter-spacing: 1px;
    font-size: 13px;
    &:hover {
      background: #144663
    }
  }

  .discount__input {
    float: left;
    width: 60%;

  }

  .cart-totals {
    padding: 25px 0;
    border-color: #bcc4c5;
  }

  .cart-totals__row {
    font-size: 14px;
    color: $darkblue;
  }

  a.cart-list-item__actions-item {
    display: none;
  }

  .box-qty {
    position: relative;
    float: left;
  }

  .cart-list-item__qty {
    float: left;
    margin-right: 20px;
    width: auto;
  }

  .cart-list-item__qty-input {
    text-align: center;
    border-radius: 0;
    border-color: #bdc4c5;
    background: #f7f8f8;
    box-shadow: none;
    font-family: Urbani, sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: $darkblue;
    width: 78px;
  }

  .cart-list-item__qty-wrapper {
    position: relative;
    margin-top: 10px;

    .before,
    .after {
      width: 24px;
      position: absolute;
      font-size: 20px;
      line-height: 40px;
      text-align: center;
      top: 0;
      color: $color-primary;
      cursor: pointer;
      font-size: 24px;
      font-weight: bold;
      font-family: Urbani, sans-serif;
    }

    .before {
      left: 0;
    }

    .after {
      right: 0;
    }

    label {
      float: left;
      margin: 0 15px 0 0;
      line-height: 40px;
      font-family: Roboto, sans-serif;
      font-size: 14px;
      font-weight: normal;
    }
  }

  .cart-list-item__price {
    float: right;
    @include mq(0, $screen-s) {
      float: left;
      width: 100%;

    }
    @include mq($screen-m) {
      margin-top: 8px;
    }
  }

  .cart-list-item__data,
  .cart-list-item__details {
    display: block;
  }

  .cart-list-item__details {
    margin: 0;
  }

  .cart-actions {
    display: none;
  }

  .cart-totals {
    clear: both;
  }

  .cart-totals__row.promos {
    color: red;
  }

  .cart-totals__row .price {
    font-weight: bold;
  }

  .product-description {
    font-weight: bold;
    float: left;
    margin-bottom: 10px;
    font-family: Urbani, sans-serif;
    font-size: 17px;
    color: $darkblue;
    .product-sku {
      font-size: 14px;
      padding-left: 10px;
      word-break: break-word;
      font-family: Roboto, sans-serif;
      font-weight: 300;
    }
  }

  .product-sku {
    margin-right: 0;
    font-weight: normal;
  }

  .cart-list-item__actions {
    .button__icon {
      width: 20px;
      height: 20px;
      fill: $darkblue !important;
    }

    .button--icon {
      border: none;
      background: none;
      margin-top: -7px;
    }
  }


  .cart-list-item__info {
    padding-right: 20px;
  }

  .cart-list-item {
    border-bottom: none;
    border-top: 1px solid #bcc4c5;
    padding-top: 35px;
    &:first-of-type {
      border-top: none;
    }
    .price__value--old {
      margin-top: 0;
    }
  }

  .cart__products-list {
    border-top: none;
  }

  .offer__checkbox {
    margin: 0;
    max-width: none;
    label {
      line-height: 28px;
      svg {
        float: left;
        margin-right: 5px;
        fill: $darkblue;
      }
    }
    .checkbox__label:before {
      top: 20px;
      border: solid 1px $darkblue;
      border-radius: 0;
    }
    svg.checkbox__icon {
      top: 20px;
    }
  }

  .promo-text-container {
    font-weight: bold;
    border-top: 1px solid #bcc4c5;
    padding: 25px 35px;
    font-family: Urbani, sans-serif;
    color: $darkblue;
    text-align: center;
    @include mq($screen-l) {
      text-align: left;
    }
    .price__value {
      background-color: #f34350;
      color: #ffffff;
      font-size: 20px;
      vertical-align: middle;
    }
  }
  .cms-block-online_payment_secured {
    padding: 0 20px;
    margin-top: 25px;
    ul {
      padding-left: 80px;
    }
    li {
      margin-right: 15px;
      margin-bottom: 15px;
      img {
        height: 25px;
        width: auto;
      }
    }
    p {
      font-size: 18px;
      line-height: 40px;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      font-weight: bold;
      font-family: Urbani, sans-serif;
      &:before {
        content: "";
        background: url(../images/locker.png) center no-repeat;
        background-size: 100% 100%;
        width: 34px;
        height: 49px;
        display: block;
        float: left;
        margin-right: 12px;
        margin-top: -5px;
      }
    }
  }
  .cms-block-you_offer_a_gift {
    padding: 25px 30px;
    background: #e7eaeb;
    position: relative;
    margin-top: 40px;
    &:before {
      content: "";
      background: url(../images/gift-blue.png) center no-repeat;
      background-size: 100% 100%;
      width: 56px;
      height: 62px;
      display: block;
      left: 11px;
      top: -11px;
      position: absolute;
    }
    .title {
      font-size: 18px!important;
      font-weight: bold;
      text-transform: none!important;
      padding: 0 !important;
      margin-bottom: 25px;
      margin-top: 0;
      padding-left: 40px!important;
    }
    .bold {
      font-weight: bold;
    }
    p {
      margin-top: 15px;
      font-size: 14px;
      line-height: 1.2;
    }
  }

  .price__value--percentage {
    background-color: #f34350;
    color: #ffffff;
  }
  .cart-summary__item {
    button.checkout {
      background: #f34350;
      height: 58px;
      line-height: 58px;
      font-size: 20px;
      position: relative;
      display: flex;
      &:hover {
        background: #cc3944;
      }
    }
    .cart-summary__checkout-button-icon {
      position: absolute;
      right: $spacer--large;
      fill: $white;
      width: 16px;
      height: 16px;
    }
    h3 {
      font-weight: 600;
      text-align: center;
      border-bottom: 1px solid #bcc4c5;
      margin-bottom: 0;
      padding-bottom: 30px;
      font-size: 24px;
    }
  }

  .discount__field {
    background: none;
    border: 1px solid $darkblue;
    color: $darkblue;
    box-shadow: none;
    &::placeholder {
      color: $input__placeholder-color;
      font-size: 14px;
    }
  }
  .cart-list-item__image {
      padding: 0 25px;
      padding-top: 5px;
    padding-right: 15px;
    flex: 0 0 130px;
    @include mq($screen-m) {
      padding: 0 25px;
      flex: 0 0 160px;
    }

  }
  .cart-totals__row--summary {
    margin-bottom: 0;
    padding-bottom: 10px;
    border-color:  #bcc4c5;
  }
  .ampromo-items-add {
    border: none;
    border-top: 1px solid #bcc4c5;
    border-bottom: 1px solid #bcc4c5;
    padding: 20px 10px;
    margin-bottom: 0;
    > a {
      border: none;
    }
    p {
      font-size: 14px;
      line-height: 1.2;
    }
    button {
      padding: 0 25px;
      float: none;
      width: auto;
    }
  }

  .cart-empty {
    text-align: center;
  }
}
#mini-cart {
  .minicart-product {
    position: relative;
    .minicart-product__actions {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

.checkout-cart-index, .checkout-index-index {

  .ampromo-item-group {
    margin-left: -15px;
    margin-right: -15px;
  }

  .ampromo-item-buttons {
    flex: 0 0 auto;
    margin-top: auto;

    .button {
      height: 30px;
      line-height: 1px;
      position: relative;
    }
    .cart-summary__checkout-button-icon {
      fill: $color-primary;
      height: 14px;
      width: 14px;
      right: 10px;
      top: 50%;
      margin-top: -7px;
    }
    .button {
      &:focus {
        .cart-summary__checkout-button-icon {
          fill: white;
        }
      }
    }
  }
  .ampromo-items-content {
    width: 1170px;
    max-width: calc(100% - 40px);
    @include mq($screen-xl) {
      margin: 120px auto;
      max-height: calc(100% - 240px);
    }
    h2 {
      font-size: 30px;
      margin-bottom: 30px;
      margin-left: 15px;
      margin-right: 15px;
    }
    .ampromo-items-form {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 0;
    }
    .ampromo-item{
      border: none;
      padding: 0;
      margin: 0;
      background: none!important;
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 30px;
      @include mq($screen-s) {
        width: 50%;
      }
      @include mq($screen-m) {
        width: 25%;
      }

      @include mq($screen-l) {
        width: 16.5%;
      }

      @include mq($screen-xl) {
        width: 12.5%;
      }

      .ampromo-options {
        margin-bottom: 0;
        margin-top: 5px;
      }
      .ampromo-item-image {
        border: 3px solid white;
        border-radius: 3px;
        max-width: 100%;
        width: auto;
      }
      &:hover, &:focus {
        .ampromo-item-image {
          border-color: $color-primary;
        }

        .cart-summary__checkout-button-icon {
          fill: #ffffff;
        }
        .button--secondary {
          border-color: $color-primary ;
          color: #fff;
          background: $color-primary ;
        }
      }
      .ampromo-item-image {
        flex: 0 0 auto;
        margin: 0 auto 5px;
        height: 130px;
      }
    }
    .ampromo-item-title {
      //display: none;
    }
  }
  .ampromo-gallery {
    margin-left: -15px;
    margin-right: -15px;
    width: auto !important;
    margin: 0;
    h3 {
      border-bottom: 1px solid #bcc4c5;
      padding-bottom: 5px;
      font-size: 24px;
      display: block;
      width: 100%;
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 30px;
    }
  }
  .ampromo-overlay {
    overflow: unset;
    background-color: $color-overlay;
  }

  .ampromo-items-content {
    .ampromo-close {
      width: 30px;
      height: 30px;
      border-radius: 16px;
      background-color: $color-secondary;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' id='close' width='100%25' height='100%25'%3E%3Cpath fill='none' stroke='%23FFF' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' d='M1.74 1.74l8.52 8.52M10.26 1.74l-8.52 8.52'%3E%3C/path%3E%3C/svg%3E");
      background-size: 16px 16px;
      background-position: center;
      &:hover {
        background-color: $color-primary;
      }

      @include mq(0, $screen-m) {
        position: fixed;
        top: 40px;
        right: 30px;
      }
    }
  }
}

.ampromo-items-add > a {
  border: none !important;
}

.checkout-container .authentication-wrapper {
  margin-top: 0;
}
