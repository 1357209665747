// LOCAL MAGENTO CSS VARIABLES OVERRIDE
$modal-popup__z-index: 1001;
$modal-overlay__background-color: rgba($color-secondary, 0.9);

// CHILD THEME VARIABLES OVERRIDE
$color-primary: #e53c3a;
$color-secondary: #71100f;
$color-overlay: rgba(42, 49, 139, 0.9);
$darkblue: $color-secondary;
$blue: $color-secondary;
$teal: $color-primary;
$newsletter__button-bg-color: $pink;
$newsletter__heading-color: $white;
$newsletter__checkbox-label-color: $white;
$newsletter__text-color: $white;
$footer__title-color: $white;
$badge__background           : rgba($color-primary, 0.9);

// FIX BOX SHADOW INHERITANCE IF NEEDED
