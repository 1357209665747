$layout-sidebar-width: 25% !default;
$layout-content-width: calc(100% - #{$layout-sidebar-width}) !default;

.page-layout-2columns-left,
.page-layout-2columns-right {
    .columns {
        display: flex;
        flex-direction: column;

        @include mq($screen-m) {
            flex-direction: row;
        }
    }

    .main {
        @include mq($screen-m) {
            order: 1;
            flex: 1 0 $layout-content-width;
            max-width: $layout-content-width;
        }
    }

    .sidebar {
        @include mq($screen-m) {
            flex: 1 0 $layout-sidebar-width;
            max-width: $layout-sidebar-width;
        }
    }
}

.page-layout-2columns-right {
    .main {
        @include mq($screen-m) {
            order: 0;
        }
    }
}

// custom layouts
.page-main {
    position: relative;
    overflow: hidden;
    @include clearfix;

    @include mq(0, $screen-xl) {
        margin-left: 0;
        margin-right: 0;
    }
}

.columns {
    padding-bottom: $spacer--extra-large;

    @include mq($screen-xl) {
        width: calc(100% - 250px);
        float: right;

    }

    &.account {
        background: $gray-lightest;
    }
}

@media all and (-ms-high-contrast:none)
{
    @include mq($screen-xl) {
        .columns {
            margin-top: 137px;
            min-height: 680px;
        }

        *::-ms-backdrop, .columns {
            margin-top: 137px;
            min-height: 680px;
        }
    }
}

.page-content {
    margin: 30px $spacer--medium;
    @include mq($screen-s) {
        margin: 54px $spacer--medium;
    }
    @include mq($screen-xl) {
        margin: 54px 40px;
    }
    @include mq($screen-xml) {
        margin: 54px 100px;
    }


    &.product-view {
        margin-top: 10px;
    }

    &.category-view {
        margin-top: 40px;
        @include mq($screen-xl) {
            margin: 50px 40px 60px;
        }
        @include mq($screen-xxl) {
            margin: 50px 140px 60px;
        }

    }

    &.contact {
        @include mq($screen-xl) {
            margin: 40px 160px 60px;
        }
        @include mq($screen-xxl) {
            margin: 40px 320px 60px;
        }
    }
}

// TEMP
html,
body {
    margin: 0 auto;
}

body {
    &.overflow-hidden, &.overflow-hidden-menu {
        overflow: hidden;
    }
    @include mq(0, $screen-m) {
        &.overflow-hidden-mobile {
            overflow: hidden;
        }
    }
}