$spacer-checkout: 60px !default;
$spacer-checkout--big: 98px !default;

.row--checkout {
    @include fix-gutter(42px);
}

.payment-method__icon {
    display: inline-block;
    width: auto;
    margin: 15px 24px 15px 0;
    max-height: 33px;
    &--cb {
        max-height: 27px;
        &:first-of-type {
            margin-right: $spacer--medium;
        }
    }
    &--postfinance {
        max-height: 27px;
    }
}

.payment-method__tip {
    display: none;
    align-items: center;
    color: $gray;
    font-size: $font-size-small;
    margin-top: 5px;
    
    &-icon {
        margin-bottom: 2px;
        width: auto;
        height: 28px;
        margin-right: 12px;
    }
}

.multishipping-checkout-billing {
    .box-billing-method {
        margin-top: $spacer-checkout;
    }
}
.multishipping-checkout_address-selectbilling {
    .multicheckout {
        padding-top: 0;
        .box-billing-address {
            padding-top: $spacer-checkout;
            padding-bottom: $spacer-checkout;
        }
    }
}

.multicheckout-summary__list {
    border-bottom: none;
}


.multicheckout-summary__incentive {
    vertical-align: middle;
    line-height: 100%;
    padding-top: 4px;
    padding-bottom: 4px;
    .price__value {
        vertical-align: middle;
        padding: 0 8px !important;
    }
}

.multicheckout {
    background: $white;
    box-shadow: $box-shadow__form;
    padding: $spacer--large 0 $spacer-checkout;


    &.order-review {
        .multicheckout-summary {
            width: 100%;
            @include mq($screen-m) {
                width: 320px;
            }
            box-shadow: none;

            max-width: 100%;
            padding: 0;
            float: right;
            .multicheckout-summary__title {
                display: none;
            }
        }
    }


    .extra-img {
        .more {
            color: $color-primary;
            font-size: 24px;
            width: 22px;
            height: 22px;
            box-shadow: 0 2px 8px rgba(24, 52, 70, 0.3);
            display: inline-block;
            border-radius: 50%;
            line-height: 22px;
            text-align: center;
            font-weight: 400;
            top: 3px;
            position: relative;
            z-index: 10;
            background: white;
            margin-left: -12px;
            margin-right: -20px;
        }
        .numeric-edition-included {
            fill: $teal;
            svg {
                width: 62px;
                height: 37px;
            }
        }
    }

    .truck_mentions {
        .truck_icon {
            display: inline-block;
            width: 50px;
            height: 27px;
            vertical-align: middle;
            margin-right: 5px;
        }
    }

    &.success {
        padding: 0;
        box-shadow: none;
        
        .actions-toolbar {
            text-align: center;
            
            .button,
            button {
                @extend .button;
                @extend .button--link;
                width: auto;
                min-width: 220px;
                margin: 5px;
            }
        }
    }

    .delete__icon {
        fill: $darkblue;
    }

    &__title {
        font-size: 30px;
        color: $darkblue;
        padding: 0 20px;
        @include mq($screen-l) {
            padding: 0 $spacer-checkout--big;
        }
    }

    .box {
        padding: 0 20px;
        @include mq($screen-l) {
            padding: 0 $spacer-checkout--big;
        }
    }
    .box-billing-address {
        border-bottom: 1px solid $gray-lighter;
        padding-bottom: $spacer-checkout;

        .box-content {
            @include mq($screen-m) {
                display: flex;
                align-items: flex-start;
                max-width: 570px;
            }
        }
    }


    .box-billing-address .box-title span,
    .box-billing-method .box-title span,
    .block-shipping .block-title strong {
        font-family: $font-family-sans-serif-alt;
        font-size: 24px;
        color: $darkblue;
        font-weight: 700;
        margin-bottom: $spacer--large;
        display: block;
    }

    address {
        font-style: normal;
        width: 100%;
        @include mq($screen-m) {
            width: 50%;
        }

        color: $darkblue;
        line-height: 1.5;
        a {
            color: $darkblue;
        }
    }

    .block-shipping {

        .block-title {
            margin: $spacer-checkout $spacer-checkout 0;
            @include mq($screen-l) {
                margin: $spacer-checkout $spacer-checkout--big 0;
            }
        }

        .title--address {
            margin: 0 $spacer-checkout $spacer--large;
            font-family: $font-family-sans-serif-alt;
            font-size: $font-size-medium;
            text-transform: uppercase;
            font-weight: 400;

            @include mq($screen-l) {
                margin: 0 $spacer-checkout--big $spacer--large;
            }
        }

        .block-content .box-content {
            overflow: hidden;
        }
    }

    .checkout-payment-method {
        .label {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            height: auto;
            margin-bottom: 0;
            margin-left: 0;
            font-family: $font-family-sans-serif;
            color: $darkblue;
            line-height: normal;
        }
        .radio {
            margin-bottom: 0;
        }
        .radio__icon {
            margin-right: 20px;
        }
        .item-title {
            padding: 12px;
            background: $gray-lightest;
            margin-bottom: $spacer;

            &:last-of-type {
                margin-bottom: 0;
            }
            
            @include mq($screen-s) {
                padding: 12px 24px;
            }
        }


    }

    .actions-toolbar {
        margin: $spacer--extra-large $spacer;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .checkout-review__payment {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: $gray-lightest;
        padding: $spacer--medium;
        margin-bottom: $spacer-checkout;

        .title {
            color: $darkblue;
            font-weight: 700;
        }
    }

    .checkout-review__payment,
    .box-billing-address,
    .order-review-wrapper {
        .action {
            width: auto;
            color: $color-primary;
            text-decoration: underline;
            display: inline-flex;

            &__icon {
                fill: $color-primary;
                width: 20px;
                height: 20px;
                margin-right: $spacer;
            }
        }
    }

    .box-billing-address {
        .action.primary {
            padding: 0 !important;
            text-decoration: none;
            .action__icon {
                fill: #ffffff;
            }
        }
        .action.edit{
            justify-content: center;
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .order-review-wrapper {
        padding-bottom: $spacer-checkout;
        margin-bottom: $spacer-checkout;
        border-bottom: 1px solid $gray-lighter;
        @include fix-gutter(42px);

        address {
            width: 100%;
            margin-bottom: $spacer--large;
        }

        .box-title {
            font-weight: 900;
            margin-bottom: $spacer--large;
            display: block;
            font-family: $font-family-sans-serif-alt;
            font-size: $font-size-medium;
        }
    }
    .box-review {
        margin-bottom: $spacer-checkout;
        @include clearfix;
    }

    .table-order-review {
        max-width: 320px !important; //sass-lint:disable-line no-important
        float: right;
        margin: 0;

        tfoot td,
        tfoot th {
            background: $white;
            border: 0;
            padding: 10px 0 !important; //sass-lint:disable-line no-important
            color: $darkblue;
            vertical-align: middle !important;; //sass-lint:disable-line no-important
        }

        tfoot th {
            font-size: $font-size-small;
            font-weight: normal;
        }
        
        tfoot td {
            text-align: right;
            font-family: $font-family-sans-serif-alt;
            font-weight: 700;
            .price {
                font-weight: 900;
            }
        }

        .grand th,
        .grand td {
            border-top: 1px solid $gray-light;
            border-bottom: 1px solid $gray-light;
        }

        .grand td {
            font-weight: 900;
            font-size: $font-size-extra-large;
        }
    }

    &.address.form {
        padding: 40px $spacer--medium 30px;
        @include mq($screen-l) {
            padding: 40px $spacer--extra-large 30px ;
        }
    }

    .adresses__title {
        font-size: 30px;
        color: $darkblue;
        margin-bottom: 25px;
        @include mq($screen-xl) {
            margin-left: $spacer--extra-large;
        }
    }
    .addresses__subtitle {
        font-family: $font-family-sans-serif-alt;

        @include mq($screen-xl) {
            margin-left: 40px;
        }

        .title {
            font-size: 24px;
            font-weight: 700;
            color: $darkblue;
            margin-bottom: 0;

        }
        .warning {
            color: $pink;
            display: flex;
            align-items: center;
            margin-top: $spacer;
            @include mq($screen-m) {
                margin-top: 0;
            }
        }
        .warning__icon {
            fill: $pink;
            margin-right: $spacer;
            width: 34px;
            height: 34px;
        }

        .delivery-country {
            color: $teal;
            font-weight: normal;
            display: flex;
            align-items: center;
            margin-top: $spacer;
            @include mq($screen-m) {
                margin-top: 0;
            }
        }
        .delivery-country__icon {
            fill: $teal;
            margin-right: $spacer;
            width: 40px;
            height: 40px;
            min-width: 40px;
            min-height: 40px;
        }

    }
    .addresses__list {
        overflow: hidden;
    }

    .addresses-item {
        padding: $spacer--extra-large 0;
        border-bottom: 1px solid $gray-lighter;
        @include fix-gutter(42px);

        .address .label {
            font-weight: 900;
            font-size: $font-size-large;
            margin: 0 0 $spacer--medium 0;
            @include mq($screen-m) {
                margin-bottom: 0 0 $spacer--large $spacer;
            }
        }

        .control {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .select {
                margin-bottom: 0;
                max-width: 90%;
            }
        }
        .action {
            width: auto;
        }

        .add {
            margin: $spacer--medium 0 0 $spacer--medium;
            cursor: pointer;
            background: transparent;
            border: 0;
            font-size: $font-size-small;
            color: $color-primary;
            text-decoration: underline;
            font-family: $font-family-sans-serif;

            &:before {
                content: "+";
                height: 22px;
                width: 22px;
                display: inline-block;
                color: $white;
                background: $color-primary;
                margin-right: $spacer;
                border-radius: 50%;
                text-align: center;
                line-height: 22px;
                font-size: 22px;
                font-weight: 700;
                font-family: $font-family-sans-serif-alt;
                overflow: hidden;
            }
        }
    }

    .address-comment {
        label {
            margin-top: 1em;
        }

        .input {
            width: 90%;
        }
    }

    &__item {
        margin-bottom: $spacer--large;
        @include fix-gutter(42px);

        @include mq($screen-xl) {
            padding-left: $spacer--extra-large;
        }

        .multishipping-checkout-overview & {
            max-width: 620px;
            padding-left: 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        .product-item-name {
            color: $darkblue;
            font-size: $font-size-medium;
            font-weight: 900;
            font-family: $font-family-sans-serif-alt;
            margin: $spacer 0;
            display: block;
            @include mq($screen-m) {
                margin-top: 0;
            }
        }

        .product-description {
            margin-bottom: $spacer--medium;
            font-size: $font-size-base;
            font-weight: 700;
            font-family: $font-family-sans-serif-alt;
            color: $darkblue;
        }
        .product-sku {
            font-size: $font-size-small;
            font-weight: 300;
        }
        .price-area {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: $spacer--medium;
            color: $darkblue;
            font-size: $font-size-small;

            @include mq($screen-m) {
                align-items: center;
                flex-direction: row;
            }

            .price {
                margin: $spacer 0 0;
                @include mq($screen-m) {
                    margin: 0;
                }
            }
        }
        .child-image-wrapper {
            margin-bottom: $spacer--medium;
            img {
                width: auto;
            }
        }
        .product-gift {
            color: $darkblue;
            font-size: $font-size-small;
            display: flex;
            align-items: flex-end;
            &__icon {
                fill: $darkblue;
                margin-right: $spacer;
            }
        }
    }
}


body.multishipping-checkout_address-newshipping {
    .warning {
        color: $pink;
        display: flex;
        align-items: center;
        margin-top: $spacer;
        @include mq($screen-m) {
            margin-top: 0;
        }
    }
    .warning__icon {
        fill: $pink;
        margin-right: $spacer;
        width: 34px;
        height: 34px;
    }
    .delivery-country {
        color: $teal;
        font-weight: normal;
        display: flex;
        align-items: center;
        margin-top: $spacer;
        @include mq($screen-m) {
            margin-top: 0;
        }
    }
    .delivery-country__icon {
        fill: $teal;
        margin-right: $spacer;
        width: 40px;
        height: 40px;
        min-width: 40px;
        min-height: 40px;
    }
}