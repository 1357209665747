$newsletter__font-size                  : $font-size-base !default;
$newsletter__font-size--agreements      : 12px !default;
$newsletter__field-height               : 36px !default;
$newsletter__field-border               : 1px solid $color-secondary !default;
$newsletter__field-border-radius        : 32px !default;
$newsletter__field-padding              : 0 $spacer--medium !default;
$newsletter__line-height                : 1.2 !default;
$newsletter__input-background           : #fff !default;
$newsletter__input-color                : $gray-darkest !default;
$newsletter__input-color--placeholder   : $gray-light !default;
$newsletter__checkbox-label-color       : $darkblue !default;
$newsletter__heading-color              : $darkblue !default;
$newsletter__text-color                 : $darkblue !default;
$newsletter__controls-height            : 36px !default;
$newsletter__bottom-gap                 : $spacer !default;
$newsletter__button-padding             : 21px !default;
$newsletter__button-bg-color            : $color-secondary !default;
$newsletter__checkbox-bg-color          : $white !default;
$newsletter__checkbox-label-padding-left: 22px !default;
$newsletter__checkbox-label-padding-top : 0 !default;
$newsletter__checkbox-label-line-height : $font-line-height !default;
$newsletter__checkbox-icon-border-radius: 1px !default;
$newsletter__checkbox-icon-size         : 14px !default;
$newsletter__checkbox-icon-position-top : 0 !default;
$newsletter__heading-margin             : 0 20px $newsletter__bottom-gap 0 !default;

.newsletter {
    &__heading {
        line-height: $newsletter__line-height;
        color: $newsletter__heading-color;
        height: auto;
        font-size: $font-size-base;
        font-weight: 900;
        text-align: center;
        margin: $newsletter__heading-margin;
        text-transform: uppercase;
        @include mq($screen-xl) {
            text-align: left;
        }
    }
    &__controls {
        position: relative;
        height: auto;
        margin-bottom: 15px;
    }
    &__button {

        height: $newsletter__controls-height;
        border-radius: $newsletter__field-border-radius;
        padding: 0 $newsletter__button-padding;
        width: 90%;
        background: $newsletter__button-bg-color;
        &:hover,
        &:focus {
            background: darken($newsletter__button-bg-color, 10%);
            cursor: pointer;
            outline: none;
        }

        @include mq($screen-xl) {
            position: absolute;
            top: 0;
            right: 0;
            width: 38%;
        }
    }
    &__input {
        margin: 0;
    }
    &__label {
        @include visually-hidden;
    }
    &__field {
        background: $newsletter__input-background;
        padding: $newsletter__field-padding;
        margin: 0 0 10px;
        border: $newsletter__field-border;
        border-radius: $newsletter__field-border-radius;
        height: $newsletter__field-height;
        color: $newsletter__input-color;
        line-height: $newsletter__field-height;
        width: 90%;
        font-family: $font-family-sans-serif-alt;
        box-shadow: none;
        &::placeholder {
            color: $newsletter__input-color--placeholder;
        }

        @include mq($screen-xl) {
            width: 60%;
            margin: 0;
        }
    }
    &__text {
        margin-bottom: 16px;
        color: $newsletter__text-color;
        margin-top: 28px;
    }
    .checkbox__icon {
        top: $newsletter__checkbox-icon-position-top;
        left: 0;
        border-radius: $newsletter__checkbox-icon-border-radius;
        width: $newsletter__checkbox-icon-size;
        height: $newsletter__checkbox-icon-size;
    }
    .checkbox__field {
        &:checked ~ .checkbox__label:before {
            background-color: $darkblue;
            background-image: url(../images/check.svg);
            background-position: center;
            background-repeat: no-repeat;
            -webkit-background-size: 70%;
            background-size: 70%;
        }
    }

    .checkbox__field:checked + .checkbox__icon {
        display: none;
    }
    .checkbox__label {
        width: 100%;
        opacity: 1;
        font-size: $newsletter__font-size--agreements;
        line-height: $newsletter__checkbox-label-line-height;
        text-align: center;
        padding-left: 0;
        padding-top: $newsletter__checkbox-label-padding-top;
        padding-right: 0;
        color: $newsletter__checkbox-label-color;
        font-weight: normal;
        &:before {

            width: $newsletter__checkbox-icon-size;
            height: $newsletter__checkbox-icon-size;
            border: 1px solid $color-secondary;
            background: $newsletter__checkbox-bg-color;
            border-radius: $newsletter__checkbox-icon-border-radius;
            top: 2px;
            position: relative;
            display: inline-block;
            margin-right: 5px;
            @include mq($screen-xl) {
                position: absolute;
                margin-right: 0;
                top: 50%; // to align with label, depends on line-height of label
                margin-top: -14px;
            }
        }

        @include mq($screen-xl) {
            text-align: left;
            padding-left: $newsletter__checkbox-label-padding-left;
        }

        & > a {
            &:hover,
            &:focus {
                color: $newsletter__checkbox-label-color;
            }
        }
    }
}
