$button__height                     : 38px !default;
$button__width                      : 48px !default;
$button__padding                    : 0 $spacer--medium !default;
$button__border                     : none !default;
$button__border-radius              : 100px !default;
$button__border-hover               : none !default;
$button__background                 : $color-primary !default;
$button__background-hover           : darken($color-primary, 25%) !default;
$button__outline                    : none !default;
$button__font-family                : $font-family-condensed !default;
$button__font-weight                : $font-weight-normal !default;
$button__font-size                  : $font-size-small !default;
$button__font-color                 : $white !default;
$button__font-color-hover           : $white !default;
$button__transition                 : $transition-base !default;

// Secondary
$button__border--secondary          : 1px solid $color-primary !default;
$button__border-hover--secondary    : $color-primary !default;
$button__background--secondary      : transparent !default;
$button__background-hover--secondary: $color-primary !default;
$button__font-color--secondary      : $color-primary !default;
$button__font-color-hover--secondary: #fff !default;

// Icon
$button__padding--icon              : 0 !default;
$button__size--icon                 : 10px !default;
$button__border-width--icon         : 3px !default;
$button__border--icon               : $button__border-width--icon solid $color-primary !default;
$button__border-hover--icon         : darken($color-primary, 25%) !default;
$button__fill--icon                 : $white !default;
$button__fill-hover--icon           : $white !default;
$button__background--icon           : $color-primary !default;
$button__background-hover--icon     : darken($color-primary, 25%) !default;

//fluid
$button__width--fluid               : 100% !default;

.button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: $button__height;
    min-width: $button__width;
    line-height: 1.1;
    padding: $button__padding;
    border: $button__border;
    border-radius: $button__border-radius;
    outline: $button__outline;
    background: $button__background;
    font-family: $button__font-family;
    font-size: $button__font-size;
    color: $button__font-color;
    transition: $button__transition;
    font-weight: $button__font-weight;
    text-transform: uppercase;

    &:hover,
    &:focus {
        background: $button__background-hover;
        border-color: $button__border-hover;
        color: $button__font-color-hover;
    }

    @include isIE() {
        align-items: center;
    }

    &--link {
        align-items: center;
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: none;
            color: $button__font-color-hover;
        }
    }

    &--secondary {
        border: $button__border--secondary;
        color: $button__font-color--secondary;
        background: $button__background--secondary;

        &:hover,
        &:focus {
            border-color: $button__border-hover--secondary;
            color: $button__font-color-hover--secondary;
            background: $button__background-hover--secondary;
        }
    }

    &--icon {
        align-items: center;
        padding: $button__padding--icon;
        border: $button__border--icon;
        background: $button__background--icon;

        &:hover,
        &:focus {
            border-color: $button__border-hover--icon;
            background: $button__background-hover--icon;
            .button__icon,
            .icon {
                fill: $button__fill-hover--icon;
            }
        }
    }

    &--pink {
        background: $pink;
        border-color: $pink;
        &:hover,
        &:focus {
            background: darken($pink, 25%);
            border-color: darken($pink, 25%);
        }
    }

    &--fluid {
        width: $button__width--fluid;
    }

    &--noradius {
        border-radius: 0;
    }

    &__icon {
        width: $button__size--icon;
        height: $button__size--icon;
        fill: $button__fill--icon;
        transition: $button__transition;
    }
    &__icon-text {
        width: 16px;
        height: 16px;
        margin-left: $spacer--medium;
        fill: $button__fill--icon;
    }
}
