// LIBRE BASKERVILLE
@font-face {
    font-family: 'Libre Baskerville';
    font-style: normal;
    font-weight: 400;
    src: local('Libre Baskerville'), local('LibreBaskerville-Regular'),
    url('../fonts/libre-baskerville-v6-latin-regular.woff2') format('woff2'),
    url('../fonts/libre-baskerville-v6-latin-regular.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Libre Baskerville';
    font-style: normal;
    font-weight: 700;
    src: local('Libre Baskerville Bold'), local('LibreBaskerville-Bold'),
    url('../fonts/libre-baskerville-v6-latin-700.woff2') format('woff2'),
    url('../fonts/libre-baskerville-v6-latin-700.woff') format('woff');
    font-display: swap;
}
// ROBOTO CONDENSED
@font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto Condensed'), local('RobotoCondensed-Regular'),
    url('../fonts/roboto-condensed-v16-latin-regular.woff2') format('woff2'),
    url('../fonts/roboto-condensed-v16-latin-regular.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    src: local('Roboto Condensed Bold'), local('RobotoCondensed-Bold'),
    url('../fonts/roboto-condensed-v16-latin-700.woff2') format('woff2'),
    url('../fonts/roboto-condensed-v16-latin-700.woff') format('woff');
    font-display: swap;
}
// ROBOTO
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: local('Roboto Light'), local('Roboto-Light'),
    url('../fonts/roboto-v18-latin-300.woff2') format('woff2'),
    url('../fonts/roboto-v18-latin-300.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto'), local('Roboto-Regular'),
    url('../fonts/roboto-v18-latin-regular.woff2') format('woff2'),
    url('../fonts/roboto-v18-latin-regular.woff') format('woff');
    font-display: swap;
}
// URBANI
@font-face {
    font-family: 'Urbani';
    font-style: normal;
    font-weight: 200;
    src: local('Urbani Light'), local('Urbani-Light'),
    url('../fonts/Urbani-Light.woff2') format('woff2'),
    url('../fonts/Urbani-Light.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Urbani';
    font-style: normal;
    font-weight: 300;
    src: local('Urbani Book'), local('Urbani-Book'),
    url('../fonts/Urbani-Book.woff2') format('woff2'),
    url('../fonts/Urbani-Book.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Urbani';
    font-style: normal;
    font-weight: 400;
    src: local('Urbani'), local('Urbani-Regular'),
    url('../fonts/Urbani-Regular.woff2') format('woff2'),
    url('../fonts/Urbani-Regular.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Urbani';
    font-style: normal;
    font-weight: 700;
    src: local('Urbani Bold'), local('Urbani-Bold'),
    url('../fonts/Urbani-Bold.woff2') format('woff2'),
    url('../fonts/Urbani-Bold.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Urbani';
    font-style: normal;
    font-weight: 800;
    src: local('Urbani ExtraBold'), local('Urbani-ExtraBold'),
    url('../fonts/Urbani-ExtraBold.woff2') format('woff2'),
    url('../fonts/Urbani-ExtraBold.woff') format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Urbani';
    font-style: normal;
    font-weight: 900;
    src: local('Urbani Black'), local('Urbani-Black'),
    url('../fonts/Urbani-Black.woff2') format('woff2'),
    url('../fonts/Urbani-Black.woff') format('woff');
    font-display: swap;
}
