
.mgk-adcpopup-modal.modal-popup.modal-slide._show .modal-inner-wrap {
  @include mq($screen-xl) {
    transform: translateY(50%);
  }
}

.mgk-adcpopup-modal.modal-popup {
  .modal__close-button {
    top: 7px;
    right: 7px;
    @include mq($screen-m) {
      top: 16px;
      right: 16px;
    }
  }
  .modal-header {
    height: 0;
    padding: 0;
    overflow: hidden;
  }
  .modal-content {
    padding: 0;
  }

  .product-items {
    list-style: none;
    padding: 0 0 0 $spacer--medium;
  }

  .product-item {
    display: flex;
  }

  .product-item-photo {
    flex: 0 0 auto;
    display: inline-block;
    max-width: 350px;
    margin-right: $spacer--large;
    width: auto;
    padding: 0;
    border: none;
    box-shadow: none;
    
    .offer__image {
        height: auto;
        max-height: 110px;
        margin-bottom: 0;
    }
    
    .more {
      margin-left: -6px;
    }
  }
  .product-item-details {
    text-align: left;
    overflow: hidden;
  }

  .product-item-name {
    margin-bottom: $spacer;
    display: block;
    a {
      font-family: $font-family-sans-serif-alt;
      font-weight: 900;
      font-size: $font-size-medium;
      color: $darkblue;
    }
  }

  .product-item-sku {
    margin-bottom: $spacer;
    &,
    .label {
      font-weight: 300;
      color: $darkblue;
      font-size: $font-size-small;
      margin-left: 0;
    }
  }

  .details-qty.qty {
    margin-top: 7px;
    .label {
      margin-left: 0;
      font-weight: normal;
      font-family: Roboto, sans-serif;
      font-size: 14px;
    }
    .qty-text {
      font-family: Urbani, sans-serif;
      font-size: 14px;
      font-weight: bold;
    }
  }

  .label,
  .values {
    display: inline;
  }

  .price__value--old {
    text-align: left;
  }
  
  @include mq(0, $screen-s) {
      .product-item {
        display: block;
        text-align: center;
      }

      .product-item-photo {
          margin-right: 16px;
      }
  }
}

.adcpopup-content {
  border-bottom: 1px solid $gray-light;
  padding: 30px 10px 0;
  @include mq($screen-m) {
    padding: 30px;
  }

  @include mq($screen-xl) {
    border-bottom: 0;
    border-right: 1px solid $gray-light;
  }

  &__title {
    font-family: $font-family-sans-serif-alt;
    font-size: $font-size-medium;
    margin-bottom: $spacer--large;
    color: $darkblue;
  }
  &__title-icon {
    float: left;
    margin-right: $spacer;
    fill: $green;
    padding: 1px;
  }
  .adcpopup-content__title {
    margin-right: 30px;
    font-weight: 600;
    @include mq($screen-m) {
      margin-right: 0;
    }
  }
}

.adcpopup-summary {
  padding: 0;

  .shopping-actions {
    padding: 10px 30px;
    max-width: 290px;
    margin-left: auto;
    margin-right: auto;
    @include mq($screen-m) {
      padding: 30px;
    }

    .button + .button {
      margin-top: 10px;

      @include mq($screen-m) {
        margin-top: 20px;
      }
    }

    .button {
      position: relative;
      .button__icon-text {
        position: absolute;
        right: 10px;
        width: 14px;
        top: 50%;
        margin-top: -8px;
      }
    }
  }

  .content-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }

  .shopping-cart {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .summary-item {
    padding: 10px 30px;
    color: $darkblue;
    font-size: 14px;
    &.visible-xl {
      display: none;
      @include mq($screen-xl) {
        display: block;
      }
    }
    &.cart-qty-label {
      font-size: 17px;
    }
    &:last-of-type {
      @include mq($screen-xl) {
        border-top: 1px solid $gray-light;
      }
      border-bottom: 1px solid $gray-light;
    }
    .label {
      margin: 0;
      font-size: 14px;
      font-weight: 900;
    }
    .values.right {
      font-family: Urbani, sans-serif;
      font-weight: bold;
      font-size: 16px;
    }
  }

  .summary-title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
  }
}

.mgk-adcpopup-block {
  margin: 0;
}

.modal-popup.modal-slide {
  z-index: 90000 !important; //sass-lint:disable-line no-important
}
