.amgdprcookie-modal-template {


    position: relative;
    min-height:100%;
    #close-modal {
        display: none !important;
    }
    .amgdprcookie-buttons-block {
        display:flex;
        justify-content: center;
    }
}
@media (max-width: 768px) {

    .amgdprcookie-modal-template {
        margin: 1em;
        padding: 1em;
        width: calc(100% - 2em);
        border: $color-secondary 5px solid;
        background-color:rgba(255,255,255,1);
    }
    .amgdprcookie-groups-modal {

        .modal-inner-wrap {
            width: 100% !important;
            border: $color-secondary 5px solid;
            margin: 0 !important;
            .modal-content {
                overflow:auto;
            }
        }

    }
}
@media (min-width: 768px) {

    .amgdprcookie-modal-template {
        background-color:rgba(255,255,255,0.9);
        padding: 2em;
        max-width: 50%;
        border-right: $color-secondary 5px solid;

    }
}
.modal-popup {
    &.amgdprcookie-modal-container {
        &.modal-slide {
            z-index:9500 !important;
        }
    }
}

.amgdprcookie-button {
    align-items: center;
    flex-basis: auto;
    flex-shrink: 0;
    margin: 1em auto;
    min-width: $cookie_message__close-size;
    height: $cookie-message__close-size;
    padding: $cookie-message__close-padding;
    background: $cookie-message__close-background-color;
    border: none;
    color: $cookie-message__close-color;
    cursor: pointer;
    font-weight: $cookie-message__close-font-weight;
    font-family: inherit;
    font-size: $cookie-message__close-font-size;
    border-radius: 100px;
    outline: none;

    @include mq($screen-s) {
        margin: 0 15px;
        padding: $cookie-message__close-padding--small;
    }
}

.amgdprcookie-link {
    margin-bottom: 2em;
    display: inline-block;
}

form.amgdprcookie-settings-form {
    .amgdprcookie-groups-container {
        height: auto !important;
    }

}


/*.amgdprjs-bar-template {
    z-index: 10005 !important;
    position: absolute;
    background-color:rgba(240,240,240,0.7);
}*/

/*
#gdpr-cookie-block, #gdpr-cookie-container-cookie-settings {
  top: 25%;
  z-index: 9999 !important;
  position: absolute;
  align-items: center;
  max-width: 50%;
  left: 25%;
  background-color: #fff;
  @media (max-width: 767px) {
    left: 5%;
    top: 5%;
    max-width: 90%;
  }
  .actions {
    display: flex;
    button {
      margin: 0.25em;
    }
  }
  &.message {
    &:after {
      width: 0;
      height: 0;
    }
  }
}

#gdpr-cookie-container-cookie-settings {
  top: 10%;
  min-height: 80%;
  .content {
    width: 100%;

    iframe {
      max-width: 100%;
    }
  }
}

#gdpr-cookie-container-cookie-settings-settings {
  border: none;
}
.amgdprcookie-btn-wrapper {
  button {
    margin: 0.25em;
  }
}
.amgdprcookie-container {
  .amgdprcookie-check, .amgdprcookie-check.active {

    .amgdprcookie-options {
      background-color: $color-primary;
      color: #fff !important;
      padding: 1em 5em 1em 1.5em;
      &:after {
        background-color: #fff !important;
      }
    }
  }
}
.amgdprcookie-toggle-cookie .amgdprcookie-input:checked:not([disabled]) + .amgdprcookie-label {
  background-color: $color-primary !important;
}

.amgdprcookie-content-wrapper {
  font-size: $font-size-small !important;
}
.amgdprcookie-content-wrapper .amgdprcookie-title {
  text-align: left;
}

@media (max-width: $screen-s) {
  #gdpr-cookie-container-cookie-settings {
    padding: 0;
  }
  .amgdprcookie-main-wrapper .amgdprcookie-top {
    display: block !important;
    .amgdprcookie-head, .amgdprcookie-btn-wrapper {
      display: block;
      padding: 0.5em;
      text-align: center;
    }
  }
  #gdpr-cookie-block {
    .actions {
      display: block;
    }
  }
}

*/
