$blockquote__margin     : $spacer--large 0 $spacer !default;
$blockquote__padding    : 0 90px !default;
$blockquote__border-left: 0 !default;

.blockquote {
    margin: $blockquote__margin;
    padding: 30px 0;
    border-left: $blockquote__border-left;
    position: relative;

    &,
    p {
        font-size: $font-size-base;
        font-family: $font-family-serif;
        font-style: italic;
        color: $gray;
        line-height: $font-line-height;
    }

    @include mq ($screen-l) {
        padding: $blockquote__padding;
        font-size: $font-size-medium;
    }

    &:before,
    &:after {
        position: absolute;
        font-size: 70px;
        font-style: normal;
        color: $gray-lighter;
        @include mq ($screen-l) {
            font-size: 160px;
        }
    }
    &:before {
        content: "“";
        left: 0;
        top: -15px;
        @include mq ($screen-l) {
            top: -55px;
        }
    }
    &:after {
        content: "”";
        right: 0;
        bottom: -5px;
        line-height: 0.1;
        @include mq ($screen-l) {
            bottom: -55px;
        }
    }
}

blockquote {
    @extend .blockquote;
}

.cite {

    align-items: center;
    margin-top: $spacer--large;
    font-size: $font-size-base;
    font-family: $font-family-sans-serif-alt;
    font-weight: bold;
    font-style: normal;
    color: $gray-dark;

    @include mq ($screen-l) {
        padding-left: 90px;
        display: flex;
    }

    span {
        font-weight: 300;
        margin-left: $spacer;
    }

    &__logo {
        margin-right: $spacer;
        max-width: 124px;
        margin-bottom: 4px;
        @include mq ($screen-l) {
            margin-bottom: 0;
        }
    }
}
