$catalog-list__margin                 : $spacer--medium 0 0 0 !default;
$catalog-list__sidebar-width\@medium  : auto !default;
$catalog-list__sidebar-margin\@medium : 0 $spacer--medium 0 0 !default;
$catalog-list__sidebar-border         : 0 !default;
$catalog-list__products-width\@medium : 100% !default;
$catalog-list__products-padding       : 0 0 $spacer--large 0 !default;

.catalog-list {
    display: flex;
    flex-direction: column;
    margin: $catalog-list__margin;
    position: relative;

    @include mq($screen-l) {
        display: block;
    }
    @include clearfix;

    &__products {
        order: 1;
        padding: $catalog-list__products-padding;
        margin-top: 0;
        @include mq($screen-m) {
            margin-top: 0;
        }
        @include mq($screen-l) {
            float: right;
            width: $catalog-list__products-width\@medium;
        }
    }

    &__sidebar,
    &__sidebar-additional {
        @include mq($screen-l) {
            float: left;
            width: $catalog-list__sidebar-width\@medium;
        }
    }

    &__sidebar {
        order: 0;
        border-bottom: $catalog-list__sidebar-border;
        @include mq($screen-l) {
            margin: $catalog-list__sidebar-margin\@medium;
            border-bottom: none;
        }
    }

    &__sidebar-additional {
        order: 2;
        @include mq($screen-l) {
            clear: left;
        }
    }
}
