$product-view__action-elements-margin-top\@small: 28px !default;
$product-view__action-elements-margin-top\@large: 0 !default;

$product-view__action-icon-color: $color-primary !default;
$product-view__action-icon-color-hover: #fff !default;
$product-view__action-icon-transition: $transition-base !default;
$product-view__action-icon-compare-padding: 16px 16px !default;
$product-view__action-icon-compare-width: 16px !default;
$product-view__action-icon-compare-height: 16px !default;
$product-view__action-icon-emailfriend-padding: 16px 14px !default;
$product-view__action-icon-emailfriend-height: 16px !default;
$product-view__action-icon-emailfriend-width: 20px !default;
$product-view__action-icon-wishlist-padding: 4px 3px !default;
$product-view__action-icon-wishlist-height: 18px !default;
$product-view__action-icon-wishlist-width: 20px !default;
$product-view__action-item-size: 28px !default;
$product-view__action-item-spacing: $spacer !default;

$product-view__add-review-link-font-size: $font-size-base !default;
$product-view__add-review-link-font-size\@medium: $font-size-small !default;

$product-view__add-to-cart-margin-right: $spacer--medium !default;

$product-view__base-elements-vertical-spacing: $spacer--medium !default;

$product-view__title-font-size: 30px !default;

$product-view__col-base-spacing: $spacer--extra-large !default;
$product-view__col-base-reduce-width: $product-view__col-base-spacing / 2 !default;
$product-view__col-left-base-width: 33% !default;
$product-view__col-left-width: calc(#{$product-view__col-left-base-width} - #{$product-view__col-base-reduce-width}) !default;
$product-view__col-right-base-width: 100% - #{$product-view__col-left-base-width} !default;
$product-view__col-right-width: calc(#{$product-view__col-right-base-width} - #{$product-view__col-base-reduce-width}) !default;

$product-view__description-color: $gray !default;
$product-view__description-margin: 0 0 $spacer--medium !default;
$product-view__description-margin\@medium: 0 0 $spacer--medium !default;
$product-view__description-padding: 0 0 0 $spacer--medium !default;
$product-view__description-padding\@medium: 0 0 0 $spacer--medium !default;
$product-view__description-border-top: 0 !default;
$product-view__description-border-bottom: 0 !default;
$product-view__description-line-height: 1.2 !default;
$product-view__description-title-font-weight: $font-weight-bold !default;
$product-view__description-bundle-margin: 0 !default;
$product-view__description-bundle-padding: $spacer--medium 0 !default;

$product-view__customization-space: $spacer--medium !default;
$product-view__customization-border: 1px solid $gray-light !default;

$product-view__summary-padding: $spacer--medium 0 !default;
$product-view__summary-border: 1px solid $gray-light !default;
$product-view__summary-margin: 0 0 $spacer--large !default;
$product-view__summary-title-font-weight: $font-weight-bold !default;
$product-view__summary-item-space: $spacer--medium !default;
$product-view__summary-price-margin-bottom: $spacer--medium !default;
$product-view__summary-price-font-size: $font-size-large !default;
$product-view__summary-price-font-weight: $font-weight-bold !default;
$product-view__summary-unit-padding-left: 4px !default;
$product-view__summary-unit-font-size: $font-size-base !default;

$product-view__extra-actions-margin-bottom: $spacer--medium !default;
$product-view__extra-actions-margin-bottom\@medium: $spacer !default;
$product-view__extra-actions-margin-bottom\@large: 0 !default;
$product-view__extra-actions-padding: 0 $spacer !default;

$product-view__add-to-cart-margin: 0 0 $product-view__extra-actions-margin-bottom\@medium 0 !default;
$product-view__add-to-cart-margin\@large: 0 $product-view__add-to-cart-margin-right $product-view__extra-actions-margin-bottom\@large 0 !default;
$product-view__add-to-cart-btn-min-width: 150px !default;
$product-view__add-to-cart-btn-padding\@large: 0 !default;

$product-view__update-wishlist-btn-width: auto !default;

$product-view__gallery-bottom-border: none !default;
$product-view__gallery-bottom-border\@medium: none !default;
$product-view__gallery-margin-bottom: $spacer--medium !default;
$product-view__gallery-margin-bottom\@medium: $spacer--large !default;
$product-view__gallery-padding-bottom: $spacer !default;
$product-view__gallery-padding-bottom\@medium: 0 !default;

$product-view__information-padding: 0 20px !default;
$product-view__information-padding\@medium: 0 !default;

$product-view__main-actions-margin-bottom: 23px !default;

$product-view__main-details-bottom-spacing: $spacer--medium !default;

$product-view__order-gallery: 1 !default;
$product-view__order-main-details: 2 !default;
$product-view__order-secondary-details: 3 !default;

$product-view__price-bottom-margin: $spacer--medium !default;
$product-view__price-bottom-margin\@medium: $spacer--medium !default;
$product-view__price-color: $gray-darker !default;
$product-view__price-color--old: $gray !default;
$product-view__price-color--special: $gray-darker !default;
$product-view__price-font-size: $font-size-large !default;
$product-view__price-font-weight: $font-weight-bold !default;
$product-view__price-label-font-weight: $font-weight-normal !default;

$product-view__qty-margin-right\@small: $spacer--medium !default;

$product-view__rating-link-color: $gray-dark !default;
$product-view__rating-width: 100% !default;
$product-view__rating-width\@medium: 45% !default;
$product-view__rating-top: 0 !default;
$product-view__rating-right: 0 !default;
$product-view__rating-margin: 0 0 14px 0 !default;
$product-view__rating-margin\@medium: 0 !default;
$product-view__rating-text-color: $gray-dark !default;
$product-view__rating-value-font-size: $font-size-base !default;

$product-view__sku-color: $gray !default;
$product-view__sku-margin-bottom: $spacer--medium !default;
$product-view__sku-margin-bottom\@medium: $spacer !default;
$product-view__sku-padding-right: 55% !default;

$product-view__stock-color: $gray-dark !default;
$product-view__stock-color--out-of-stock: $red !default;
$product-view__stock-color--alert: $red !default;
$product-view__stock-font-weight\@medium: $font-weight-bold !default;
$product-view__stock-text-transform\@medium: uppercase !default;

$product-view__swatch-margin-bottom: $spacer--medium !default;
$product-view__swatch-margin-right: $spacer !default;
$product-view__swatch-option-title-font-size: 17px !default;
$product-view__swatch-option-title-font-weight: $font-weight-bold !default;

$product-view__slider-name-font-size: $font-size-medium !default;
$product-view__slider-margin-bottom: $spacer--extra-large !default;
$product-view__slider-margin-bottom\@medium: 72px !default;
$product-view__slider-margin-bottom--last\@medium: 56px !default;
$product-view__slider-margin-bottom--last\@large: 64px !default;
$product-view__slider-heading-font-size: 24px !default;
$product-view__slider-heading-font-size-weight: $font-weight-bold;
$product-view__slider-heading-text-transform: uppercase !default;

$product-view__tabs-margin: 0 (-$spacer) 14px (-$spacer) !default;
$product-view__tabs-margin\@medium: 0 0 72px 0 !default;

$product-view__additional-content-margin-bottom\@medium: 72px !default;

$product-view__review-item-padding: $spacer--large 0 !default;
$product-view__review-item-border: 1px solid $gray-light !default;
$product-view__review-details-margin-bottom: $spacer--medium !default;
$product-view__review-author-margin-bottom: $spacer !default;
$product-view__review-date-margin-bottom: $spacer !default;
$product-view__review-date-font-size: $font-size-small !default;
$product-view__review-date-color: $gray-light !default;
$product-view__review-content-title-font-weight: $font-weight-bold !default;
$product-view__review-product-name-font-weight: $font-weight-bold !default;

$product-view__wrapper-margin: $spacer--medium 0 $spacer--large 0 !default;
$product-view__wrapper-margin\@medium: 0 0 40px 0 !default;

$product-view__grouped-qty-margin: 0 !default;
$product-view__grouped-qty-max-width: 100px !default;
$product-view__grouped-qty-width: 120px !default;
$product-view__grouped-product-name-font-weight: $font-weight-bold !default;
$product-view__grouped-product-name-margin: 0 $spacer--medium 0 0 !default;
$product-view__grouped-product-name-margin\@medium: 0 0 $spacer !default;

.product-view {
    display: flex;
    flex-direction: column;

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: $product-view__wrapper-margin;

        @include mq($screen-m) {
            display: block;
            margin: $product-view__wrapper-margin\@medium;
        }
    }

    &__form {
        width: 100%;
    }

    &__image {
        margin: 0 auto;
        width: 80%;
    }

    &__gallery {
        order: $product-view__order-gallery;
        width: 100%;
        border-bottom: $product-view__gallery-bottom-border;
        padding-bottom: $product-view__gallery-padding-bottom;
        margin-bottom: $product-view__gallery-margin-bottom;
        position: relative;

        @include mq($screen-l) {
            border-bottom: $product-view__gallery-bottom-border\@medium;
            padding-bottom: $product-view__gallery-padding-bottom\@medium;
            margin-bottom: $product-view__gallery-margin-bottom\@medium;
            float: left;
            width: $product-view__col-left-width;
        }


        &-actions {
            background: rgba(#000, 0.8);
            height: 50%;
            position: absolute;
            bottom: 0;
            width: 80%;
            display: none;
            margin-left: 10%;
            padding: 10px 0 40px;
            flex-direction: column;
            justify-content: space-evenly;
            align-content: center;
            z-index: 10;
            @include mq($screen-xxl) {
                height: 45%;
            }

            .product-view__gallery:hover & {
                display: flex;
            }

            &-btn {
                background: transparent;
                border: 1px solid #fff;
                margin: 0 auto;
                width: 100%;
                max-width: 180px;
                transition: none;

                &:hover {
                    color: #000;
                    background: #fff;

                    .icon {
                        fill: #000;
                    }
                }

                &-icon {
                    fill: #fff;
                    height: 22px;
                    width: 22px;
                    display: inline-block;
                    order: -1;
                    margin-right: $spacer;
                    transition: none;
                }
            }
        }


    }

    &__main-details {
        width: 100%;
        order: $product-view__order-main-details;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        margin-bottom: $product-view__main-details-bottom-spacing;
        @include mq($screen-l) {
            display: block;
            position: relative;
            width: $product-view__col-right-width;
            float: right;
        }
    }

    &__secondary-details {
        display: flex;
        flex-wrap: wrap;
        order: $product-view__order-secondary-details;
        width: 100%;
        min-height: 450px;

        @include mq($screen-l) {
            display: block;
            width: $product-view__col-right-width;
            float: right;
        }
    }

    &__title {
        width: 100%;
        margin-bottom: $spacer;
        font-size: 30px;
        justify-content: left;

        @include mq($screen-l) {
            font-size: $product-view__title-font-size;
        }

        .heading--page {
            margin: 0;
            font-size: 30px;
            text-transform: inherit;
            @include mq($screen-l) {
                font-size: $product-view__title-font-size;
            }
        }
    }

    &__price {
        width: 50%;
        font-size: $product-view__price-font-size;
        font-weight: $product-view__price-font-weight;
        margin-bottom: $product-view__price-bottom-margin;
        @include clearfix;

        @include mq($screen-m) {
            width: 100%;
            padding-right: 55%;
            margin-bottom: $product-view__price-bottom-margin\@medium;
        }

        .price-box {
            display: flex;
            float: left;
        }

        .price-label {
            font-weight: $product-view__price-label-font-weight;
        }

        .price__value {
            &--old {
                color: $product-view__price-color--old;
            }

            &--special {
                color: $product-view__price-color--special;
            }

            &.sly-old-price {
                display: none;
            }
        }
    }

    &__description {
        margin: $product-view__description-margin;
        width: 100%;
        padding: $product-view__description-padding;
        border-top: $product-view__description-border-top;
        border-bottom: $product-view__description-border-bottom;
        border-left: 1px solid $gray-lighter;

        &,
        p {
            line-height: $product-view__description-line-height;
            font-size: $font-size-small;
            color: $product-view__description-color;
        }

        p {
            margin: 0;
            &:last-of-type {
                display: inline;
            }
        }

        @include mq($screen-m) {
            padding: $product-view__description-padding\@medium;
            margin: $product-view__description-margin\@medium;
        }
    }

    &__description-link {
        color: $color-primary;
        text-decoration: underline;
        &.js-anchor {
            cursor: pointer;
        }
    }

    &__description-bundle {
        order: 0;
        margin: $product-view__description-bundle-margin;
        padding: $product-view__description-bundle-padding;
    }

    &__description-title {
        font-weight: $product-view__description-title-font-weight;
        line-height: $product-view__description-line-height;
        margin-bottom: $spacer;
        font-size: $font-size-small;
        color: $product-view__description-color;
        font-family: $font-family-sans-serif;
    }

    &__customization {
        width: 100%;
        margin-bottom: $product-view__customization-space;
        padding-bottom: $product-view__customization-space;
        border-bottom: $product-view__customization-border;
    }

    &__summary {
        width: 100%;
        padding: $product-view__summary-padding;
        margin: $product-view__summary-margin;
        border-bottom: $product-view__summary-border;
        border-top: $product-view__summary-border;

        @include mq($screen-m) {
            border-bottom: 0;
            margin: 0;
        }
    }

    &__summary-price {
        display: flex;
        align-items: center;
        margin-bottom: $product-view__summary-price-margin-bottom;
        font-weight: $product-view__summary-price-font-weight;
        font-size: $product-view__summary-price-font-size;
    }

    &__summary-unit {
        padding-left: $product-view__summary-unit-padding-left;
        font-size: $product-view__summary-unit-font-size;
    }

    &__summary-title {
        font-weight: $product-view__summary-title-font-weight;
        margin-bottom: $product-view__summary-item-space;
    }

    &__summary-list {
        padding-left: $product-view__summary-item-space;
    }

    &__summary-item-title {
        display: block;
        font-weight: $product-view__summary-title-font-weight;
    }

    &__options {
        width: 100%;

        .swatch {
            margin-bottom: $product-view__swatch-margin-bottom;
            display: block;

            &__option-container {
                margin-right: $product-view__swatch-margin-right;
                &.disabled {
                    background: #c8c8c8;
                }
            }
        }
    }

    &__swatch-option-title {
        font-size: $product-view__swatch-option-title-font-size;
        font-weight: $product-view__swatch-option-title-font-weight;
        display: block;
    }

    &__tabs {
        margin: $product-view__tabs-margin;

        @include mq($screen-m) {
            margin: $product-view__tabs-margin\@medium;
        }
    }

    &__tab {
        .tab__content {
            line-height: $product-view__description-line-height;
        }
    }

    &__information {
        order: 3;
        width: 100%;
        padding: $product-view__information-padding;

        @include mq($screen-m) {
            padding: $product-view__information-padding\@medium;
        }
    }

    &__stock-status {
        color: $product-view__stock-color;
        width: 50%;
        text-align: right;
        margin-bottom: $product-view__price-bottom-margin;

        @include mq($screen-m) {
            margin-bottom: 0;
            float: right;
            font-weight: $product-view__stock-font-weight\@medium;
            text-transform: $product-view__stock-text-transform\@medium;
            position: absolute;
            right: 0;
            width: 45%;
            bottom: 0;
            text-align: right;
        }

        &--out-of-stock {
            color: $product-view__stock-color--out-of-stock;
        }

        &--alert {
            color: $product-view__stock-color--alert;
        }
    }

    &__rating {
        color: $product-view__rating-text-color;
        width: $product-view__rating-width;
        display: flex;
        margin: $product-view__rating-margin;
        justify-content: space-between;

        @include mq($screen-m) {
            position: absolute;
            top: $product-view__rating-top;
            right: $product-view__rating-right;
            width: $product-view__rating-width\@medium;
            justify-content: flex-end;
            margin: $product-view__rating-margin\@medium;
        }
    }

    &__rating-rates {
        width: 50%;
        display: flex;
        flex: 1 0 auto;
        align-items: center;
        font-size: $product-view__rating-value-font-size;

        @include mq($screen-m) {
            width: auto;
            justify-content: flex-end;
        }
    }

    &__rating-actions {
        width: 50%;
        flex: 1 0 auto;
        text-align: right;

        @include mq($screen-m) {
            width: auto;
        }
    }

    &__main-actions {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: $product-view__main-actions-margin-bottom;
        flex-wrap: wrap;

        @include mq($screen-l) {
            flex-wrap: nowrap;
            align-items: flex-end;
        }
    }

    &__actions {
        clear: both;
    }

    &__extra-actions {
        display: inline-block;
        padding: $product-view__extra-actions-padding;
        margin-left: $spacer--medium;

        @include mq($screen-l) {
            margin-bottom: $product-view__extra-actions-margin-bottom\@large;
            padding: 0;
        }
    }

    &__qty {
        flex-basis: 100%;
        margin-bottom: $product-view__extra-actions-margin-bottom;

        @include mq($screen-s) {
            flex: 0 0 auto;
            margin-right: $product-view__qty-margin-right\@small;
        }

        @include mq($screen-l) {
            margin-bottom: $product-view__extra-actions-margin-bottom\@large;
        }
    }

    &__grouped-qty {
        margin: $product-view__grouped-qty-margin;
        max-width: $product-view__grouped-qty-max-width;
    }

    &__grouped-table {
        table-layout: fixed;
    }

    &__grouped-col-qty {
        width: $product-view__grouped-qty-width;
    }

    &__grouped-product-name {
        display: block;
        font-weight: $product-view__grouped-product-name-font-weight;
        margin: $product-view__grouped-product-name-margin;

        @include mq($screen-m) {
            margin: $product-view__grouped-product-name-margin\@medium;
        }
    }

    &__action-elements {
        flex-flow: column;
        flex: 1 0 auto;
        justify-content: space-between;
        display: flex;

        @include mq($screen-s) {
            margin-top: $product-view__action-elements-margin-top\@small;
        }

        @include mq($screen-l) {
            margin-top: $product-view__action-elements-margin-top\@large;
            flex-flow: row nowrap;
            justify-content: flex-start;
        }
    }

    &__add-to-cart-btn,
    &__update-cart-btn {
        flex: 1 0 auto;
        width: 100%;
        min-width: $product-view__add-to-cart-btn-min-width;
        background: $pink;

        &:hover,
        &:focus {
            background: darken($pink, 10%);
        }

        @include mq($screen-l) {
            padding: $product-view__add-to-cart-btn-padding\@large;
        }
    }

    &__add-to-cart-icon {
        order: -1;
        margin-right: 10px;
        width: 16px;
        height: 16px;
    }

    &__add-to-cart,
    &__update-cart {
        width: 100%;
        margin: $product-view__add-to-cart-margin;

        @include mq($screen-l) {
            width: auto;
            flex: 0 0 auto;
            margin: $product-view__add-to-cart-margin\@large;
        }
    }

    &__update-wishlist {
        width: $product-view__update-wishlist-btn-width;
    }

    &__action {
        margin-right: $product-view__action-item-spacing;
        width: $product-view__action-item-size;
        height: $product-view__action-item-size;
        padding: 0;
        color: $color-primary;

        &:hover,
        &.active {
            cursor: pointer;
            background: $color-primary;

            .icon {
                fill: $product-view__action-icon-color-hover;
            }
        }

        &:last-child {
            margin-right: 0;
        }

        &--button {
            box-shadow: none;
            background: transparent;
            border: 1px solid $product-view__action-icon-color;
            border-radius: 50%;
        }

        .icon {
            fill: $product-view__action-icon-color;
            transition: $product-view__action-icon-transition;
        }

        &--wishlist {
            padding: $product-view__action-icon-wishlist-padding;
            position: relative;
            top: -3px;

            .icon {
                width: $product-view__action-icon-wishlist-width;
                height: $product-view__action-icon-wishlist-height;
            }
        }

        &--emailfriend {
            padding: $product-view__action-icon-emailfriend-padding;

            .icon {
                width: $product-view__action-icon-emailfriend-width;
                height: $product-view__action-icon-emailfriend-height;
            }
        }

        &--compare {
            padding: $product-view__action-icon-compare-padding;

            .icon {
                width: $product-view__action-icon-compare-width;
                height: $product-view__action-icon-compare-height;
            }
        }
    }


    &__link {
        &--rating {
            text-decoration: none;
            color: $product-view__rating-link-color;

            &:hover {
                cursor: pointer;
            }
        }

        &--add-review {
            text-decoration: none;
            font-size: $product-view__add-review-link-font-size;

            @include mq($screen-m) {
                font-size: $product-view__add-review-link-font-size\@medium;
            }
        }
    }

    &__sku {
        width: 100%;
        color: $product-view__sku-color;
        margin-bottom: $product-view__sku-margin-bottom;

        @include mq($screen-m) {
            display: block;
            margin-bottom: $product-view__sku-margin-bottom\@medium;
            padding-right: $product-view__sku-padding-right;
        }
    }

    &__review {
        @include mq($screen-m) {
            width: 430px;
        }
    }

    &__review-item {
        width: 100%;
        padding: $product-view__review-item-padding;
        border-top: $product-view__review-item-border;

        &:first-child {
            border-top: 0;
        }

        &:last-child {
            border-bottom: $product-view__review-item-border;
        }
    }

    &__review-details {
        margin-bottom: $product-view__review-details-margin-bottom;

        @include mq($screen-m) {
            margin-bottom: 0;
        }
    }

    &__review-author {
        margin-bottom: $product-view__review-author-margin-bottom;
    }

    &__review-date {
        margin-bottom: $product-view__review-date-margin-bottom;
        font-size: $product-view__review-date-font-size;
        color: $product-view__review-date-color;
    }

    &__review-content-title {
        font-weight: $product-view__review-content-title-font-weight;
    }

    &__review-title {
        margin-bottom: 0;
        text-transform: uppercase;
    }

    &__review-product-name {
        font-weight: $product-view__review-product-name-font-weight;
    }

    &__slider {
        margin-bottom: $product-view__slider-margin-bottom;

        @include mq($screen-m) {
            margin-bottom: $product-view__slider-margin-bottom\@medium;
        }

        &:last-child {
            @include mq($screen-m) {
                margin-bottom: $product-view__slider-margin-bottom--last\@medium;
            }

            @include mq($screen-l) {
                margin-bottom: $product-view__slider-margin-bottom--last\@large;
            }
        }
    }

    &__additional-content {
        display: none;

        @include mq($screen-m) {
            display: block;
            margin-bottom: $product-view__additional-content-margin-bottom\@medium;

        }
    }

    &__grid-item {
        .catalog-grid-item__name {
            font-size: $product-view__slider-name-font-size;
            text-transform: initial;
        }
    }


    &__slide-item {
        padding-right: 42px;
    }

    &__offers {
        clear: both;
    }

    &__benefits {
        padding-bottom: 32px;
        border-bottom: 1px solid #c9c9c9;
        margin-bottom: $spacer--extra-large;
    }

    &__details {
        @include fix-gutter(42px);
        margin-bottom: $spacer--extra-large;
    }

    &__details-text {

        &,
        p {
            color: $gray;
            font-size: $font-size-small;
            line-height: $font-line-height;
        }


        padding-left: $spacer--medium;
        border-left: 1px solid $gray-light;
        margin-top: $spacer--large;
        margin-bottom: $spacer--extra-large;
        @include mq($screen-l, $screen-xl) {
            margin-bottom: 0;
        }
        @include mq($screen-xxl) {
            margin-bottom: 0;
        }
    }

    &__frequency {
        font-size: $font-size-small;
        font-family: $font-family-sans-serif-alt;
        align-items: center;
        color: #455966;

        @include mq($screen-l) {
            display: flex;
        }

        &-next {
            margin-top: $spacer--small;
            display: block;
            @include mq($screen-l) {
                display: inline-block;
                margin-left: $spacer--medium;
                margin-top: 0;
            }
        }

        &-icon {
            margin-right: $spacer--small;
            height: 18px;
            width: 18px;
            display: inline-block;
            position: relative;
            top: $spacer--small;
            fill: #455966;
            @include mq($screen-l) {
                margin-right: $spacer;
                top: 0;
            }
        }


    }


}

.related {
    &__title {
        color: $darkblue;
        text-align: center;
        margin-bottom: 50px;

        &:after {
            content: "";
            width: 75px;
            height: 3px;
            background: $color-primary;
            display: block;
            margin: 30px auto 0;
        }
    }
}

.mgk-adcpopup-block {
    .price__value.price__value--percentage {
        background-color: $pink;
        color: #fff;
    }

    .details-qty.qty {
        display: none;
        @include mq($screen-xl) {
            display: block;
        }
        float: left;
        margin-right: 15px;
    }

    .product-item-pricing {
        float: left;
    }

    .is-product-gift {
        margin-top: 15px;
        margin-bottom: 15px;
        line-height: 36px;
        clear: both;
        &:before {
            background: url(../images/gift.svg) center no-repeat;
            width: 15px;
            height: 20px;
            background-size: contain;
            background-position-x: 0;
            content: '';
            display: block;
            float: left;
            margin-top: 5px;
        }
        label {
            font-size: 14px;
            font-weight: normal;
            font-family: Roboto, sans-serif;
        }
    }

    .summary-item .values .price {
        font-weight: 900;
        font-size: 22px;
        font-family: Urbani, sans-serif;
    }

    .cart-qty-label {
        padding-top: 0;
        text-align: center;
    }

    .right {
        float: right;
    }
}

.product-modal-content {
    position: relative;
    .close {
        position: absolute;
        top: 5px;
        right: 12px;
        width: 20px;
        height: 20px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        border-radius: 30px;
        background-color: $darkblue;
        background-image: url(../images/close.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 50%;
        font-size: 0;
        cursor: pointer;
    }
    .content {
        font-weight: normal;
        color: $darkblue;
        font-family: Roboto, sans-serif;
        line-height: 1.3;
        margin-top: 10px;
        padding: 10px;
        background: #e5f4f7;
        margin-bottom: 15px;
        padding-right: 35px;
    }
}

.product-modal-background {
    background-color: rgba(24, 88, 124, 0.9);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 999999;
}
#product-details-anchor { //sass-lint:disable-line no-ids
    visibility: hidden;
    top: -180px;
    display: block;
    position: relative;
}
.swatch-option-tooltip {
    display: none !important; //sass-lint:disable-line no-important
}

