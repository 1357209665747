$dashborad-form-margin: 30px !default;

.dashboard-form {
    &__title {
        margin-bottom: $dashborad-form-margin;
        padding-bottom: 10px;
        font-size: $heading-font-size--third-level;
        .title-complement-notice {
            display: block;
            font-size: 14px;
            font-weight: normal;
            font-family: $font-family-sans-serif;
            padding-top: 1em;
        }
    }

    &__title-form {
        font-size: $font-size-extra-large;
    }

    &__divider,
    &__select {
        margin-bottom: $dashborad-form-margin;
    }

    &--hidden {
        display: none;
    }

    &__button {
        min-width: 150px;
    }
}
