// Colors
$white                      : #fff !default;
$black                      : #000 !default;
$red                        : #e85b55 !default;
$orange                     : #fab216 !default;
$yellow                     : #ffd500 !default;
$green                      : #31e37d !default;
$blue                       : #2962ff !default;
$teal                       : #5bc0de !default;
$pink                       : #ff5b77 !default;
$purple                     : #613d7c !default;

// Grayscale colors
$gray-darkest               : #333333 !default;
$gray-darker                : #393243 !default;
$gray-dark                  : #4c4555 !default;
$gray                       : #73739c !default;
$gray-light                 : #c2c1df !default;
$gray-lighter               : #c9c9c9 !default;
$gray-lightest              : #f7f7f9 !default;

// Semantic color scheme
$color-primary              : $green !default;
$color-secondary            : $gray-darker !default;
$color-success              : $green !default;
$color-info                 : $teal !default;
$color-warning              : $orange !default;
$color-danger               : $red !default;
$color-inverse              : $gray-darker !default;

// Background
$bg-color-base              : $white !default;
$bg-color-dark              : $black !default;

$color-overlay: rgba(24, 88, 124, 0.9);

// Border
$border-color-base          : $gray-darker !default;
$border-width-base          : 1px !default;
$border-style-base          : solid !default;

$border-base                : $border-width-base $border-style-base $border-color-base !default;

// Outline
$outline-base               : none !default;

// Spacers
$spacer                     : 8px !default;
$spacer--medium             : 16px !default;
$spacer--large              : 32px !default;
$spacer--extra-large        : 48px !default;

// Typography
$font-family-sans-serif     : "Montserrat", sans-serif !default;
$font-family-monospace      : Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;

$font-family-base           : $font-family-sans-serif !default;

$font-color-base            : $gray-darkest !default;
$font-weight-normal         : normal !default;
$font-weight-bold           : bold !default;
$font-weight-base           : $font-weight-normal !default;
$font-line-height           : 1.5 !default;

$font-size-extra-large      : 22px !default;
$font-size-large            : 18px !default;
$font-size-medium           : 16px !default;
$font-size-base             : 14px !default;
$font-size-small            : 12px !default;
$font-size-extra-small      : 10px !default;

// Layout
$max-content-width          : 1328px !default;

// Layout - breakpoints
$screen-xs                  : 320px !default;
$screen-s                   : 480px !default;
$screen-m                   : 768px !default;
$screen-l                   : 992px !default;
$screen-xl                  : 1200px !default;

$screen-xml                  : 1480px !default;

$screen-xxl                 : 1600px !default;

// Components
$border-radius              : 4px !default;
$form-elements-radius       : 32px !default;
$form-elements-radius--small: 10px !default;
$form-elements-border-color : $gray-light !default;
$transition-base            : all 0.3s ease-in-out !default;
$transition-fade            : opacity 0.25s linear !default;

// EDIGROUP THEME VARIABLES OVERRIDE
// OVERRIDE VARIABLES HERE ONLY WHEN YOU DONT NEED TO ALTER COMPONENT
// CURRENTLY WIP

// Colors
$blue               : #18587c;
$teal               : #0095aa;
$pink               : #f34350;
$green              : #19c29c;
$darkblue           : #183446;
$color-primary      : $teal;
$color-secondary    : $blue;
$color-third        : $pink;

$gray               : #777;
$gray-light         : #999;
$gray-lightest      : #f8f8f8;

// Grid
$screen-xxl: 1680px;
$max-content-width-xl: 1180px;
$max-content-width: 1660px;


// Link globals
$link__color--invert: #fff;
$link__color-hover--invert: $gray-lightest;

// List element
$list__padding--horizontal         : 0;
$list__padding--horizontal\@medium : 0;


// typography globals
$font-family-sans-serif     : Roboto, sans-serif;
$font-family-sans-serif-alt     : Urbani, sans-serif;
$font-family-condensed     : "Roboto Condensed", sans-serif;
$font-family-serif     : "Libre Baskerville", serif;
$font-family-base           : $font-family-sans-serif;

$font-size-extra-large      : 22px;
$font-size-large            : 20px;
$font-size-medium           : 18px;
$font-size-base             : 16px;
$font-size-small            : 14px;
$font-size-extra-small      : 12px;

$headings-color: $darkblue;
$headings-font-weight: 900;
$headings-font-family: $font-family-sans-serif-alt;
$heading-font-size--page         : 30px;
$heading-font-size--first-level  : 30px;
$heading-font-size--second-level : 22px;
$heading-font-size--third-level : 22px;
$headings-line-height: 1;
$link__font-family: inherit;
$headings-text-transform--page: none;

$paragraph-color: $darkblue;

// FORM elements
$form-elements-border-color: $darkblue;
$label-font-family: $font-family-sans-serif-alt;
$label-color: $darkblue;
$label-font-weight: bold;
$label-margin: 0 0 $spacer $spacer;

$input__margin-bottom: 25px;
$select__margin-bottom: 25px;
$input__placeholder-color: $gray-lighter;

// minicart content modules
$minicart-content__top-bar-height: 0;

// badge element
$badge__font-family          : $font-family-sans-serif-alt;
$badge__font-weight          : $font-weight-normal;
$badge__background           : rgba($color-primary, 0.9);
$badge__color                : #fff;
$badge__color--discount      : #fff;
$badge__background--discount : rgba($pink, 0.9);

// spacer
$spacer--small: 4px;

// messages
$message__border-radius: 0;
$message__font-size: $font-size-base;
$message__padding: $spacer--medium $spacer--extra-large;
$message__margin-base        : $spacer--medium 0 $spacer--medium 0;
$message__margin-base\@medium: 0 0 $spacer--medium 0;
$message__border--success    : 1px solid rgba($color-primary, 0.2);
$message__border             : 1px solid rgba($darkblue, 0.2);
$message__border--error      : 1px solid rgba($red, 0.2);
$message__background         : rgba($darkblue, 0.05);
$message__color--success: $color-primary;
$message__icon-spacer: 18px;

// shadow
$box-shadow__default: 0 3px 10px 0 rgba($darkblue, 0.3);
$box-shadow__grid: 5px 0 10px 0 rgba($darkblue, 0.2);
$box-shadow__selection: 5px 0 10px 0 rgba($darkblue, 0.15);
$box-shadow__offer: 0 5px 10px 0 rgba($darkblue, 0.2);
$box-shadow__form: 0 5px 10px 0 rgba($darkblue, 0.2);
$box-shadow__input: 0 2px 8px rgba($darkblue, 0.2);
$box-shadow__modal: 0 5px 10px 0 rgba($darkblue, 0.2);
$box-shadow__dashboard: 1px 4px 10px 0 rgba($darkblue, 0.2);
$box-shadow__side-menu: 1px 7px 10px 0 rgba($darkblue, 0.11);
$text-shadow__title: 0 3px 7px rgba($darkblue, 0.35);


// image element
$image__wrapper-background-color: transparent;

// popup
$popup__z-index: 101;

// radio button
$radio__size: 20px;
$radio__dot-size: 10px;
$radio__label-font-size: $font-size-base;
