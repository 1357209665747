.issues-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 42px;

    @include mq($screen-l) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
    @include mq($screen-xl) {
        grid-template-columns: 1fr 1fr 1fr;
    }
    @include mq($screen-xxl) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
}
