.page-product-configurable {

    .product-view__gallery {
        @include mq($screen-m) {
            max-height: 480px !important;
        }
    }

    .product-view__secondary-details {
        .swatch {
            &.student_offer {
                @include mq($screen-m) {
                    margin-top: 80px !important;
                }
            }
        }
    }

    .swatch-opt > .swatch.offer_duration  {
        visibility: hidden;
    }

    .swatch-opt > .swatch.support{
        visibility: hidden;
    }
}




