$catalog-grid-item__base-spacing              : $spacer !default;
$catalog-grid-item__padding                   : 0 !default;
$catalog-grid-item__border                    : 1px solid $gray-lightest !default;
$catalog-grid-item__font-size                 : $font-size-base !default;
$catalog-grid-item__font-size\@large          : $font-size-medium !default;
$catalog-grid-item__line-height               : 1.5 !default;
$catalog-grid-item__font-weight               : $font-weight-bold !default;

$catalog-grid-item__badge-top-position        : $spacer--medium !default;
$catalog-grid-item__badge-left-position       : 0 !default;
$catalog-grid-item__badge-z-index             : 100 !default;
$catalog-grid-item__badge-padding             : 0 !default;
$catalog-grid-item__badge-font-size           : $font-size-small !default;
$catalog-grid-item__badge-border-radius       : 0 !default;

$catalog-grid-item__image-margin              : 20px auto 0 !default;
$catalog-grid-item__image-width               : 100% !default;
$catalog-grid-item__image-width--lazyload     : 100% !default;
$catalog-grid-item__image-height              : auto !default;

$catalog-grid-item__loader-margin-top         : 30% !default;

$catalog-grid-item__link-overflow             : hidden !default;
$catalog-grid-item__link-overflow--visible    : visible !default;
$catalog-grid-item__link-white-space          : nowrap !default;
$catalog-grid-item__link-white-space--visible : normal !default;
$catalog-grid-item__link-text-overflow        : ellipsis !default;

$catalog-grid-item__content-bg-color          : $white !default;
$catalog-grid-item__content-padding           : $spacer !default;
$catalog-grid-item__content-width             : 100% !default;
$catalog-grid-item__content-height            : 67px !default;
$catalog-grid-item__content-height\@large     : 73px !default;
$catalog-grid-item__content-translateY        : translateY(-$catalog-grid-item__content-height) !default;
$catalog-grid-item__content-translateY\@large : translateY(-$catalog-grid-item__content-height\@large) !default;
$catalog-grid-item__content-translateY--active: translateY(-100%) !default;

$catalog-grid-item__name-color                : $font-color-base !default;
$catalog-grid-item__name-font-weight          : $font-weight-bold !default;
$catalog-grid-item__name-margin-botttom       : $spacer--medium !default;
$catalog-grid-item__name-padding--bigger      : $spacer--medium 0 !default;

$catalog-grid-item__rating-margin-bottom      : $spacer !default;
$catalog-grid-item__price-margin-bottom       : $spacer !default;
$catalog-grid-item__price-label-font-weight   : $font-weight-normal !default;

$catalog-grid-item__primary-padding           : 0 $spacer !default;
$catalog-gird-item__primary-form-margin       : 0 !default;
$catalog-grid-item__primary-icon-fill         : $gray-darker !default;

$catalog-grid-item__action-size               : 26px !default;
$catalog-grid-item__action-padding            : 0 !default;
$catalog-grid-item__action-background         : transparent !default;
$catalog-grid-item__action-border             : 1px solid $color-primary !default;
$catalog-grid-item__action-cursor             : pointer !default;
$catalog-grid-item__action-icon-size          : 16px !default;
$catalog-grid-item__action-icon-transition    : none !default;
$catalog-grid-item__action-icon-fill          : $color-primary !default;
$catalog-grid-item__action-icon-fill-hover    : #fff !default;

$catalog-grid-item__IE-margin                 : 0 $spacer $spacer--medium $spacer !default;
$catalog-grid-item__IE-width                  : calc(50% - #{$spacer--medium} - 1px) !default;
$catalog-grid-item__IE-width\@medium          : calc(100% / 3 - #{$spacer--medium} - 1px) !default;

.catalog-grid-item {
    position: relative;
    display: block;
    padding: $catalog-grid-item__padding;
    overflow: hidden;
    box-shadow: $box-shadow__grid;

    @include mq($screen-m) {
        &:hover {
            .catalog-grid-item__overlay {
                display: block;
            }
        }
    }
    &--banner {
        background: $pink;
        display: flex;
        overflow: visible;
        @include mq(0, $screen-s) {
            grid-column: span 2;
            /*min-height: 294px;*/
            /* max-height: 204px; */
            .grid-banner__list {
                max-height: 0;
                transition: 0.3s linear;
                opacity: 0;
            }
            .grid-banner__image {
                max-width: 25%;
            }

            .grid-banner__title {
                margin: 16px 0;
            }
            &.active {
                .grid-banner__list {
                    max-height: 300px;
                    opacity: 1;
                }
                .grid-banner__title {
                    margin: 16px 0 32px;
                }
            }
        }

    }

    @include isIE() {
        margin: $catalog-grid-item__IE-margin;
        width: $catalog-grid-item__IE-width;

        @include mq($screen-m) {
            width: $catalog-grid-item__IE-width\@medium;
        }
        @include mq($screen-l) {
            width: calc(100% / 4 - 16px - 1px);
        }
        @include mq(1700px) {
            margin: 0 15px 30px 15px;
            width: calc(100% / 5 - 30px - 1px);
        }
    }


    &__badge {
        position: absolute;
        bottom: 0;
        left: $catalog-grid-item__badge-left-position;
        z-index: $catalog-grid-item__badge-z-index;
        border-radius: $catalog-grid-item__badge-border-radius;
        padding: $catalog-grid-item__badge-padding;
        width: 65%;
        height: 30px;
        text-transform: uppercase;
        font-size:  10px;
        line-height: 1;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include mq($screen-s) {
            font-size: $catalog-grid-item__badge-font-size;
        }

        &--fluid {
            width: 100%;
        }


        &--discount {
            right: 0;
            left: auto;
            width: 35%;
            text-transform: initial;
            font-weight: 900;
            font-size: 16px;

            @include mq($screen-s) {
                font-size: $font-size-large;
            }
        }

        small {
            font-size: 11px;
            font-family: $font-family-condensed;
            letter-spacing: 0.5px;
            @include mq($screen-s) {
                margin-bottom: -2px;
            }
        }
    }

    &__link {
        display: block;

        &--name {
            line-height: 1.1;
            display: block;
            color: $darkblue;
            font-weight: 600;
            @include mq($screen-m) {
                max-width: 80%;
                margin: 0 auto;
            }
        }
    }

    &__image-wrapper {
        position: relative;

        .image {
            margin: $catalog-grid-item__image-margin;
            width: $catalog-grid-item__image-width;
            max-width : 204px;
            height: $catalog-grid-item__image-height;
        }

        .lazyload,
        .lazyloading {
            width: $catalog-grid-item__image-width--lazyload;
        }

        .loader__circle {
            margin-top: $catalog-grid-item__loader-margin-top;
        }
    }

    &__name {
        font-weight: bold;
        font-size: 14px;
        line-height: $catalog-grid-item__line-height;
        margin-bottom: $catalog-grid-item__name-margin-botttom;
        text-align: center;
        font-family: $font-family-sans-serif-alt;
        @include mq($screen-m) {
            position: relative;
            font-size: 17px;
        }


        @include mq($screen-xxl) {
            font-size: $catalog-grid-item__font-size\@large;
        }

        &--bigger {
            padding: $catalog-grid-item__name-padding--bigger;
        }
    }

    &__rating {
        margin-bottom: $catalog-grid-item__rating-margin-bottom;
    }

    &__price {
        margin: $spacer 0;
        padding: $catalog-grid-item__padding;
        font-size: $catalog-grid-item__font-size;
        line-height: $catalog-grid-item__line-height;
        font-weight: $catalog-grid-item__font-weight;
        white-space: nowrap;

        @include mq($screen-s) {
            margin: $spacer--medium 0;
        }

        @include mq($screen-xxl) {
            font-size: $catalog-grid-item__font-size\@large;
            min-height: 86px;
        }

        .price-label {
            font-weight: $catalog-grid-item__price-label-font-weight;
        }
    }

    &__details {
        padding: $catalog-grid-item__content-padding;
        background: $catalog-grid-item__content-bg-color;

        @include mq($screen-m) {
            width: $catalog-grid-item__content-width;
            border-top: 0;
        }


    }

    &__options {
        .swatch {
            margin: $catalog-grid-item__base-spacing 0;
        }
    }

    &__actions {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
    }

    &__primary-form {
        margin: $catalog-gird-item__primary-form-margin;
        @include mq($screen-m) {
            flex: 1;
        }
    }

    &__primary-action {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: $catalog-grid-item__primary-padding;

        &:hover,
        &:focus {
            .icon {
                fill: $catalog-grid-item__primary-icon-fill;
            }
        }
    }

    &__cart-icon {
        @include mq($screen-m) {
            display: none;
        }
    }

    &__cart-text {
        display: none;
        @include mq($screen-m) {
            display: block;
        }
    }

    &__secondary-action {
        display: inline-block;
        position: absolute;
        top: $spacer;
        right: $spacer;

        @include mq($screen-m) {
            top: 0;
            right: 0;
        }

        .button {
            height: $catalog-grid-item__action-size;
            min-width: $catalog-grid-item__action-size;
            padding: $catalog-grid-item__action-padding;
            background: $catalog-grid-item__action-background;
            border: $catalog-grid-item__action-border;
            cursor: $catalog-grid-item__action-cursor;
            background: $white;
            &:hover,
            &.active,
            &:focus {
                background: $color-primary;
                .button__icon {
                    fill: $catalog-grid-item__action-icon-fill-hover;
                }
            }
            .button__icon {
                width: $catalog-grid-item__action-icon-size;
                height: $catalog-grid-item__action-icon-size;
                transition: $catalog-grid-item__action-icon-transition;
                fill: $catalog-grid-item__action-icon-fill;
            }
        }
    }

    &__infos {
        font-size: 13px;
        align-items: center;
        justify-content: space-between;
        display: none;
        @include mq($screen-m) {
            display: flex;
        }

        &-frequency {
            font-family: $font-family-sans-serif-alt;
            display: inline-flex;
            align-items: center;
            color: $gray-dark;
            text-align:left;

            &-icon {
                display: inline-block;
                margin-right: $spacer--small;
                width: 18px;
                height: 18px;
                fill: $gray-dark;
            }
        }
        &-offers {
            font-family: $font-family-condensed;
            font-weight: bold;
            margin-left: 15px;
            text-align: right;
            white-space: nowrap;
        }
    }

    &__overlay {
        display: none;
        &.mobile {
            position: relative;
            display: block;
            @media (min-width: $screen-m){
                display: none !important;
            }
            form, > * {
                display: inline-block;
            }
            .overlay {
                display: block;
                text-align: center;
                position: relative;
                padding: 0 1em 1em;
                .button--fluid {
                    width: auto !important;
                    padding: 0;
                    margin: 0 0.5em;
                    min-width: 32px;
                    min-height: 32px;
                    height: 32px;
                    .overlay__btn-buy-icon {
                        margin-right: 0;
                    }
                    .overlay__btn-see-icon {
                        margin-left: 0;
                    }

                    .button__icon {
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }
    }
}

.grid-banner {
    align-self: center;
    padding: 1.5em 1em;
    &__image {
        max-width: 50%;
        margin: -10px auto 0;
        @include mq($screen-m) {
            margin: -70px auto 0;
        }
    }

    &__title {
        text-align: center;
        color: #fff;
        font-family: $font-family-sans-serif-alt;
        font-weight: 900;
        font-size: 24px;
        line-height: 1;
        margin: $spacer--medium 0 $spacer--large;
    }

    &__list {
        color: #fff;
        font-size: $font-size-small;
        line-height: 1.2;
        list-style: none;
    }
    &__list-item {
        margin-bottom: 25px;
        @include mq($screen-s) {
            margin-bottom: $spacer--large;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        &:before {
            width: 30px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            border-radius: 50%;
            background: $color-primary;
            display: inline-block;
            position: absolute;
            left: 15px;
            font-family: $font-family-sans-serif-alt;
            font-weight: 900;
            font-size: $font-size-medium;
        }

        &:nth-of-type(1):before {
            content: "1";
        }
        &:nth-of-type(2):before {
            content: "2";
        }
        &:nth-of-type(3):before {
            content: "3";
        }
        &:nth-of-type(4):before {
            content: "4";
        }
    }
}
