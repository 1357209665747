.agreements {

    list-style: none;
    padding: 0;
    margin: 0 auto $spacer--medium;

    .label {
        display: inline-block;
        font-family: $font-family-sans-serif;
        font-size: $font-size-small;
        font-weight: normal;
        color: $darkblue;
        padding-right: 0;
    }
}
