$login__margin-bottom                  : $spacer--extra-large !default;
$login__padding-top                    : $spacer !default;
$login__actions-margin                 : $spacer--large 0 $spacer !default;

$login__heading-margin                 : 0 0 $spacer--medium 0 !default;

$login__company-column-padding         : $spacer--medium $spacer 0 $spacer !default;
$login__company-column-margin          : $spacer--large 0 0 0 !default;
$login__company-column-border-top      : 1px solid $gray-light !default;

$login__horizontal-padding             : 38px !default;
$login__button-margin                  : 0 auto !default;
$login__button-margin\@large           : 0 auto !default;
$login__button-margin--crate-account   : 0 auto $spacer !default;
$login__button-min-width               : 160px !default;

$login__form-margin                    : $spacer--extra-large 0 0 0 !default;
$login__form-margin\@medium            : $spacer--extra-large 0 0 0  !default;
$login__form-padding                   : 0 0 $spacer--medium 0 !default;
$login__form-padding\@medium           : 0 !default;
$login__form-border                    : none !default;
$login__form-border\@medium            : none !default;

$login__fieldset-max-width             : 364px !default;
$login__fieldset-max-width\@medium     : 364px !default;

$login__forgot-password-margin         : $spacer 0 0 $spacer !default;
$login__forgot-password-margin\@small  : 0 !default;
$login__forgot-password-color          : $color-primary !default;
$login__forgot-password-text-decoration: underline !default;

$login__input-margin                   : 0 0 $spacer--large !default;

$login__info-margin                    : $spacer--extra-large auto !default;

$login__validation-tips-color          : $red !default;
$login__validation-tips-margin         : 0 0 $spacer !default;

.login {
    padding-top: $login__padding-top;
    margin-bottom: $login__margin-bottom;
    @include fix-gutter(40px);

    &__company-wrapper {
        margin: $login__company-column-margin;
        padding: $login__company-column-padding;
        border-top: $login__company-column-border-top;

        @include mq($screen-m) {
            border-top: none;
        }
    }

    &__info {
        margin: $login__info-margin;
        font-family: $font-family-sans-serif-alt;
        font-size: $font-size-large;
        color: $darkblue;
        max-width: 280px;
    }

    &__form {
        margin: $login__form-margin;
        padding: $login__form-padding;
        border-bottom: $login__form-border;
        text-align: left;

        @include mq($screen-m) {
            margin: $login__form-margin\@medium;
            padding: $login__form-padding\@medium;
            border-bottom: $login__form-border\@medium;
        }
    }

    &__heading {
        margin: $login__heading-margin;
    }

    &__fieldset {
        margin: 0 auto;
        max-width: $login__fieldset-max-width;
    }

    &__actions {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        margin: $login__actions-margin;
    }

    &__button {
        width: 100%;
        justify-content: center;
        margin: $login__button-margin;
        padding-right: $login__horizontal-padding;
        padding-left: $login__horizontal-padding;
        min-width: $login__button-min-width;
        max-width: 260px;
        position: relative;

        @include mq($screen-s) {
            flex: 0 0 auto;
        }

        @include mq($screen-l) {
            margin: $login__button-margin\@large;
        }

        &--create-accunt {
            margin: $login__button-margin--crate-account;
            background: $color-secondary;
            &:hover,
            &:focus {
                background: darken($color-secondary, 10%);
            }
        }
    }

    &__forgot-password {
        margin: $login__forgot-password-margin;
        color: $login__forgot-password-color;
        text-decoration: $login__forgot-password-text-decoration;
        font-size: $font-size-extra-small;
        display: block;
    }

    &__input {
        margin: $login__input-margin;
    }

    &__validation-tips {
        color: $login__validation-tips-color;
        margin: $login__validation-tips-margin;
    }

    &__title {
        margin-bottom: 0;
        &:after {
            content: "";
            height: 3px;
            width: 72px;
            display: block;
            margin: $spacer--medium auto 0;
            background: $color-primary;
        }
    }
    &__subtitle {
        display: block;
        font-size: 24px;
        font-weight: 700;
        margin-top: $spacer;
    }

    &__form-column,
    &__link-column {
        background: $white;
        box-shadow: $box-shadow__form;
        text-align: center;
        padding: $spacer--large $spacer--medium;
        
        @include mq($screen-s) {
            padding-left: $spacer--extra-large;
            padding-right: $spacer--extra-large;
        }
    }

    &__link-column {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    &__button-icon {
        fill: $white;
        width: 12px;
        height: 12px;
        position: absolute;
        right: $spacer--medium;
        top: 50%;
        margin-top: -6px;
    }

    &__social {
        background: darken($gray-lightest, 3%);
        box-shadow: $box-shadow__form;
        padding: $spacer--medium;
    }
    &__social-text {
        color: $darkblue;
        text-align: center;
        font-family: $font-family-sans-serif-alt;
        font-weight: 700;
        margin-bottom: $spacer--medium;
    }
}

.message-first-connection {
    text-align:center;
    padding: 1em;
    margin-bottom: 1em;
    p {
        margin: 0;
    }
    a {
        color: $login__forgot-password-color;
        text-decoration: $login__forgot-password-text-decoration;
    }
}
body.checkout-index-index .message-first-connection {
    margin-top: 1em;

}