.account-banner {
    background: #18587c url(../images/cms/banner.png) no-repeat top;
    position: relative;
    min-height: 204px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    @include mq($screen-m) {
        display: block;
        justify-content: space-around;
    }

    &__title {
        text-align: center;
        margin: 0 auto;
        color: $white;
        font-size: 24px;
        line-height: 1.15;
        font-weight: 400;

        @include mq($screen-m) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -80%);
        }
        @include mq($screen-l) {
            font-size: 36px;
        }
    }

    &__logout {
        color: $white;
        font-family: $font-family-sans-serif-alt;
        display: flex;
        text-decoration: underline;
        margin: $spacer--medium auto;

        &:hover {
            color: $white;
            text-decoration: none;
        }

        @include mq($screen-m) {
            margin: 0 $spacer--medium 0 0;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-142%);
        }
    }

    &__logout-icon {
        fill: $white;
        display: inline-block;
        margin-right: $spacer;
    }


}
