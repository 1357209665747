$forgot-password__max-width                    : 700px !default;
$forgot-password__margin-bottom                : $spacer--extra-large !default;
$forgot-password__field-margin-bottom          : $spacer--medium !default;
$forgot-password__captcha-field-width          : 100% !default;
$forgot-password__captcha-wrapper-margin-bottom: $spacer--medium !default;
$forgot-password__image-width                  : auto !default;
$forgot-password__image-max-width              : 300px !default;
$forgot-password__image-padding-right          : $spacer !default;
$forgot-password__strength-meter-margin-bottom : $spacer--medium !default;

.forgot-password {
    max-width: $forgot-password__max-width;
    padding: $spacer--extra-large;
    box-shadow: $box-shadow__form;
    margin: 0 auto;
    @include mq($screen-l) {
        padding: $spacer--extra-large calc(#{$spacer--extra-large} * 2);
    }

    &__form {
        display: flex;
        flex-direction: column;
    }

    &__email,
    &__captcha-field {
        margin-bottom: $forgot-password__field-margin-bottom;
    }

    &__captcha-field {
        width: $forgot-password__captcha-field-width;
    }

    &__captcha-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: $forgot-password__captcha-wrapper-margin-bottom;
    }

    &__image {
        padding-right: $forgot-password__image-padding-right;
        width: $forgot-password__image-width;
        max-width: $forgot-password__image-max-width;
    }

    &__strength-meter {
        margin-bottom: $forgot-password__strength-meter-margin-bottom;
    }

    &__button {
        margin: $spacer--extra-large auto 0;
        padding: 0 $spacer--large 0 $spacer--extra-large;
    }
    &__button-icon {
        margin-left: $spacer--large;
    }
}
