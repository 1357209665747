.form-address-edit {
  .intl-tel-input .flag-container {
    bottom: inherit;
    top: 18px;
  }
}

form.contact__form {
  .intl-tel-input .flag-container {
    left: 10px;
    &:hover {
      .selected-flag {
        background: none;
      }
    }
  }
}

.intl-tel-input .selected-flag .iti-arrow {
  width: 10px !important;
  height: 5px !important;
  background-image: url(../images/arrow-select.svg);
  border: none !important;
}

.intl-tel-input .selected-flag .iti-flag {
  background-color: transparent !important;
  box-shadow: none !important;
}

.intl-tel-input.allow-dropdown .flag-container, .intl-tel-input.separate-dial-code .flag-container {
  left: 10px !important;
}

.intl-tel-input.allow-dropdown input, .intl-tel-input.allow-dropdown input[type=text], .intl-tel-input.allow-dropdown input[type=tel], .intl-tel-input.separate-dial-code input, .intl-tel-input.separate-dial-code input[type=text], .intl-tel-input.separate-dial-code input[type=tel] {
  padding-left: 62px !important;
  padding-right: 30px !important;
}